import { Spin } from 'antd'

export default function Loading() {
  return (
    <div
      className="loading"
      style={{
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <Spin />
    </div>
  )
}
