export default function ForbiddenView() {
  return (
    <div style={{ marginTop: 12 }}>
      <h1 style={{ textAlign: 'center' }}>403</h1>
      <p style={{ textAlign: 'center' }}>
        You are not allow to access the page
      </p>
    </div>
  )
}
