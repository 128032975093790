import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Logout() {
  const navigate = useNavigate()
  const client = useQueryClient()
  useEffect(() => {
    client.clear()
    // Remove session from cookie
    document.cookie = 'session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    // Redirect to login page
    navigate('/login', { replace: true })
  }, [client, navigate])
  return null
}
