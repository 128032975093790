import { useQuery } from '@tanstack/react-query'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { fetchPermissions } from '../api/permission'
import {
  BookOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FundOutlined,
  LogoutOutlined,
  UserOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { READ_PERMISSION, WRITE_PERMISSION } from '../constants/permission'
import { checkPremissions } from '../utils/permission'

const useSidebarItems = () => {
  const { data: userPermissionData } = useQuery(['user-permissions'], () =>
    fetchPermissions()
  )

  const userPermissions = userPermissionData?.permissions || []

  let claimKey = 'claim'
  if (
    !checkPremissions(userPermissions || [], [
      WRITE_PERMISSION.MANAGE_CLAIMS,
    ]) &&
    checkPremissions(userPermissions || [], [
      WRITE_PERMISSION.LEADER_CLAIM_APPROVAL,
    ])
  ) {
    claimKey = 'claim-leader-approval'
  }

  const items: ItemType[] = [
    ...(checkPremissions(userPermissions || [], [READ_PERMISSION.VIEW_SALE])
      ? [{ label: 'Sale', key: 'sale', icon: <FundOutlined /> }]
      : []),
    ...(checkPremissions(userPermissions || [], [READ_PERMISSION.VIEW_PROJECT])
      ? [{ label: 'Project', key: 'project', icon: <BookOutlined /> }]
      : []),
    ...(checkPremissions(userPermissions || [], [
      WRITE_PERMISSION.MANAGE_CLAIMS,
      WRITE_PERMISSION.LEADER_CLAIM_APPROVAL,
      READ_PERMISSION.VIEW_CLAIM,
    ])
      ? [{ label: 'Claim', key: claimKey, icon: <FileDoneOutlined /> }]
      : []),
    ...(checkPremissions(userPermissions || [], [
      WRITE_PERMISSION.MANAGE_DEVELOPER_CLAIMS,
      READ_PERMISSION.VIEW_DEVELOPER_CLAIM,
    ])
      ? [
          {
            label: 'Developer Claim',
            key: 'developer-claims',
            icon: <FileDoneOutlined />,
          },
        ]
      : []),
    ...(checkPremissions(userPermissions || [], [READ_PERMISSION.VIEW_USER])
      ? [{ label: 'User', key: 'user', icon: <UserOutlined /> }]
      : []),
    ...(checkPremissions(userPermissions || [], [
      READ_PERMISSION.GENERATE_REPORT,
    ])
      ? [{ label: 'Report', key: 'report', icon: <FileTextOutlined /> }]
      : []),
    {
      label: 'Org Chart',
      key: 'org-chart',
      icon: <SolutionOutlined />,
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ]

  return items
}

export default useSidebarItems
