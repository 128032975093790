import { fetchApi } from './fetch'

const PREFIX = '/permission'

type FetchPermissionData = {
  permissions: string[]
}

export async function fetchPermissions() {
  const results: FetchPermissionData = await fetchApi(PREFIX + '/all', {
    method: 'GET',
  })
  return results
}
