import { Column, SimpleTable, stringColumn } from '@m17/table'
import {
  DeveloperClaimBatchStatus,
  DeveloperClaimBatchType,
} from '@m17/api-types'
import React, { FC, useState } from 'react'

type DeveloperClaimRecordType = {
  id: number
  batch_name: string
  type: number
  status: number
  sales: Array<{
    id: number
    pt_no: string
    project_name: string
  }>
}

type NestedRecordType = {
  id: number
  pt_no: string
  project_name: string
}

type DeveloperClaimsPageProps = {
  developerClaims: Array<DeveloperClaimRecordType>
}

const DeveloperClaimsPage: FC<DeveloperClaimsPageProps> = ({
  developerClaims,
}) => {
  const [columns] = useState<Column<DeveloperClaimRecordType>[]>([
    {
      ...stringColumn('batch_name'),
      title: 'Batch Name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (record: keyof typeof DeveloperClaimBatchType) =>
        DeveloperClaimBatchType[record],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (record: keyof typeof DeveloperClaimBatchStatus) =>
        DeveloperClaimBatchStatus[record],
    },
  ])
  const [nestedColumn] = useState<Column<NestedRecordType>[]>([
    {
      ...stringColumn('pt_no'),
      title: 'PT No',
    },
    {
      ...stringColumn('project_name'),
      title: 'Project',
    },
  ])
  return (
    <div>
      <SimpleTable
        columns={columns}
        data={developerClaims}
        rowKey="id"
        expandable={{
          expandedRowRender: (record: DeveloperClaimRecordType) => {
            return <SimpleTable data={record.sales} columns={nestedColumn} />
          },
        }}
      />
    </div>
  )
}

export default DeveloperClaimsPage
