import React from 'react'
import { Tabs } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Container } from '../views/StyledComponent'

const DeveloperClaimTabLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <Container>
      <Tabs defaultActiveKey={location.pathname} onChange={navigate}>
        <Tabs.TabPane tab="Converted Sales" key="/converted-sales" />
        <Tabs.TabPane tab="Developer Claims" key="/developer-claims" />
        <Tabs.TabPane
          tab="Developer Claim Batch"
          key="/developer-claim-batches"
        />
      </Tabs>
      <Outlet />
    </Container>
  )
}

export default DeveloperClaimTabLayout
