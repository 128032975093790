import { SaleStatus } from '@m17/api-types'
import { Button, Space, Table, TableColumnsType, Popconfirm } from 'antd'
import React, { FC, useState } from 'react'

type ClaimRecordType = {
  id: number
  no: number
  pt_no: string
  claim?: {
    id: number
    batch_name?: string
    status: number
  }
}

type LeaderApprovalPageProps = {
  claimData: Array<ClaimRecordType>
  onViewDetails: (record: ClaimRecordType) => void
  onRejectClick: (record: ClaimRecordType) => void
  onConfirmApprove: (record: ClaimRecordType) => void
}

const LeaderApprovalPage: FC<LeaderApprovalPageProps> = ({
  claimData,
  onViewDetails,
  onRejectClick,
  onConfirmApprove,
}) => {
  const [columns] = useState<TableColumnsType<ClaimRecordType>>([
    { key: 'no', dataIndex: 'no', title: 'No' },
    { key: 'project', dataIndex: 'project_name', title: 'Project Name' },
    { key: 'pt_no', dataIndex: 'pt_no', title: 'PT No.' },
    {
      key: 'project_phase',
      dataIndex: ['project_phase', 'name'],
      title: 'Phase',
    },
    {
      key: 'sale_status',
      dataIndex: 'sale_status',
      title: 'Sale Status',
      render: (status) => SaleStatus[status as keyof typeof SaleStatus],
    },
    {
      key: 'details',
      title: 'Details',
      render: (_, record) => (
        <Button type="link" onClick={() => onViewDetails(record)}>
          View Details
        </Button>
      ),
    },
    {
      key: 'approval',
      title: 'Approval',
      width: 'auto',
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Are you sure to approve this?"
            onConfirm={() => onConfirmApprove(record)}
          >
            <Button>Approve</Button>
          </Popconfirm>
          <Button danger onClick={() => onRejectClick(record)}>
            Reject
          </Button>
        </Space>
      ),
    },
  ])
  return (
    <Space size="large" direction="vertical" style={{ width: '100%' }}>
      <Table rowKey="id" columns={columns} dataSource={claimData} />
    </Space>
  )
}

export default LeaderApprovalPage
