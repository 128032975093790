import { SelectOption } from '@m17/api-types'
import { Form, FormInstance, Input, Select } from 'antd'
import { USER_TYPE } from '../constants/user'

export type AddUserFormValues = {
  username: string
  password: string
  name: string
  leader_id: string
  type: string
  code: string
}

type UserFormProps = {
  form: FormInstance<AddUserFormValues>
  leaderOptions: Array<SelectOption>
  userTypeOptions: Array<SelectOption>
  mode?: 'edit' | 'create'
  isSelfEdit?: boolean
}

const initialFormValue = {
  name: '',
  type: 'agent',
}

export default function UserForm({
  form,
  leaderOptions,
  userTypeOptions,
  mode = 'create',
  isSelfEdit = false,
}: UserFormProps) {
  const userTypeWatch = Form.useWatch('type', form)
  const isEditMode = mode === 'edit'
  return (
    <Form
      form={form}
      initialValues={initialFormValue}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
    >
      <Form.Item
        required
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            pattern: /^[\w\d]+$/,
            message: 'Valid username is required',
          },
        ]}
      >
        <Input
          autoComplete="off"
          readOnly={isEditMode}
          disabled={isEditMode}
        ></Input>
      </Form.Item>
      {!isEditMode && (
        <Form.Item
          rules={[{ required: true, message: 'Password is required' }]}
          required
          name="password"
          label="Password"
        >
          <Input type="password" autoComplete="off"></Input>
        </Form.Item>
      )}
      <Form.Item
        rules={[{ required: true, message: 'Name is required' }]}
        required
        name="name"
        label="Name"
      >
        <Input autoComplete="off"></Input>
      </Form.Item>
      <Form.Item required name={'type'} label={'Type'}>
        <Select disabled={isSelfEdit}>
          {userTypeOptions.map((op) => (
            <Select.Option key={`select-option:${op.value}`} value={op.value}>
              {op.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {![USER_TYPE.ADMIN, USER_TYPE.ACCOUNTANT, USER_TYPE.OLD_ADMIN].includes(
        userTypeWatch as USER_TYPE
      ) && (
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Code is required',
            },
          ]}
          name="code"
          label="Code"
        >
          <Input autoComplete="off"></Input>
        </Form.Item>
      )}
      {![
        USER_TYPE.ADMIN,
        USER_TYPE.LEADER,
        USER_TYPE.ACCOUNTANT,
        USER_TYPE.OLD_ADMIN,
      ].includes(userTypeWatch as USER_TYPE) && (
        <Form.Item required name="leader_id" label="Leader/Pre-Leader">
          <Select
            showSearch
            filterOption={(input, option) => {
              return (option?.children ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }}
            disabled={isSelfEdit}
          >
            {leaderOptions.map((op) => (
              <Select.Option key={op.value} value={op.value}>
                {op.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Form>
  )
}
