import { Form, FormInstance, Input, InputNumber } from 'antd'
import React, { FC } from 'react'

export type ProjectPhasePackageFormValues = {
  id?: number
  name: string
  price: number
}

type ProjectPhasePackageFormProps = {
  form: FormInstance<ProjectPhasePackageFormValues>
}

const ProjectPhasePackageForm: FC<ProjectPhasePackageFormProps> = ({
  form,
}) => {
  return (
    <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Form.Item name="id" hidden />
      <Form.Item
        name="name"
        label="Package Name"
        required
        rules={[
          {
            required: true,
            message: 'Please enter a package name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="price"
        label="Price"
        required
        rules={[
          {
            required: true,
            message: 'Please enter a price',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

export default ProjectPhasePackageForm
