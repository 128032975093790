import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { SimpleTable, Column, stringColumn, numberColumn } from '@m17/table'
import {
  DEVELOPER_CLAIM_BATCH_TYPE,
  DeveloperClaimBatchType,
} from '@m17/api-types'
import { Button, Space } from 'antd'
import React, { FC, useState } from 'react'

function toNumberString(num: number) {
  if (Number.isInteger(num)) {
    return num + '.0'
  } else {
    return num.toString()
  }
}

export type DeveloperClaimBatchRecord = {
  id: number
  name: string
  type: number
  commission_pct: number
  full_commission_pct: number
}

type DeveloperClaimBatchPageProps = {
  developerClaimBatches: DeveloperClaimBatchRecord[]
  onClickAdd: () => void
  onClickEdit: (data: DeveloperClaimBatchRecord) => void
}

const DeveloperClaimBatchPage: FC<DeveloperClaimBatchPageProps> = ({
  developerClaimBatches,
  onClickAdd,
  onClickEdit,
}) => {
  const [columns] = useState<Column<DeveloperClaimBatchRecord>[]>([
    {
      ...stringColumn('id'),
    },
    {
      ...stringColumn('name'),
    },
    {
      ...stringColumn('type'),
      render: (record: number) =>
        DeveloperClaimBatchType[record as keyof typeof DeveloperClaimBatchType],
    },
    {
      ...numberColumn('full_commission_pct'),
      title: 'Full Commission (%)',
      render: (record: number) => toNumberString(record),
    },
    {
      ...numberColumn('commission_pct'),
      title: 'Commission (%)',
      render: (value: number, record) =>
        record.type !== DEVELOPER_CLAIM_BATCH_TYPE.FULL_CLAIM
          ? value
            ? toNumberString(value)
            : '-'
          : '-',
    },
    {
      key: 'action',
      title: 'Action',
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditFilled />}
            onClick={() => onClickEdit && onClickEdit(record)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button icon={<PlusOutlined />} onClick={onClickAdd}>
        Add
      </Button>
      <SimpleTable
        columns={columns}
        data={developerClaimBatches}
        needIndex={false}
      />
    </Space>
  )
}

export default DeveloperClaimBatchPage
