import {
  ApproveClaimsPayload,
  GenerateClaimReportPayload,
  GetAllClaimsForLeaderApprovalRespData,
  GetAllClaimsRespData,
  GetSetClaimBatchOptionsRespData,
  LeaderApproveClaimPayload,
  LeaderRejectClaimPayload,
  RejectClaimsPayload,
  SelectOption,
  SetClaimBatchPayload,
} from '@m17/api-types'
import { fetchApi } from './fetch'
const PREFIX = '/claim'

type GetAllClaimsParam = {
  queryKey: (
    | string
    | {
        booking_date?: string
        claim_batch_id?: string
        project_id?: string
      }
  )[]
}

export async function getAllClaims({
  queryKey,
}: GetAllClaimsParam): Promise<GetAllClaimsRespData> {
  const [, query] = queryKey
  let queryUrl = ''
  if (Object.keys(query).length > 0) {
    queryUrl += `?${new URLSearchParams(query)}`
  }

  return fetchApi(PREFIX + '/all' + queryUrl, {
    method: 'GET',
  })
}

export async function getAllClaimsForLeaderApproval(): Promise<GetAllClaimsForLeaderApprovalRespData> {
  return fetchApi(PREFIX + '/all/for-leader-approval', {
    method: 'GET',
  })
}

export async function getSetClaimBatchOptions(): Promise<GetSetClaimBatchOptionsRespData> {
  return fetchApi(PREFIX + '/form/set-claim-batch-options', {
    method: 'GET',
  })
}

export async function getSearchClaimBatchOptions(): Promise<
  SelectOption<number>[]
> {
  return fetchApi(PREFIX + '/form/search/claim-batch-options', {
    method: 'GET',
  })
}

export async function getSearchProjectOptions(): Promise<
  SelectOption<number>[]
> {
  return fetchApi(PREFIX + '/form/search/project-options', {
    method: 'GET',
  })
}

export async function setClaimBatch(payload: SetClaimBatchPayload) {
  return fetchApi(PREFIX + '/set-claim-batch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function approveClaims(payload: ApproveClaimsPayload) {
  return fetchApi(PREFIX + '/approve-claims', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function rejectClaims(payload: RejectClaimsPayload) {
  return fetchApi(PREFIX + '/reject-claims', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function leaderApproveClaim(
  payload: LeaderApproveClaimPayload
): Promise<boolean> {
  return fetchApi(PREFIX + '/leader-approve-claim', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function leaderRejectClaim(payload: LeaderRejectClaimPayload) {
  return fetchApi(PREFIX + '/leader-reject-claim', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function generateClaimReport(payload: GenerateClaimReportPayload) {
  const url = `${PREFIX}/generate-claim-report?${new URLSearchParams({
    claim_batch_id: String(payload.claim_batch_id),
  })}`
  let filename = 'report.csv'
  const csv = await fetch('/api/v1' + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((response) => {
    // get filename from response header
    filename =
      response.headers.get('Content-Disposition')?.split('filename=')[1] ||
      filename
    return response.blob()
  })
  const urlObject = window.URL || window.webkitURL || window
  const exportUrl = urlObject.createObjectURL(csv)
  const a = document.createElement('a')
  a.href = exportUrl
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
