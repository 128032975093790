export enum PURCHASER_TYPE {
  BUMI,
  EXISTING_BUYER,
  INTRODUCER,
  NONE,
}

export enum FINANCIAL_TYPE {
  BANK_LOAN,
  GOV_LOAN,
  CASH,
}

export const PurchaserType = {
  [PURCHASER_TYPE.BUMI]: 'Bumi',
  [PURCHASER_TYPE.EXISTING_BUYER]: 'Existing Buyer',
  [PURCHASER_TYPE.INTRODUCER]: 'Introducer',
  [PURCHASER_TYPE.NONE]: 'None',
} as const

export const FinancialType = {
  [FINANCIAL_TYPE.BANK_LOAN]: 'Back Loan',
  [FINANCIAL_TYPE.GOV_LOAN]: 'Gov Loan',
  [FINANCIAL_TYPE.CASH]: 'Cash',
} as const
