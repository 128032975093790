import { SelectOption } from '.'

export enum SALE_STATUS {
  PENDING_LOAN_DOCUMENTS,
  PENDING_LOAN_APPROVAL,
  LOAN_APPROVED,
  INSTRUCTED_LAWYER,
  CONVERTED,
  CANCELLED,
  CANCELLED_CHANGE_UNIT,
}

export const SaleStatus = {
  [SALE_STATUS.PENDING_LOAN_DOCUMENTS]: 'Pending Loan Document',
  [SALE_STATUS.PENDING_LOAN_APPROVAL]: 'Pending Loan Approval',
  [SALE_STATUS.LOAN_APPROVED]: 'Loan Approved',
  [SALE_STATUS.INSTRUCTED_LAWYER]: 'Instructed Lawyer',
  [SALE_STATUS.CONVERTED]: 'Converted',
  [SALE_STATUS.CANCELLED]: 'Cancelled',
  [SALE_STATUS.CANCELLED_CHANGE_UNIT]: 'Cancelled - Change Unit',
}

export enum SALE_CLAIM_STATUS {
  PENDING,
  CLAIM_SUBMITTED,
  CLAIM_APPROVED,
  PENDING_DEVELOPER_APPROVAL,
  PENDING_FINAL_APPROVAL,
  CLAIM_COMPLETED,
  CLAIM_REJECTED,
}

export const SaleClaimStatus = {
  [SALE_CLAIM_STATUS.PENDING]: 'Pending',
  [SALE_CLAIM_STATUS.CLAIM_SUBMITTED]: 'Claim Submitted',
  [SALE_CLAIM_STATUS.CLAIM_APPROVED]: 'Claim Approved',
  [SALE_CLAIM_STATUS.PENDING_DEVELOPER_APPROVAL]: 'Pending Developer Approval',
  [SALE_CLAIM_STATUS.PENDING_FINAL_APPROVAL]: 'Pending Final Payment',
  [SALE_CLAIM_STATUS.CLAIM_COMPLETED]: 'Claim Completed',
  [SALE_CLAIM_STATUS.CLAIM_REJECTED]: 'Claim Rejected',
}

export interface Commission {
  id: number
  username: string
  rate: number
}

export type AllSaleResponse = {
  id: number
  pt?: string
  pt_no?: string
  spa_price: number
  nett_price: number
  booking_date: number
  purchaser_name: string
  purchaser_contact: string | null
  purchaser_type: number | null
  financial_type: number | null
  sale_status: SALE_STATUS
  project_name: string
  spa_date: number | null
  la_date: number | null
  lo_date: number | null
  commission: {
    rate: number
    user: {
      username: string
      name: string
    }
  }[]
  project: string
  project_phase_id: string
  claim?: {
    id: number
    status: number
    remark: string | null
  }
  other_info?: string | null
  sop_date: number | null
  package_add_on: number[]
}

export type AllSaleParams = {
  project_id?: string
  phase?: string
  pt_id?: string
  booking_date?: string
  spa_date?: string
  lo_date?: string
  sale_status?: SALE_STATUS | null
  claim_status?: SALE_CLAIM_STATUS | null
  keyword?: string
  created_by?: string
}

export type CreateSalePayload = {
  pt_id: number
  booking_date: number
  purchaser_name: string
  purchaser_contact: string
  purchaser_type: number
  financial_type: number
  sale_remark?: string
  project_id: number
  spa_date?: number
  lo_date?: number
  la_date?: number
  project_phase_id: number
  sale_status: number
  commissions: Commission[]
  other_info?: string
  sop_date?: number
  package_add_on: number[]
}

export type UpdateSalePayload = {
  pt_id: number
  booking_date: number
  purchaser_name: string
  purchaser_contact: string
  purchaser_type: number
  financial_type: number
  sale_remark?: string
  project_id: number
  spa_date?: number
  lo_date?: number
  la_date?: number
  project_phase_id: number
  sale_status: number
  commissions: Commission[]
  other_info?: string
  sop_date?: number
  package_add_on: number[]
}

export type CreateFastCommClaimPayload = {
  sale_id: number
}

export type GetSaleSearchAgentOptionRespData = Array<SelectOption<string>>

export type GetSaleFormPackageOptionRespData = SelectOption<number>

export type GetNetPricePayload = {
  pt_id: number
  purchaser_type: number
  booking_date: number
  spa_date?: number
  lo_date?: number
  sop_date?: number
  package_add_on: number[]
}
