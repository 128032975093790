import React from 'react'
import { Form, FormInstance, Input } from 'antd'

export type ProjectPhaseFormValues = {
  phaseId?: number
  name: string
}

type ProjectPhaseFormProps = {
  form: FormInstance<ProjectPhaseFormValues>
}

const ProjectPhaseForm = ({ form }: ProjectPhaseFormProps) => {
  return (
    <Form form={form}>
      <Form.Item name="phaseId" hidden />
      <Form.Item
        name={'name'}
        label="Phase Name"
        rules={[
          {
            required: true,
            message: 'Phase name is required',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default ProjectPhaseForm
