import {
  CreateDeveloperClaimBatchPayload,
  CreateDeveloperClaimBatchResp,
  ListDeveloperClaimBatchesResp,
  SelectOption,
  SetDeveloperClaimBatchOptionsResp,
  UpdateDeveloperClaimBatchPayload,
  UpdateDeveloperClaimBatchResp,
} from '@m17/api-types'
import { fetchApi } from './fetch'

const PREFIX = '/developer-claim-batch'

export async function createDeveloperClaimBatch(
  payload: CreateDeveloperClaimBatchPayload
): Promise<CreateDeveloperClaimBatchResp> {
  return fetchApi(PREFIX + '/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function updateDeveloperClaimBatch(
  payload: UpdateDeveloperClaimBatchPayload
): Promise<UpdateDeveloperClaimBatchResp> {
  return fetchApi(PREFIX + '/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function getDeveloperClaimBatches(): Promise<
  ListDeveloperClaimBatchesResp[]
> {
  return fetchApi(PREFIX + '/all', {
    method: 'GET',
  })
}

export async function getSetDeveloperClaimBatchOptions(): Promise<
  SetDeveloperClaimBatchOptionsResp[]
> {
  return fetchApi(PREFIX + '/form/set-claim-batch-options', {
    method: 'GET',
  })
}

export async function getClaimBatchOptions() {
  return fetchApi<SelectOption<number>[]>(
    PREFIX + '/form/claim-batch-options',
    {
      method: 'GET',
    }
  )
}
