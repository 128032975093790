import React, { useMemo } from 'react'
import LayoutUI from '../views/LayoutUI'
import { useNavigate, matchPath, useLocation } from 'react-router-dom'

export type LayoutUIProps = {
  children: React.ReactNode
}

export default function LayoutUIContainer({ children }: LayoutUIProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const menuActiveKey = useMemo(() => {
    const name = location.pathname
    if (
      matchPath(
        {
          path: '/user',
        },
        name
      )
    ) {
      return 'user'
    }
    if (
      matchPath(
        {
          path: '/',
        },
        name
      )
    ) {
      return 'sale'
    }
    if (
      matchPath(
        {
          path: '/sale',
        },
        name
      )
    ) {
      return 'sale'
    }
    if (
      matchPath(
        {
          path: '/project',
        },
        name
      )
    ) {
      return 'project'
    }
    if (
      matchPath(
        {
          path: '/report',
        },
        name
      )
    ) {
      return 'report'
    }
    if (
      matchPath('converted-sales', name) ||
      matchPath({ path: '/developer-claims' }, name) ||
      matchPath('/developer-claim-batches', name)
    ) {
      return 'developer-claims'
    }
  }, [location])

  const handleMenuClick: React.ComponentProps<
    typeof LayoutUI
  >['onMenuSelect'] = (info) => {
    switch (info.key) {
      case 'sale':
        navigate('/')
        break
      case 'claim':
        navigate('/claim')
        break
      case 'developer-claims':
        navigate('/developer-claims')
        break
      case 'claim-leader-approval':
        navigate('/claim-leader-approval')
        break
      case 'project':
        navigate('/project')
        break
      case 'user':
        navigate('/user')
        break
      case 'report':
        navigate('/report')
        break
      case 'logout':
        navigate('/logout')
        break
      case 'org-chart':
        navigate('/org-chart')
        break
      default:
        break
    }
  }

  return (
    <LayoutUI
      menuActiveKey={menuActiveKey}
      onMenuSelect={handleMenuClick}
      renderContent={children}
    />
  )
}
