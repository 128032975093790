import React, { useState } from 'react'
import { Card, Col, Form, Row, message } from 'antd'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  getDeveloperClaimConvertedSales,
  setDeveloperClaimBatch,
} from '../api/developerClaim'
import ConvertedSalesPage from '../views/ConvertedSalesPage'
import { getSetDeveloperClaimBatchOptions } from '../api/developerClaimBatch'
import SetBatchForm from './SetBatchForm'

const ConvertedSalesPageContainer = () => {
  const { data: convertedSales, refetch: refetchConvertedSales } = useQuery(
    ['developer-claim-converted-sales'],
    getDeveloperClaimConvertedSales
  )
  const { data: developerClaimBatchOptions } = useQuery(
    ['set-developer-claim-batch-options'],
    getSetDeveloperClaimBatchOptions
  )
  const { mutateAsync: setDeveloperClaimBatchFn } = useMutation(
    setDeveloperClaimBatch
  )

  const [claimBatchForm] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const onSubmitSetClaimBatch = async () => {
    try {
      await claimBatchForm.validateFields()
    } catch (error) {
      return
    }
    try {
      const claimBatchFormValues = claimBatchForm.getFieldsValue()
      await setDeveloperClaimBatchFn({
        claim_batch_id: claimBatchFormValues.claim_batch_id,
        sale_ids: selectedRowKeys as number[],
      })
      await refetchConvertedSales()
      setSelectedRowKeys([])
      claimBatchForm.resetFields()
      message.success('Update successfully')
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  return (
    <>
      <ConvertedSalesPage
        convertedSales={convertedSales || []}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={setSelectedRowKeys}
      />

      <Row gutter={[16, 16]} style={{ margin: '16px 0' }}>
        <Col span={8}>
          <Card>
            <SetBatchForm
              form={claimBatchForm}
              claimBatchOptions={developerClaimBatchOptions || []}
              disabled={selectedRowKeys.length === 0}
              onSubmit={onSubmitSetClaimBatch}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ConvertedSalesPageContainer
