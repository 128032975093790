import React, { useCallback } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { getAllDeveloperClaims } from '../api/developerClaim'
import { getClaimBatchOptions } from '../api/developerClaimBatch'
import DeveloperClaimsPage from '../views/DeveloperClaimsPage'
import { Button, Card, Col, Form, Row, Select, Typography, message } from 'antd'
import { generateDeveloperClaimInvoice } from '../api/developerClaim'

const DeveloperClaimsPageContainer = () => {
  const { data: developerClaims } = useQuery(
    ['developer-claims'],
    getAllDeveloperClaims
  )

  const { data: developerClaimBatchOptions } = useQuery(
    ['developer-claim-batch-options'],
    getClaimBatchOptions
  )

  const {
    mutateAsync: generateDeveloperClaimInvoiceFn,
    isLoading: isLoadingGenerateDeveloperClaimInvoice,
  } = useMutation(generateDeveloperClaimInvoice)

  const [generateDeveloperClaimInvoiceForm] = Form.useForm()

  const onGenerateDeveloperClaimInvoice = useCallback(async () => {
    try {
      await generateDeveloperClaimInvoiceForm.validateFields()
    } catch (error) {
      message.error('Make sure the form is correct')
    }
    try {
      const values = generateDeveloperClaimInvoiceForm.getFieldsValue()
      await generateDeveloperClaimInvoiceFn({
        developer_claim_batch_id: values.developer_claim_batch_id,
      })
    } catch (error) {
      message.error('Something went wrong.')
    }
  }, [generateDeveloperClaimInvoiceForm, generateDeveloperClaimInvoiceFn])

  return (
    <div>
      <DeveloperClaimsPage developerClaims={developerClaims || []} />

      <Row>
        <Col span={8}>
          <Card>
            <Form form={generateDeveloperClaimInvoiceForm} layout="vertical">
              <Typography.Title level={5}>
                Generate Proforma Invoice
              </Typography.Title>
              <Form.Item
                label="Claim batch ID"
                name="developer_claim_batch_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select a claim batch',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a claim batch"
                  filterOption={(input, option) => {
                    return (option?.children ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }}
                >
                  {(developerClaimBatchOptions || []).map(
                    ({ label, value }) => (
                      <Select.Option
                        map={`set-claim-batch-option:${value}`}
                        value={value}
                      >
                        {label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>

              <Button
                onClick={onGenerateDeveloperClaimInvoice}
                loading={isLoadingGenerateDeveloperClaimInvoice}
              >
                Generate
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default DeveloperClaimsPageContainer
