import React, { PropsWithChildren } from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchPermissions } from '../api/permission'
import { checkPremissions } from '../utils/permission'

type AccessControlProps = PropsWithChildren<{
  allowedPermissions: string[]
  renderNoAccess?: () => JSX.Element
}>

const AccessControl = ({
  allowedPermissions,
  children,
  renderNoAccess = () => <></>,
}: AccessControlProps) => {
  const { data: userPermissionData } = useQuery(['user-permissions'], () =>
    fetchPermissions()
  )
  const isPermitted = checkPremissions(
    userPermissionData?.permissions || [],
    allowedPermissions
  )
  if (isPermitted && children) {
    return <div>{children}</div>
  }
  return renderNoAccess()
}

export default AccessControl
