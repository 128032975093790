import { CLAIM_STATUS, ClaimStatus, SelectOption } from '@m17/api-types'
import { Button, DatePicker, Form, FormInstance, Select, Space } from 'antd'
import { Moment } from 'moment'
import React, { FC } from 'react'

const { RangePicker } = DatePicker

export type ClaimSearchFormValues = {
  booking_date?: Moment[]
  claim_batch_id?: number
  project_id?: number
  claim_status?: number
}

type ClaimSearchFormProps = {
  claimBatchFilterOptions: SelectOption<number>[]
  projectFilterOptions: SelectOption<number>[]
  form?: FormInstance<ClaimSearchFormValues>
  onSubmit?: (v: ClaimSearchFormValues) => void
  onReset?: React.FormEventHandler<HTMLFormElement>
}

const ClaimSearchForm: FC<ClaimSearchFormProps> = ({
  form,
  claimBatchFilterOptions,
  projectFilterOptions,
  onSubmit,
  onReset,
}) => {
  return (
    <Form
      form={form}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: 16,
      }}
      onFinish={onSubmit}
      onReset={onReset}
      layout="vertical"
    >
      <Form.Item
        name="booking_date"
        label="Booking Date"
        style={{ gridColumn: 'span 2 / span 2', marginBottom: 0 }}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item
        name="claim_batch_id"
        label="Claim batch"
        style={{ marginBottom: 0 }}
      >
        <Select
          allowClear
          showSearch
          placeholder="Filter by batch"
          options={claimBatchFilterOptions}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form.Item>
      <Form.Item name="project_id" label="Project" style={{ marginBottom: 0 }}>
        <Select
          allowClear
          showSearch
          placeholder="Filter by project"
          options={projectFilterOptions}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        ></Select>
      </Form.Item>
      <Form.Item
        name="claim_status"
        label="Claim Status"
        style={{ gridColumn: 'span 2 / span 2', marginBottom: 0 }}
      >
        <Select allowClear showSearch placeholder="Filter by claim status">
          {Object.keys(ClaimStatus).map((key) => (
            <Select.Option value={key}>
              {CLAIM_STATUS[key as keyof typeof CLAIM_STATUS]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Space>
        <Button htmlType="reset">Clear</Button>
        <Button htmlType="submit" type="primary">
          Search
        </Button>
      </Space>
    </Form>
  )
}

export default ClaimSearchForm
