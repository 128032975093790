import { removeNull } from '../utils'
import { fetchApi } from './fetch'
import type { ProjectPhase } from '../types/projectPhase'
import { UpdateProjectPayload, UpdateProjectPhasePayload } from '@m17/api-types'
import { PTData, Project, ProjectDetailData, ProjectRebate } from '@m17/backend'

const PREFIX = '/project'

export async function fetchProject(): Promise<Project[]> {
  const result: Project[] = await fetchApi(PREFIX + '/all', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return result
}

export async function fetchProjectDetail(
  id: string
): Promise<ProjectDetailData> {
  const result: ProjectDetailData = await fetchApi(PREFIX + '/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return result
}

export async function assign(projectId: string, username: string) {
  const result = await fetchApi(PREFIX + `/${projectId}/assign`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      username,
    }),
  })
  return result
}

export async function unassign(projectId: string, username: string) {
  const result = await fetchApi(PREFIX + `/${projectId}/unassign`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      username,
    }),
  })
  return result
}

export async function fetchProjectPhases(projectId: string | number) {
  const result: ProjectPhase[] = await fetchApi(
    PREFIX + `/${projectId}/phases`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return result
}

export async function fetchProjectRebates(
  projectId: string,
  phaseId: string
): Promise<ProjectRebate[]> {
  const result: ProjectRebate[] = await fetchApi(
    PREFIX + `/${projectId}/${phaseId}/rebates`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return result
}

// Create project
type CreateProjectParams = {
  name: string
  area?: string
  developer?: string
}

export async function createProject({
  name,
  area,
  developer,
}: CreateProjectParams) {
  const result = await fetchApi(PREFIX + '/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(
      removeNull({
        name,
        area,
        developer,
      })
    ),
  })
  return result
}

export async function updateProject({
  projectId,
  name,
  area,
  developer,
}: UpdateProjectPayload & { projectId: number }) {
  const result = await fetchApi(PREFIX + '/' + projectId, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(
      removeNull({
        name,
        area,
        developer,
      })
    ),
  })
  return result
}

export async function createPhase({
  name,
  projectId,
}: {
  name: string
  projectId: string
}) {
  const result = await fetchApi(PREFIX + `/${projectId}/phase/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(
      removeNull({
        name,
      })
    ),
  })
  return result
}

export async function updatePhase({
  name,
  projectId,
  phaseId,
}: UpdateProjectPhasePayload & { projectId: number }) {
  const result = await fetchApi(PREFIX + `/${projectId}/phase/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(
      removeNull({
        name,
        phaseId,
      })
    ),
  })
  return result
}

export async function fetchProjectPTs(
  projectId: number,
  projectPhaseId: number | undefined | null = undefined,
  notInSale: number = 0
): Promise<PTData[]> {
  const result: PTData[] = await fetchApi(
    PREFIX +
      `/${projectId}/pt?` +
      new URLSearchParams(
        removeNull({
          project_phase_id: projectPhaseId,
          not_in_sale: notInSale,
        })
      ),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return result
}
