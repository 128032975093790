import React, { useCallback, useEffect, useMemo } from 'react'
import ReportPage from '../views/ReportPage'
import { Form, message } from 'antd'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchProject } from '../api/project'
import { generateReport } from '../api/report'
import { createSelectValue, parseIdFromSelectValue, removeNull } from '../utils'
import { Moment } from 'moment'
import AccessControl from './AccessControl'
import { READ_PERMISSION } from '../constants/permission'
import ForbiddenView from '../views/ForbiddenView'

const convertDate = (date: Moment[] | undefined) => {
  if (!date) {
    return date
  }
  return date.map((item) => item.unix())
}

const accessPermission = [READ_PERMISSION.VIEW_REPORT]

const ReportPageContainer = () => {
  const [reportForm] = Form.useForm()

  const { data: projectsData = [] } = useQuery(['project'], fetchProject)
  const projectWithAll = useMemo(
    () => [{ id: -1, name: 'All Project' }, ...projectsData],
    [projectsData]
  )
  const { mutateAsync: generateReportMutate } = useMutation(generateReport)

  const onGenerateReportClick = useCallback(async () => {
    // Validation
    try {
      await reportForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    if (
      reportForm.getFieldsError().filter((item) => item.errors.length > 0)
        .length > 0
    ) {
      message.error('Please make sure the field is filled up correctly')
      return
    }
    const data = reportForm.getFieldsValue()
    data.booking_date = convertDate(data.booking_date)
    const projectId = parseIdFromSelectValue(data.project)
    // For All Project, set project id to undefined.
    // This will then be ignore in the filter.
    data.project_id = projectId! > 0 ? projectId : undefined

    delete data.project

    const processData = removeNull(data)

    message.info('Exporting...')
    await generateReportMutate(processData)
  }, [reportForm, generateReportMutate])

  useEffect(() => {
    if (projectWithAll.length > 0) {
      reportForm.setFieldValue('project', createSelectValue(-1, 'All Project'))
    }
  }, [reportForm, projectWithAll])

  return (
    <AccessControl
      allowedPermissions={accessPermission}
      renderNoAccess={ForbiddenView}
    >
      <ReportPage
        form={reportForm}
        projects={projectWithAll || []}
        onGenerateReportClick={onGenerateReportClick}
      />
    </AccessControl>
  )
}

export default ReportPageContainer
