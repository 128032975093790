import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DatePicker,
  Form,
  Input,
  Select,
  Typography,
  Divider,
  Radio,
  Button,
  Space,
  Checkbox,
  FormInstance,
  InputNumber,
} from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { SaleStatus, SelectOption } from '@m17/api-types'
import { ProjectPhase } from '../types/projectPhase'
import { createSelectValue } from '../utils'
import { User } from '../types/user'
import { ImageUploadButton } from '../components/UploadButton'
import React from 'react'
import { FINANCIAL_TYPE } from '../constants/sale'

const options = [
  { label: 'Bumi', value: 0 },
  { label: 'Existing Buyer', value: 1 },
  { label: 'Introducer', value: 2 },
  { label: 'Non-Bumi', value: 3 },
]

type SaleFormProps = {
  mode?: 'create' | 'edit' | 'view'
  projectOptions: {
    name: string
    id: number
  }[]
  form: FormInstance<any>
  users: User[]
  projectPhases: ProjectPhase[]
  projectPTs?: Array<{
    id: number
    pt_no: string
  }>
  phasePackagesOptions?: Array<SelectOption<number>>
}

type CustomCheckBox = {
  onChange?: (e: any) => void
  value?: number
  disabled?: boolean
}

function PurchaserCheckBox({ onChange, ...props }: CustomCheckBox) {
  const { value, disabled } = props

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    const newValue = checkedValues
      .map(Number)
      .reduce((prev, cur) => prev + (1 << cur), 0)

    onChange && onChange(newValue)
  }
  let arr = []
  for (let i = 0; i < 4; i++) {
    if (value && value & (1 << i)) {
      arr.push(i)
    }
  }

  return (
    <Checkbox.Group
      options={options}
      value={arr}
      onChange={handleChange}
      disabled={disabled}
    ></Checkbox.Group>
  )
}

export default function SaleForm({
  mode = 'create',
  form,
  projectOptions,
  users,
  projectPhases,
  projectPTs,
  phasePackagesOptions = [],
}: SaleFormProps) {
  const editMode = mode === 'edit'
  const viewMode = mode === 'view'
  const selectedFinancialType = Form.useWatch('financial_type', form)

  return (
    <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Typography.Text strong>Project Information</Typography.Text>
      <Form.Item
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (!value) {
                return Promise.reject('Project is required')
              }
              return projectOptions.find((opt) => value.includes(opt.name)) ||
                editMode
                ? Promise.resolve()
                : Promise.reject('The project is not found')
            },
          },
        ]}
        name={'project'}
        label="Project Name"
      >
        <Select
          showSearch
          disabled={editMode || viewMode}
          placeholder="Select a project"
        >
          {projectOptions.map((opt) => (
            <Select.Option
              value={`${opt.id}-${opt.name}`}
              key={`project-option:${opt.id}`}
            >
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="project_phase_id" label="Phase" required>
        <Select
          showSearch
          allowClear
          disabled={editMode || viewMode}
          placeholder="Select a phase"
        >
          {projectPhases.map((phase) => (
            <Select.Option
              value={createSelectValue(phase.id, phase.name)}
              key={`phase-option:${phase.id}`}
            >
              {phase.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={'pt'}
        label="Pt No"
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (!value) {
                return Promise.reject('Pt No is required')
              }
              if (!projectPTs || projectPTs.length === 0 || editMode) {
                return Promise.resolve()
              }
              return projectPTs.find((opt) => value.includes(opt.pt_no))
                ? Promise.resolve()
                : Promise.reject('PT No not found')
            },
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          placeholder="Select a PT No"
          disabled={!projectPTs || editMode || viewMode}
        >
          {projectPTs?.map((pt) => (
            <Select.Option
              value={createSelectValue(pt.id, pt.pt_no)}
              key={`pt-option:${pt.id}`}
            >
              {pt.pt_no}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Divider></Divider>

      <Typography.Text strong>Sale Information</Typography.Text>

      <Form.Item
        rules={[{ required: true, message: 'Purchaser name is required' }]}
        name={'purchaser_name'}
        label="Purchaser Name"
      >
        <Input placeholder="Enter purchaser name" disabled={viewMode} />
      </Form.Item>
      <Form.Item
        name={'purchaser_contact'}
        label="Purchaser Contact"
        rules={[{ required: true, message: 'Purchaser contact is required' }]}
        required
      >
        <Input placeholder="Enter purchaser contact" disabled={viewMode} />
      </Form.Item>
      <Form.Item
        name={'spa_price'}
        label="SPA Price"
        required
        rules={[
          {
            required: true,
            message: 'Please select a PT No to get SPA price',
          },
        ]}
      >
        <Input prefix={'RM '} readOnly disabled={viewMode}></Input>
      </Form.Item>
      <Form.Item name={'nett_price'} label="Nett Price">
        <Input prefix={'RM '} readOnly disabled></Input>
      </Form.Item>
      <Form.Item
        name={'booking_date'}
        label="Booking Date"
        required
        rules={[{ required: true, message: 'Booking date is required' }]}
      >
        <DatePicker disabled={viewMode} />
      </Form.Item>
      <Form.Item name={'sop_date'} label="SOP Date">
        <DatePicker disabled={viewMode} />
      </Form.Item>
      <Form.Item name={'checklist_date'} label="Original Checklist Date">
        <DatePicker disabled={viewMode} />
      </Form.Item>
      <Form.Item
        name={'differential_sum_amount'}
        label="Differential Sum Amount"
      >
        <Input prefix={'RM '} disabled={viewMode}></Input>
      </Form.Item>
      <Form.Item
        name={'receipt_paid_differential_sum'}
        label="Receipt of Paid Differential Sum"
      >
        <ImageUploadButton
          defaultFileList={
            form.getFieldValue('receipt_paid_differential_sum')?.fileList || []
          }
          disabled={viewMode}
        ></ImageUploadButton>
      </Form.Item>
      <Form.Item name={'spa_date'} label="SPA Date">
        <DatePicker disabled={viewMode} />
      </Form.Item>
      {[FINANCIAL_TYPE.BANK_LOAN, FINANCIAL_TYPE.GOV_LOAN].includes(
        selectedFinancialType
      ) && (
        <>
          <Form.Item name={'lo_date'} label="Acceptance LO Date">
            <DatePicker disabled={viewMode} />
          </Form.Item>
          <Form.Item name={'la_date'} label="Signing LA Date">
            <DatePicker disabled={viewMode} />
          </Form.Item>
        </>
      )}
      <Form.Item required name={'sale_status'} label="Sale Status">
        <Select disabled={viewMode}>
          {Object.entries(SaleStatus).map((opt) => (
            <Select.Option
              value={Number(opt[0])}
              key={`sale-status-option:${opt[0]}`}
            >
              {opt[1]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="purchaser_type"
        required
        label={'Purchase Information'}
        shouldUpdate
        rules={[
          {
            required: true,
            message: 'Purchase information is required',
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.reject('Purchase information is required')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <PurchaserCheckBox disabled={viewMode}></PurchaserCheckBox>
      </Form.Item>
      <Form.Item required name={'financial_type'} label={'Financial Type'}>
        <Radio.Group disabled={viewMode}>
          <Radio value={FINANCIAL_TYPE.BANK_LOAN}>Bank Loan</Radio>
          <Radio value={FINANCIAL_TYPE.GOV_LOAN}>Gov Loan</Radio>
          <Radio value={FINANCIAL_TYPE.CASH}>Cash</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        required
        name={'real_nett_price'}
        label="Nett Price (Real)"
        rules={[
          {
            required: true,
            message: 'Nett price is required for validation',
          },
          {
            validator: (_, value) => {
              if (value !== '' && !Number(value)) {
                return Promise.reject('Please enter the valid nett price')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input prefix={'RM '}></Input>
      </Form.Item>
      <Form.Item name={'sale_remark'} label="Sale Remark">
        <Input.TextArea disabled={viewMode}></Input.TextArea>
      </Form.Item>
      {phasePackagesOptions.length > 0 && (
        <>
          <Divider></Divider>
          <Typography.Text strong>Add-on Package</Typography.Text>
          <Form.Item label="Add-on" name="package_add_on">
            <Checkbox.Group options={[...phasePackagesOptions]} />
          </Form.Item>
        </>
      )}
      {selectedFinancialType === FINANCIAL_TYPE.BANK_LOAN && (
        <>
          <Divider></Divider>
          <Typography.Text strong>Bank Information</Typography.Text>
          <Form.List name="banks" initialValue={form.getFieldValue('banks')}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <React.Fragment key={`bank-array-field:${field.key + index}`}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      label="Bank"
                    >
                      <Input disabled={viewMode}></Input>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'banker_name']}
                      label="Banker Name"
                    >
                      <Input disabled={viewMode}></Input>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'banker_contact']}
                      label="Banker Contact"
                    >
                      <Input disabled={viewMode}></Input>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'banker_branch']}
                      label="Banker Branch"
                    >
                      <Input disabled={viewMode}></Input>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'loan_amount']}
                      label="Loan Amount"
                    >
                      <Input prefix={'RM '} disabled={viewMode}></Input>
                    </Form.Item>
                    {!viewMode && (
                      <Button
                        type="dashed"
                        onClick={() => remove(index)}
                        style={{ width: '100%' }}
                        icon={<PlusOutlined />}
                        disabled={viewMode}
                      >
                        Remove
                      </Button>
                    )}
                    <Divider></Divider>
                  </React.Fragment>
                ))}

                {!viewMode && (
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                    disabled={viewMode}
                  >
                    Add field
                  </Button>
                )}
              </>
            )}
          </Form.List>
        </>
      )}
      <Divider></Divider>
      <Typography.Text strong>Commission Information</Typography.Text>
      <div style={{ padding: 10 }}></div>
      {/* Get commissions info */}
      <Form.List
        name="commissions"
        initialValue={form.getFieldValue('commissions')}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space
                key={`commission-array-field:${field.key + index}`}
                align="baseline"
              >
                <Form.Item
                  {...field}
                  label={'Name'}
                  name={[field.name, 'username']}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    filterOption={(input, option) => {
                      return (option?.children ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }}
                    disabled={viewMode}
                  >
                    {users.map((opt) => (
                      <Select.Option
                        value={opt.username}
                        key={`commission-user-option:${opt.code}`}
                      >
                        {opt.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  label={'Rate'}
                  name={[field.name, 'rate']}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <InputNumber placeholder="0.02" disabled={viewMode} />
                </Form.Item>

                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
              </Space>
            ))}

            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '100%' }}
              icon={<PlusOutlined />}
              disabled={viewMode}
            >
              Add field
            </Button>
          </>
        )}
      </Form.List>
      <div style={{ paddingBottom: '10px' }} />
    </Form>
  )
}
