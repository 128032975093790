import React, { FC, useState } from 'react'
import { SimpleTable, Column, stringColumn } from '@m17/table'
import {
  DeveloperClaimBatchType,
  GetDeveloperClaimConvertedSaleResp,
} from '@m17/api-types'

type DeveloperClaimPageProps = {
  convertedSales: GetDeveloperClaimConvertedSaleResp[]
  selectedRowKeys: React.Key[]
  onSelectChange: (newSelectedRowKeys: React.Key[]) => void
}

const ConvertedSalesPage: FC<DeveloperClaimPageProps> = ({
  convertedSales,

  selectedRowKeys,
  onSelectChange,
}) => {
  const [columns] = useState<Column<GetDeveloperClaimConvertedSaleResp>[]>([
    {
      title: 'Project',
      ...stringColumn('project_name'),
    },
    {
      title: 'PT No',
      ...stringColumn('pt_no'),
    },
    {
      title: 'Claim Batch',
      dataIndex: 'developer_claims',
      render: (
        record: GetDeveloperClaimConvertedSaleResp['developer_claims']
      ) => {
        return record.map((r) => (
          <>
            <span>{`[${DeveloperClaimBatchType[r.batch_type]}]`}</span>{' '}
            <span>{r.batch_name}</span>
          </>
        ))
      },
    },
  ])

  return (
    <>
      <SimpleTable
        columns={columns}
        data={convertedSales}
        needIndex={false}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        rowKey="id"
      />
    </>
  )
}

export default ConvertedSalesPage
