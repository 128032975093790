import { DEVELOPER_CLAIM_BATCH_TYPE, SelectOption } from '@m17/api-types'
import { Form, FormInstance, Input, Select, InputNumber } from 'antd'
import React, { FC } from 'react'

export type DeveloperClaimBatchFormValues = {
  id?: number
  name: string
  type: number
  commission_pct?: number
  full_commission_pct?: number
}

type DeveloperClaimBatchFormProps = {
  form: FormInstance<DeveloperClaimBatchFormValues>
  typeOptions: SelectOption<number>[]
}

const DeveloperClaimBatchForm: FC<DeveloperClaimBatchFormProps> = ({
  form,
  typeOptions,
}) => {
  const watchClaimType = Form.useWatch('type', form)
  const watchFullComm = Form.useWatch('full_commission_pct', form) || 0
  return (
    <Form layout="horizontal" form={form} id="claim-batch-form">
      <Form.Item hidden name="id" />
      <Form.Item
        name="name"
        label="Batch Name"
        required
        rules={[{ required: true, message: 'Please enter a name' }]}
      >
        <Input placeholder="Enter batch name" />
      </Form.Item>
      <Form.Item
        name="type"
        label="Claim Type"
        required
        rules={[{ required: true, message: 'Please select a type' }]}
      >
        <Select>
          {typeOptions.map((to) => (
            <Select.Option
              key={`developer-claim-batch-type:${to.value}`}
              value={to.value}
            >
              {to.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="full_commission_pct"
        label="Full Commission (%)"
        required
        rules={[
          { required: true, message: 'Please enter commission' },
          {
            validator: async (_, value) => {
              if (value <= 0) {
                throw new Error('Must be more than 0')
              }
              if (value > 100) {
                throw new Error('Must not exceed 100')
              }
            },
          },
        ]}
      >
        <InputNumber placeholder="Commission" step={0.1} />
      </Form.Item>
      {watchClaimType !== DEVELOPER_CLAIM_BATCH_TYPE.FULL_CLAIM && (
        <Form.Item
          name="commission_pct"
          label="Commission (%)"
          required
          rules={[
            { required: true, message: 'Please enter claim percentage' },
            {
              validator: async (_, value) => {
                if (value <= 0) {
                  throw new Error('Must be more than 0')
                }
                if (value > watchFullComm) {
                  throw new Error('Must not exceed full commission %')
                }
              },
            },
          ]}
        >
          <InputNumber placeholder="Claim Percentage" step={0.1} />
        </Form.Item>
      )}
    </Form>
  )
}

export default DeveloperClaimBatchForm
