import React, { FC } from 'react'
import { Form, Input, FormInstance } from 'antd'

const { TextArea } = Input

export type LeaderRejectClaimFormValues = {
  claim_id: number
  claim_remark: string
  pt_no: string
}

type LeaderRejectClaimFormProps = {
  form: FormInstance<LeaderRejectClaimFormValues>
}

const LeaderRejectClaimForm: FC<LeaderRejectClaimFormProps> = ({ form }) => {
  return (
    <Form form={form}>
      <Form.Item name="claim_id" hidden />
      <Form.Item shouldUpdate label="PT NO">
        {() => form.getFieldValue('pt_no')}
      </Form.Item>
      <Form.Item
        name="claim_remark"
        label="Remark"
        rules={[
          {
            required: true,
            message: 'Please enter the reason.',
          },
        ]}
      >
        <TextArea placeholder="Enter reject reason" />
      </Form.Item>
    </Form>
  )
}

export default LeaderRejectClaimForm
