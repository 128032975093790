import { Button, Form, Input, Typography } from 'antd'

const { Paragraph } = Typography

type LoginProps = {
  onFinish: (value: { username: string; password: string }) => void
}

export default function Login({ onFinish }: LoginProps) {
  return (
    <div
      style={{
        display: 'grid',
        minHeight: '100vh',
        alignItems: 'center',
      }}
    >
      <Form
        onFinish={onFinish}
        style={{
          minWidth: 320,
          maxWidth: 800,
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img
          src="/logo512.png"
          alt="logo"
          style={{ width: 192, margin: '0 auto' }}
        />
        <div style={{ padding: 24 }}></div>
        <Paragraph>Username</Paragraph>
        <Form.Item
          name={'username'}
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Paragraph>Password</Paragraph>
        <Form.Item
          name={'password'}
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input type={'password'}></Input>
        </Form.Item>

        <div style={{ paddingBottom: 12 }}></div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </div>
      </Form>
    </div>
  )
}
