import ServerDownImage from '../assets/server-down.svg'

export default function ErrorPage() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img
        src={ServerDownImage}
        alt="server down"
        style={{
          maxWidth: '50%',
        }}
      />
      <div style={{ height: 24 }}></div>
      <h1>Oops! Something went wrong.</h1>
    </div>
  )
}
