import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import useSidebarItems from '../hooks/useSidebarItems'

const { Sider } = Layout

type LayoutUIProps = {
  onMenuSelect: React.ComponentProps<typeof Menu>['onSelect']
  renderContent: React.ReactNode
  menuActiveKey: React.ComponentProps<typeof Menu>['activeKey']
}

// works when >=4.20.0, recommended ✅

export default function LayoutUI({
  onMenuSelect,
  renderContent,
  menuActiveKey,
}: LayoutUIProps) {
  const [isCollapse, setIsCollapse] = useState(false)
  const items = useSidebarItems()

  return (
    <Layout hasSider>
      <Sider collapsible collapsed={isCollapse} onCollapse={setIsCollapse}>
        <Menu
          theme="dark"
          mode="inline"
          activeKey={menuActiveKey}
          selectedKeys={[menuActiveKey!]}
          onSelect={onMenuSelect}
          items={items}
          style={{
            position: 'sticky',
            top: 0,
          }}
        />
      </Sider>
      <Layout className="site-layout" style={{ minHeight: '100vh' }}>
        {renderContent}
      </Layout>
    </Layout>
  )
}
