import React, { useCallback } from 'react'
import ProjectPage, { PackageRecordType } from '../views/ProjectPage'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  assign,
  createPhase,
  updatePhase,
  fetchProject,
  fetchProjectDetail,
  fetchProjectPhases,
  fetchProjectRebates,
  fetchProjectPTs,
  unassign,
  createProject,
  updateProject,
} from '../api/project'
import {
  getAllProjectPhasePackage,
  createProjectPhasePackage,
  updateProjectPhasePackage,
  deleteProjectPhasePackage,
} from '../api/projectPhase'
import Loading from './Loading'
import { fetchUserDetail, getAllUsers } from '../api/user'
import { AutoComplete, Form, message, Modal, Space } from 'antd'
import useDisclosure from '../hooks/useDisclose'
import RebateForm, { RebateFormValues } from '../views/RebateForm'
import { createRebate, deleteRebate, updateRebate } from '../api/rebate'
import { createSelectValue, parseIdFromSelectValue, removeNull } from '../utils'
import moment from 'moment'
import { ProjectForm, ProjectFormValues } from './ProjectForm'
import PTForm from '../views/PTForm'
import { PTFormValues } from '../types/pt'
import { createPT, deletePT, updatePT, downloadImportPTFormat } from '../api/pt'
import ProjectPhasePackageForm, {
  ProjectPhasePackageFormValues,
} from './ProjectPhasePackageForm'
import { CreateRebatePayload, UpdateRebatePayload } from '@m17/api-types'
import ProjectPhaseForm, { ProjectPhaseFormValues } from './ProjectPhaseForm'
import { ProjectPhase } from '../types/projectPhase'

export default function ProjectPageContainer() {
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
  const {
    data,
    isLoading,
    isError,
    refetch: refetchProjects,
  } = useQuery(['project'], fetchProject)
  const [isOpenCreateProjectForm, setOpenCreateProjectForm] =
    React.useState(false)
  const [isOpenCreatePhaseForm, setOpenCreatePhaseForm] = React.useState(false)
  const [value, setValue] = React.useState('')
  const { data: users } = useQuery(['projectDetail'], getAllUsers)
  const [itemSelected, setItemSelected] = React.useState<any>(null)
  const [modal, setModal] = React.useState(false)
  const [phaseSelected, setPhaseSelected] = React.useState<any>()
  const [rebateSelected, setRebateSelected] = React.useState<any>()

  const { data: userDetail } = useQuery(['get-user-detail'], fetchUserDetail)
  const {
    isOpen: isOpenCreateRebateForm,
    onClose: onCloseCreateRebateForm,
    onOpen: onOpenCreateRebateForm,
  } = useDisclosure()
  const {
    isOpen: isOpenEditRebateForm,
    onClose: onCloseEditRebateForm,
    onOpen: onOpenEditRebateForm,
  } = useDisclosure()
  const {
    isOpen: isOpenCreatePTForm,
    onClose: onCloseCreatePTForm,
    onOpen: onOpenCreatePTForm,
  } = useDisclosure()
  const {
    isOpen: isOpenEditPTForm,
    onClose: onCloseEditPTForm,
    onOpen: onOpenEditPTForm,
  } = useDisclosure()
  const {
    isOpen: isOpenCreateProjectPhasePackageForm,
    onClose: onCloseCreateProjectPhasePackageForm,
    onOpen: onOpenCreateProjectPhasePackageForm,
  } = useDisclosure()
  const {
    isOpen: isOpenEditProjectPhasePackageForm,
    onClose: onCloseEditProjectPhasePackageForm,
    onOpen: onOpenEditProjectPhasePackageForm,
  } = useDisclosure()
  const {
    isOpen: isOpenEditProjectForm,
    onClose: onCloseEditProjectForm,
    onOpen: onOpenEditProjectForm,
  } = useDisclosure()
  const {
    isOpen: isOpenEditPhaseForm,
    onClose: onCloseEditPhaseForm,
    onOpen: onOpenEditPhaseForm,
  } = useDisclosure()
  const [form] = Form.useForm<RebateFormValues>()
  const [phaseForm] = Form.useForm<ProjectPhaseFormValues>()
  const [ptForm] = Form.useForm<PTFormValues>()
  const [projectPhasePackageForm] =
    Form.useForm<ProjectPhasePackageFormValues>()
  const [editProjectPhasePackageForm] =
    Form.useForm<ProjectPhasePackageFormValues>()
  const [projectForm] = Form.useForm<ProjectFormValues>()
  const { data: projectData, refetch: refetchProjectDetail } = useQuery(
    ['project-detail', itemSelected?.id],
    () => fetchProjectDetail(itemSelected!.id),
    {
      enabled: !!itemSelected,
    }
  )

  const { data: projectPhasesData, refetch: refetchPhases } = useQuery(
    ['project-phases', itemSelected?.id],
    () => fetchProjectPhases(itemSelected!.id),
    {
      enabled: !!itemSelected,
    }
  )
  const { data: rebateData, refetch: refetchRebates } = useQuery(
    ['project-rebates', itemSelected?.id, phaseSelected],
    () => fetchProjectRebates(itemSelected!.id, phaseSelected.split('-')[0]),
    {
      enabled: !!phaseSelected && !!itemSelected,
    }
  )
  const { data: ptData, refetch: refetchPT } = useQuery(
    ['project-pts', itemSelected?.id],
    () => fetchProjectPTs(itemSelected!.id),
    {
      enabled: !!itemSelected,
    }
  )
  const {
    data: projectPhasePackageData,
    refetch: refetchProjectPhasePackageData,
  } = useQuery(
    ['project-phase-package', phaseSelected],
    ({ queryKey }) =>
      getAllProjectPhasePackage({
        phase_id: queryKey[1].split('-')[0] as string,
      }),
    {
      enabled: !!phaseSelected,
    }
  )

  const { mutateAsync: createRebateMutate } = useMutation(createRebate)
  const { mutateAsync: updateRebateMutate } = useMutation(updateRebate)
  const { mutateAsync: deleteRebateMutate } = useMutation(deleteRebate)
  const { mutateAsync: createPhaseMutate, isLoading: isLoadingCreatePhase } =
    useMutation(createPhase)
  const { mutateAsync: updatePhaseMutate, isLoading: isLoadingUpdatePhase } =
    useMutation(updatePhase)
  const { mutateAsync: createPTMutate } = useMutation(createPT)
  const { mutateAsync: updatePTMutate } = useMutation(updatePT)
  const { mutateAsync: deletePTMutate } = useMutation(deletePT)
  const { mutateAsync: createProjectPhasePackageMutate } = useMutation(
    createProjectPhasePackage
  )
  const { mutateAsync: updateProjectPhasePackageMutate } = useMutation(
    updateProjectPhasePackage
  )
  const { mutateAsync: deleteProjectPhasePackageMutate } = useMutation(
    deleteProjectPhasePackage
  )
  const {
    mutateAsync: createProjectMutate,
    isLoading: isLoadingCreateProject,
  } = useMutation(createProject)
  const {
    mutateAsync: updateProjectMutate,
    isLoading: isLoadingUpdateProject,
  } = useMutation(updateProject)

  const onSelectPhase = useCallback((phase: string) => {
    setPhaseSelected(phase)
  }, [])

  const onSelectProject = useCallback(
    (item: any) => {
      form.resetFields()
      ptForm.resetFields()
      phaseForm.resetFields()
      setItemSelected(item)
      setPhaseSelected(undefined)
    },
    [form, ptForm, phaseForm]
  )

  const onAddRebateClick = useCallback(() => {
    form.resetFields()
    onOpenCreateRebateForm()
  }, [onOpenCreateRebateForm, form])

  const onCancelRebateForm = useCallback(() => {
    onCloseCreateRebateForm()
  }, [onCloseCreateRebateForm])

  const onOkCreateRebateForm = useCallback(async () => {
    try {
      await form.validateFields()
    } catch {
      message.error('Please make sure the form is correct!')
      return
    }

    if (
      form.getFieldsError().filter((item) => item.errors.length > 0).length > 0
    ) {
      message.error('Please make sure the field is filled up correctly')
      return
    }

    const data = form.getFieldsValue()
    // preprocess the data
    const payload: CreateRebatePayload = {
      project_id: Number(itemSelected.id),
      project_phase_id: Number(parseIdFromSelectValue(phaseSelected)),
      effective_date: data.effective_date.unix(),
      a_rebate_pct_bumi:
        data.a_rebate_pct_bumi && Number(data.a_rebate_pct_bumi),
      a_rebate_pct_non_bumi:
        data.a_rebate_pct_non_bumi && Number(data.a_rebate_pct_non_bumi),
      b_rebate_amt_bumi:
        data.b_rebate_amt_bumi && Number(data.b_rebate_amt_bumi),
      b_rebate_amt_non_bumi:
        data.b_rebate_amt_non_bumi && Number(data.b_rebate_amt_non_bumi),
      b_rebate_method: data.b_rebate_method && Number(data.b_rebate_method),
      c_rebate_pct_all: data.c_rebate_pct_all && Number(data.c_rebate_pct_all),
      c_rebate_pct_all_2:
        data.c_rebate_pct_all_2 && Number(data.c_rebate_pct_all_2),
      c_sop_date_pct_all:
        data.c_sop_date_pct_all && Number(data.c_sop_date_pct_all),
      c_sop_date_amt_all:
        data.c_sop_date_amt_all && Number(data.c_sop_date_amt_all),
      c_rebate_amt_all: data.c_rebate_amt_all && Number(data.c_rebate_amt_all),
      d_introducer_fee_amt:
        data.d_introducer_fee_amt && Number(data.d_introducer_fee_amt),
      d_rebate_pct_existing_buyer:
        data.d_rebate_pct_existing_buyer && Number(data.d_introducer_fee_amt),
    }
    const processedPayload = removeNull(payload)

    await createRebateMutate(processedPayload)
    message.success('Rebate created successfully')
    await refetchRebates()
    onCloseCreateRebateForm()
  }, [
    form,
    onCloseCreateRebateForm,
    createRebateMutate,
    refetchRebates,
    itemSelected,
    phaseSelected,
  ])

  const onEditRebateClick = useCallback(
    (rebate: any) => {
      setRebateSelected({
        ...rebate,
        effective_date: moment.unix(rebate.effective_date),
      })
      form.setFieldsValue({
        ...rebate,
        effective_date: moment.unix(rebate.effective_date),
      })
      onOpenEditRebateForm()
    },
    [setRebateSelected, form, onOpenEditRebateForm]
  )

  const onOkEditRebateClick = useCallback(async () => {
    try {
      await form.validateFields()
    } catch {
      message.error('Please make sure the form is correct!')
      return
    }

    if (
      form.getFieldsError().filter((item) => item.errors.length > 0).length > 0
    ) {
      message.error('Please make sure the field is filled up correctly')
      return
    }

    const data = form.getFieldsValue()

    // preprocess the data
    const payload: UpdateRebatePayload = {
      rebate_id: Number(rebateSelected.id),
      project_id: Number(itemSelected.id),
      project_phase_id: Number(parseIdFromSelectValue(phaseSelected)),
      effective_date: data.effective_date.unix(),
      a_rebate_pct_bumi:
        data.a_rebate_pct_bumi && Number(data.a_rebate_pct_bumi),
      a_rebate_pct_non_bumi:
        data.a_rebate_pct_non_bumi && Number(data.a_rebate_pct_non_bumi),
      b_rebate_amt_bumi:
        data.b_rebate_amt_bumi && Number(data.b_rebate_amt_bumi),
      b_rebate_amt_non_bumi:
        data.b_rebate_amt_non_bumi && Number(data.b_rebate_amt_non_bumi),
      b_rebate_method: data.b_rebate_method && Number(data.b_rebate_method),
      c_rebate_pct_all: data.c_rebate_pct_all && Number(data.c_rebate_pct_all),
      c_rebate_pct_all_2:
        data.c_rebate_pct_all_2 && Number(data.c_rebate_pct_all_2),
      c_sop_date_pct_all:
        data.c_sop_date_pct_all && Number(data.c_sop_date_pct_all),
      c_sop_date_amt_all:
        data.c_sop_date_amt_all && Number(data.c_sop_date_amt_all),
      c_rebate_amt_all: data.c_rebate_amt_all && Number(data.c_rebate_amt_all),
      d_introducer_fee_amt:
        data.d_introducer_fee_amt && Number(data.d_introducer_fee_amt),
      d_rebate_pct_existing_buyer:
        data.d_rebate_pct_existing_buyer && Number(data.d_introducer_fee_amt),
    }
    const processedPayload = removeNull(payload)

    await updateRebateMutate(processedPayload)
    message.success('Rebate edited successfully')
    await refetchRebates()
    onCloseEditRebateForm()
  }, [
    form,
    phaseSelected,
    rebateSelected,
    itemSelected,
    onCloseEditRebateForm,
    updateRebateMutate,
    refetchRebates,
  ])

  const onCancelRebateClick = useCallback(() => {
    onCloseEditRebateForm()
  }, [onCloseEditRebateForm])

  const onComfirmDeleteRebateClick = useCallback(
    async (rebateId: number) => {
      await deleteRebateMutate({ rebate_id: rebateId })
      await refetchRebates()
    },
    [deleteRebateMutate, refetchRebates]
  )

  const onAddPTClick = useCallback(() => {
    if (projectData) {
      ptForm.setFieldsValue({
        project: createSelectValue(projectData.id, projectData.name),
      }) // Initial bumi_lot value
      onOpenCreatePTForm()
    }
  }, [ptForm, projectData, onOpenCreatePTForm])

  const getPTFormValues = useCallback(async () => {
    // Validation
    try {
      await ptForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }
    if (
      ptForm.getFieldsError().filter((item) => item.errors.length > 0).length >
      0
    ) {
      message.error('Please make sure the field is filled up correctly')
      return
    }
    const data = ptForm.getFieldsValue()
    const draft: any = { ...data }
    // process the data
    draft.project_id = parseIdFromSelectValue(draft.project)
    delete draft.project
    if (draft.project_phase) {
      draft.project_phase_id = parseIdFromSelectValue(draft.project_phase)
      delete draft.project_phase
    }
    draft.spa_price = Number(draft.spa_price)
    draft.spa_price_bumi = Number(draft.spa_price_bumi)

    const processedData = removeNull(draft)
    return processedData
  }, [ptForm])

  const onConfirmCreatePT = useCallback(async () => {
    const formValues = await getPTFormValues()
    try {
      await createPTMutate(formValues)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      }
      return
    }
    message.success('PT created successfully')
    ptForm.resetFields()
    await refetchPT()
    onCloseCreatePTForm()
  }, [getPTFormValues, onCloseCreatePTForm, refetchPT, createPTMutate, ptForm])

  const onEditPTClick = useCallback(
    (data: any) => {
      if (projectData) {
        ptForm.resetFields()
        const phaseData = projectPhasesData?.find(
          (d) => d.id === data.project_phase_id
        )
        console.log(phaseData)
        ptForm.setFieldsValue({
          ...data,
          project: createSelectValue(projectData.id, projectData.name),
          project_phase: phaseData
            ? `${phaseData.id}-${phaseData.name}`
            : undefined,
        })
        onOpenEditPTForm()
      }
    },
    [ptForm, projectData, projectPhasesData, onOpenEditPTForm]
  )

  const onOkEditPT = useCallback(async () => {
    const formValues = await getPTFormValues()
    await updatePTMutate(formValues)
    message.success('PT updated successfully')
    await refetchPT()
    onCloseEditPTForm()
  }, [getPTFormValues, updatePTMutate, refetchPT, onCloseEditPTForm])

  const onCancelEditPT = useCallback(() => {
    onCloseEditPTForm()
    ptForm.resetFields()
  }, [ptForm, onCloseEditPTForm])

  const onConfirmDeletePTClick = useCallback(
    async (ptId: number) => {
      await deletePTMutate({ id: ptId })
      await refetchPT()
      message.success('PT deleted successfully')
    },
    [deletePTMutate, refetchPT]
  )

  const onDownloadPTImportFormatClick = useCallback(async () => {
    const blob = await downloadImportPTFormat()
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `import_format.csv`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode?.removeChild(link)
  }, [])

  const onDoneUploadPTCSV = useCallback(async () => {
    await refetchPT()
  }, [refetchPT])

  const onAddPackageClick = useCallback(() => {
    projectPhasePackageForm.resetFields()
    onOpenCreateProjectPhasePackageForm()
  }, [projectPhasePackageForm, onOpenCreateProjectPhasePackageForm])

  const onCancelAddPackage = useCallback(() => {
    onCloseCreateProjectPhasePackageForm()
    projectPhasePackageForm.resetFields()
  }, [projectPhasePackageForm, onCloseCreateProjectPhasePackageForm])

  const onOkAddPackage = useCallback(async () => {
    try {
      projectPhasePackageForm.validateFields()
    } catch (error) {
      message.error('Please fill in the form correctly.')
      return
    }

    try {
      const { name, price } = projectPhasePackageForm.getFieldsValue()
      await createProjectPhasePackageMutate({
        name,
        price,
        phase_id: Number(phaseSelected.split('-')[0]),
      })
      await refetchProjectPhasePackageData()
      onCloseCreateProjectPhasePackageForm()
      message.success('Add package successfully')
    } catch (error) {
      message.error('Something went wrong')
    }
  }, [
    projectPhasePackageForm,
    phaseSelected,
    createProjectPhasePackageMutate,
    refetchProjectPhasePackageData,
    onCloseCreateProjectPhasePackageForm,
  ])

  const onEditPackageClick = useCallback(
    (record: PackageRecordType) => {
      editProjectPhasePackageForm.resetFields()
      editProjectPhasePackageForm.setFieldsValue({
        id: record.id,
        name: record.name,
        price: record.price,
      })
      onOpenEditProjectPhasePackageForm()
    },
    [editProjectPhasePackageForm, onOpenEditProjectPhasePackageForm]
  )

  const onOkEditPackage = useCallback(async () => {
    try {
      editProjectPhasePackageForm.validateFields()
    } catch (error) {
      message.error('Please fill in the form correctly.')
      return
    }

    try {
      const { name, price, id } = editProjectPhasePackageForm.getFieldsValue()
      if (!id) {
        throw new Error('Missing package id. Please refresh and try again.')
      }
      await updateProjectPhasePackageMutate({
        id,
        name,
        price,
      })
      await refetchProjectPhasePackageData()
      onCloseEditProjectPhasePackageForm()
      message.success('Update package successfully')
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      } else {
        message.error('Something went wrong')
      }
    }
  }, [
    editProjectPhasePackageForm,
    updateProjectPhasePackageMutate,
    refetchProjectPhasePackageData,
    onCloseEditProjectPhasePackageForm,
  ])

  const onCancelEditPackage = useCallback(() => {
    onCloseEditProjectPhasePackageForm()
  }, [onCloseEditProjectPhasePackageForm])

  const onDeletePackage = useCallback(
    async (id: string) => {
      try {
        await deleteProjectPhasePackageMutate({
          id,
        })
        await refetchProjectPhasePackageData()
        message.success('Delete package successfully')
      } catch (error) {
        message.error('Something went wrong')
      }
    },
    [deleteProjectPhasePackageMutate, refetchProjectPhasePackageData]
  )

  if (isLoading || isError) {
    return <Loading></Loading>
  }

  const filterData = (users || []).filter((item: any) =>
    !value ? true : item.username.includes(value)
  )

  const onOk = async () => {
    if (!filterData.find((data: any) => data.username === value)) {
      message.error('User not found')
      return
    }
    await assign(itemSelected.id, value)
    setValue('')
    setModal(false)
    message.success('Assign success')
    refetchProjectDetail()
  }

  const onOkCreateProject = async () => {
    try {
      await projectForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    try {
      const data = projectForm.getFieldsValue()
      await createProjectMutate(data)
      await refetchProjects()
      await refetchProjectDetail()
      message.success('Project created successfully.')
      setOpenCreateProjectForm(false)
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  const onEditProjectClick = () => {
    if (projectData) {
      projectForm.setFieldsValue({
        area: projectData.area,
        developer: projectData.developer,
        name: projectData.name,
      })
    }
    onOpenEditProjectForm()
  }

  const onOkEditProject = async () => {
    try {
      await projectForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    try {
      const data = projectForm.getFieldsValue()
      await updateProjectMutate({
        ...data,
        projectId: itemSelected.id,
      })
      await refetchProjects()
      await refetchProjectDetail()
      message.success('Project edited successfully.')
      onCloseEditProjectForm()
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  const onOkCreatePhaseForm = async () => {
    try {
      await phaseForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    try {
      const data = phaseForm.getFieldsValue()
      await createPhaseMutate({
        ...data,
        projectId: itemSelected.id,
      })
      forceUpdate()
      message.success('Phase created successfully.')
      refetchPhases()
      phaseForm.resetFields()
      setOpenCreatePhaseForm(false)
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  const onEditPhaseClick = (phase: ProjectPhase) => {
    phaseForm.setFieldsValue({
      name: phase.name,
      phaseId: phase.id,
    })
    onOpenEditPhaseForm()
  }

  const onOkEditPhaseForm = async () => {
    try {
      await phaseForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    try {
      const data = phaseForm.getFieldsValue()
      if (!data.phaseId) {
        throw new Error('Phase Id not found')
      }
      await updatePhaseMutate({
        ...data,
        phaseId: data.phaseId!,
        projectId: itemSelected.id,
      })
      await refetchPhases()
      message.success('Phase edited successfully.')
      onCloseEditPhaseForm()
      phaseForm.resetFields()
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  if (isLoading) {
    return <Loading></Loading>
  }

  return (
    <div>
      <ProjectPage
        userDetail={userDetail}
        assigned={projectData?.assigned || []}
        itemList={data}
        data={projectData}
        onClick={onSelectProject}
        onAddUserClick={() => setModal(true)}
        onRemoveClick={async (record) => {
          await unassign(itemSelected.id, record.username)
          message.success('Removed user')
          refetchProjectDetail()
        }}
        projectPhases={projectPhasesData}
        phaseSelected={phaseSelected}
        projectRebates={rebateData || []}
        projectPTs={ptData || []}
        projectPhasePackages={projectPhasePackageData || []}
        onSelectPhase={onSelectPhase}
        onAddRebateClick={onAddRebateClick}
        onEditRebateClick={onEditRebateClick}
        onComfirmDeleteRebateClick={onComfirmDeleteRebateClick}
        onAddProjectClick={() => setOpenCreateProjectForm(true)}
        onEditProjectClick={onEditProjectClick}
        onAddPhaseClick={() => setOpenCreatePhaseForm(true)}
        onEditPhaseClick={onEditPhaseClick}
        onAddPTClick={onAddPTClick}
        onEditPTClick={onEditPTClick}
        onConfirmDeletePTClick={onConfirmDeletePTClick}
        onDownloadPTImportFormatClick={onDownloadPTImportFormatClick}
        onDoneUploadPTCSV={onDoneUploadPTCSV}
        onAddPackageClick={onAddPackageClick}
        onEditPackageClick={onEditPackageClick}
        onDeletePackage={onDeletePackage}
      ></ProjectPage>
      <Modal
        visible={modal}
        okText={'OK'}
        onOk={onOk}
        onCancel={() => setModal(false)}
      >
        <div></div>
        <Space direction="horizontal">
          <span>Users: </span>
          <AutoComplete
            value={value}
            style={{ width: 200 }}
            onSelect={(value: any) => setValue(value)}
            onChange={(value) => setValue(value)}
            options={filterData.map((user: any) => ({
              value: user.username,
            }))}
            placeholder="input here"
          ></AutoComplete>
        </Space>
      </Modal>
      <Modal
        open={isOpenCreateRebateForm}
        okText="OK"
        onOk={onOkCreateRebateForm}
        onCancel={onCancelRebateForm}
        title="Create Rebate"
        width={1000}
      >
        <RebateForm form={form} />
      </Modal>
      <Modal
        open={isOpenEditRebateForm}
        okText="OK"
        onOk={onOkEditRebateClick}
        onCancel={onCancelRebateClick}
        title="Edit Rebate"
        width={1000}
        destroyOnClose
      >
        <RebateForm form={form} />
      </Modal>
      <Modal
        open={isOpenCreateProjectForm}
        title="Create Project"
        destroyOnClose
        onCancel={() => setOpenCreateProjectForm(false)}
        onOk={onOkCreateProject}
        okButtonProps={{
          loading: isLoadingCreateProject,
        }}
      >
        <ProjectForm
          form={projectForm}
          onFinish={() => setOpenCreateProjectForm(false)}
        />
      </Modal>
      <Modal
        open={isOpenEditProjectForm}
        title="Edit Project"
        destroyOnClose
        onCancel={onCloseEditProjectForm}
        onOk={onOkEditProject}
        okButtonProps={{
          loading: isLoadingUpdateProject,
        }}
      >
        <ProjectForm form={projectForm} onFinish={onCloseEditProjectForm} />
      </Modal>
      <Modal
        open={isOpenCreatePhaseForm}
        title="Create Phase"
        destroyOnClose
        onCancel={() => setOpenCreatePhaseForm(false)}
        onOk={onOkCreatePhaseForm}
        okButtonProps={{
          loading: isLoadingCreatePhase,
        }}
      >
        <ProjectPhaseForm form={phaseForm} />
      </Modal>
      <Modal
        open={isOpenEditPhaseForm}
        title="Edit Phase"
        destroyOnClose
        onCancel={onCloseEditPhaseForm}
        onOk={onOkEditPhaseForm}
        okButtonProps={{
          loading: isLoadingUpdatePhase,
        }}
      >
        <ProjectPhaseForm form={phaseForm} />
      </Modal>
      <Modal
        open={isOpenCreatePTForm}
        onCancel={onCloseCreatePTForm}
        onOk={onConfirmCreatePT}
        title="Create PT"
      >
        <PTForm
          form={ptForm}
          projectOptions={data || []}
          projectPhaseOptions={projectPhasesData || []}
        />
      </Modal>
      <Modal
        open={isOpenEditPTForm}
        onCancel={onCancelEditPT}
        onOk={onOkEditPT}
        title="Edit PT"
      >
        <PTForm
          form={ptForm}
          projectOptions={data || []}
          projectPhaseOptions={projectPhasesData || []}
        />
      </Modal>
      <Modal
        open={isOpenCreateProjectPhasePackageForm}
        onCancel={onCancelAddPackage}
        onOk={onOkAddPackage}
        title="Create Package"
      >
        <ProjectPhasePackageForm form={projectPhasePackageForm} />
      </Modal>
      <Modal
        open={isOpenEditProjectPhasePackageForm}
        onCancel={onCancelEditPackage}
        onOk={onOkEditPackage}
        title="Edit Package"
      >
        <ProjectPhasePackageForm form={editProjectPhasePackageForm} />
      </Modal>
    </div>
  )
}
