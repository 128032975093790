import React, { useCallback } from 'react'
import {
  getAllClaimsForLeaderApproval,
  leaderRejectClaim,
  leaderApproveClaim,
} from '../api/claim'
import { useQuery, useMutation } from '@tanstack/react-query'
import LeaderApprovalPage from '../views/LeaderApprovalPage'
import { Form, Modal, message } from 'antd'
import SaleForm from '../views/SaleForm'
import { getAllUsers } from '../api/user'
import useDisclosure from '../hooks/useDisclose'
import moment from 'moment'
import LeaderRejectClaimForm, {
  LeaderRejectClaimFormValues,
} from './LeaderRejectClaimForm'

const LeaderClaimApprovalContainer = () => {
  const [form] = Form.useForm()
  const [leaderRejectClaimForm] = Form.useForm<LeaderRejectClaimFormValues>()
  const {
    isOpen: isOpenSaleForm,
    onClose: onCloseSaleForm,
    onOpen: onOpenSaleForm,
  } = useDisclosure()
  const {
    isOpen: isOpenRejectClaimForm,
    onClose: onCloseRejectClaimForm,
    onOpen: onOpenRejectClaimForm,
  } = useDisclosure()

  const { data, refetch: refetchClaims } = useQuery(
    ['all-claims-for-leader-approval'],
    getAllClaimsForLeaderApproval
  )

  const { data: users } = useQuery(['user'], getAllUsers)

  const { mutateAsync: leaderRejectClaimFn } = useMutation(
    ['leader-reject-claim'],
    leaderRejectClaim
  )
  const { mutateAsync: leaderApproveClaimFn } = useMutation(
    ['leader-approve-claim'],
    leaderApproveClaim
  )

  const onViewDetails = (record: any) => {
    const pt =
      record.pt_id && record?.project_pt
        ? `${record.project_pt.pt_no}`
        : record.pt
    const formValues = {
      ...record,
      project: `${record['project']['name']}`,
      ...(record.project_phase_id
        ? {
            project_phase_id: `${record.project_phase.name}`,
          }
        : {}),
      booking_date: moment(record.booking_date, 'X'),
      spa_date: record.spa_date && moment(record.spa_date, 'X'),
      la_date: record.la_date && moment(record.la_date, 'X'),
      lo_date: record.lo_date && moment(record.lo_date, 'X'),
      commissions: record.commission.map((comm: any) => ({
        rate: comm.rate,
        username: comm.user.username,
      })),
      pt,
      sop_date: record.sop_date && moment(record.sop_date, 'X'),
    }
    form.setFieldsValue(formValues)
    onOpenSaleForm()
  }

  const onRejectClick = useCallback(
    (record: { claim?: { id: number }; pt_no: string }) => {
      leaderRejectClaimForm.resetFields()
      leaderRejectClaimForm.setFieldsValue({
        claim_id: record?.claim?.id,
        pt_no: record.pt_no,
      })
      onOpenRejectClaimForm()
    },
    [onOpenRejectClaimForm, leaderRejectClaimForm]
  )

  const onOkRejectClaim = useCallback(async () => {
    try {
      await leaderRejectClaimForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    const data = leaderRejectClaimForm.getFieldsValue()
    try {
      await leaderRejectClaimFn({
        claim_id: data.claim_id,
        claim_remark: data.claim_remark,
      })

      onCloseRejectClaimForm()
      message.success('Reject claim successfully.')
      await refetchClaims()
    } catch (error) {
      message.error('Something went wrong. Please try again.')
    }
  }, [
    refetchClaims,
    onCloseRejectClaimForm,
    leaderRejectClaimForm,
    leaderRejectClaimFn,
  ])

  const onConfirmApprove = useCallback(
    async (record: { claim?: { id: number }; pt_no: string }) => {
      try {
        await leaderApproveClaimFn({
          claim_id: record.claim!.id,
        })
        message.success('Approve claim successfully.')
        await refetchClaims()
      } catch (error) {
        message.error('Something went wrong. Please try again.')
      }
    },
    [refetchClaims, leaderApproveClaimFn]
  )

  return (
    <>
      <LeaderApprovalPage
        claimData={data || []}
        onViewDetails={onViewDetails}
        onRejectClick={onRejectClick}
        onConfirmApprove={onConfirmApprove}
      />
      <Modal
        title="Sale Details"
        onOk={onCloseSaleForm}
        onCancel={onCloseSaleForm}
        cancelButtonProps={{ hidden: true }}
        open={isOpenSaleForm}
        width={720}
      >
        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
          <SaleForm
            projectOptions={[]}
            form={form}
            users={users || []}
            projectPhases={[]}
            projectPTs={[]}
            mode="view"
          ></SaleForm>
        </div>
      </Modal>
      <Modal
        title="Reject claim"
        open={isOpenRejectClaimForm}
        onOk={onOkRejectClaim}
        onCancel={onCloseRejectClaimForm}
      >
        <LeaderRejectClaimForm form={leaderRejectClaimForm} />
      </Modal>
    </>
  )
}

export default LeaderClaimApprovalContainer
