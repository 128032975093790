import { Form, FormInstance, Input } from 'antd'

export type ResetPasswordFormValues = {
  password: string
  username: string
}

type ResetPasswordFormProps = {
  form: FormInstance<ResetPasswordFormValues>
}

const ResetPasswordForm = ({ form }: ResetPasswordFormProps) => {
  return (
    <Form form={form}>
      <Form.Item label="Username" name="username">
        <Input disabled />
      </Form.Item>
      <Form.Item label="New Password" name="password">
        <Input.Password placeholder="Enter new password" />
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordForm
