import {
  CreateProjectPhasePackagePayload,
  DeleteProjectPhasePackageParam,
  GetAllProjectPhasePackageParam,
  GetAllProjectPhasePackageResp,
  UpdateProjectPhasePackagePayload,
  UpdateProjectPhasePackageResp,
} from '@m17/api-types'
import { fetchApi } from './fetch'

const PREFIX = '/project/phase'

export const getAllProjectPhasePackage = ({
  phase_id,
}: GetAllProjectPhasePackageParam): Promise<
  GetAllProjectPhasePackageResp[]
> => {
  return fetchApi(PREFIX + `/${phase_id}/package/all`, {
    method: 'GET',
  })
}

export const createProjectPhasePackage = (
  payload: CreateProjectPhasePackagePayload
) => {
  const { name, phase_id, price } = payload
  return fetchApi(PREFIX + '/package/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phase_id,
      name,
      price,
    }),
  })
}

export const updateProjectPhasePackage = (
  payload: UpdateProjectPhasePackagePayload
) => {
  const { id, name, price } = payload
  return fetchApi(PREFIX + '/package/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
      name,
      price,
    }),
  })
}

export const deleteProjectPhasePackage = (
  param: DeleteProjectPhasePackageParam
): Promise<UpdateProjectPhasePackageResp> => {
  const { id } = param
  return fetchApi(PREFIX + `/package/${id}`, {
    method: 'DELETE',
  })
}
