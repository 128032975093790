import React from 'react'
import { Button, Form, FormInstance, Select, Space } from 'antd'
import { SelectOption } from '@m17/api-types'

export type UserSearchFormValues = {
  leader_id: string
  user_type: string
}

type UserSearchFormProps = {
  leaderFilterOptions: SelectOption[]
  userTypeFilterOptions: SelectOption[]
  form: FormInstance<UserSearchFormValues>
  onSubmitUserSearch: (v: UserSearchFormValues) => void
  onResetUserSearch?: React.FormEventHandler<HTMLFormElement>
}

const UserSearchForm = ({
  leaderFilterOptions,
  userTypeFilterOptions,
  form,
  onSubmitUserSearch,
  onResetUserSearch,
}: UserSearchFormProps) => {
  return (
    <Form<UserSearchFormValues>
      form={form}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: 16,
      }}
      onFinish={onSubmitUserSearch}
      onReset={onResetUserSearch}
      layout="inline"
    >
      <Form.Item name="leader_id" label="Leader">
        <Select showSearch allowClear placeholder="Filter by leader">
          {leaderFilterOptions.map((op) => (
            <Select.Option key={`leader-option:${op.value}`} value={op.value}>
              {op.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="user_type" label="Type">
        <Select showSearch allowClear placeholder="Filter by type">
          {userTypeFilterOptions.map((op) => (
            <Select.Option
              key={`user-type-option:${op.value}`}
              value={op.value}
            >
              {op.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Space>
        <Button htmlType="reset">Clear</Button>
        <Button htmlType="submit">Search</Button>
      </Space>
    </Form>
  )
}

export default UserSearchForm
