import React from 'react'
import { Table, TableProps, Popconfirm } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { dateColumn, numberColumn, stringColumn } from './column'

export interface SimpleColumn<
  T extends Record<string, unknown>,
  K extends keyof T = keyof T,
> {
  title: K extends string ? K : never
  type: 'string' | 'number' | 'date'
}

export type Column<RecordType extends Record<string, unknown>> =
  | SimpleColumn<RecordType>
  | ColumnProps<RecordType>

export interface Action<T> {
  key: string
  onAction: (record: T, index: number) => void
  color?: string
  needConfirm?: boolean
}

export interface SimpleTableProps<K extends Record<string, unknown>>
  extends Omit<TableProps<K>, 'dataSource' | 'onChange' | 'columns'> {
  columns: Column<K>[]
  data: K[]
  needIndex?: boolean
  actions?: Action<K>[] | ((record: K, index: number) => Action<K>[])
}

function columnFactory<T extends Record<string, unknown>>(
  type: string,
  title: string
) {
  if (type === 'string') {
    return stringColumn<T>(title)
  }
  if (type === 'number') {
    return numberColumn<T>(title)
  }

  if (type === 'date') {
    return dateColumn<T>(title)
  }
  throw new Error(`Doesn't support the key ${type}`)
}

function isSimpleColumn(
  column: SimpleColumn<any> | ColumnProps<any>
): column is SimpleColumn<any> {
  return (column as SimpleColumn<any>).type !== undefined
}

interface ActionContainerProps<T> {
  actions: Action<T>[] | ((record: T, index: number) => Action<T>[])
  record: T
  index: number
}

function ActionContainer<T>({
  actions,
  record,
  index,
}: ActionContainerProps<T>) {
  let internalActions

  if (typeof actions === 'function') {
    internalActions = actions(record, index)
  } else {
    internalActions = actions
  }

  return (
    <div>
      {internalActions.map((action, actionIndex) => {
        const content = (
          <a
            onClick={() => action.onAction(record, index)}
            style={{
              color: action.color,
            }}
          >
            {action.key}
          </a>
        )
        return (
          <React.Fragment key={action.key}>
            {action.needConfirm ? (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => action.onAction(record, index)}
              >
                {content}
              </Popconfirm>
            ) : (
              content
            )}
            {actionIndex !== internalActions.length - 1 && (
              <span style={{ margin: '0 8px' }}>|</span>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export function SimpleTable<K extends Record<string, unknown>>({
  columns,
  data,
  needIndex = true,
  actions = [],
  loading,
  pagination = {
    position: ['bottomRight'],
    pageSize: 10,
  },
  ...props
}: SimpleTableProps<K>) {
  const _columns = React.useMemo(() => {
    const ActionButtons = (record: K, index: number) => {
      return <ActionContainer actions={actions} record={record} index={index} />
    }

    const dataColumn: ColumnProps<K>[] = columns.map((column) => {
      // go through the type
      if (isSimpleColumn(column)) {
        return columnFactory(column.type, column.title)
      }
      return column
    })

    if (actions.length) {
      // add action column
      dataColumn.push({
        title: 'Action',
        dataIndex: 'action',
        render: (_: unknown, record: any, index: number) => {
          return ActionButtons(record, index)
        },
        width: 150,
      })
    }

    // we need to check if the data is added properly
    if (needIndex && data.length > 0) {
      dataColumn.unshift({
        title: 'No',
        dataIndex: 'index',
        render: (_: unknown, __, index: number) => index + 1,
      })
    }
    return dataColumn
  }, [loading, columns])

  return (
    <Table<K>
      dataSource={data}
      columns={_columns}
      loading={loading}
      {...props}
    ></Table>
  )
}
