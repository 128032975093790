export enum WRITE_PERMISSION {
  CREATE_USER = 'write:create-user',
  DISABLE_USER = 'write:disable-user',
  ENABLE_USER = 'write:enable-user',
  MANAGE_CLAIMS = 'write:manage-claims',
  LEADER_CLAIM_APPROVAL = 'write:leader-claim-approval',
  MANAGE_DEVELOPER_CLAIMS = 'write:manage-developer-claims',
}

export enum READ_PERMISSION {
  VIEW_SALE = 'read:view-sale',
  VIEW_PROJECT = 'read:view-project',
  VIEW_USER = 'read:view-user',
  GENERATE_REPORT = 'read:generate-report',
  VIEW_CLAIM = 'read:view-claim',
  VIEW_DEVELOPER_CLAIM = 'read:view-developer-claim',
  VIEW_REPORT = 'read:view-report',
}
