import {
  CreateRebatePayload,
  DeleteRebatePayload,
  UpdateRebatePayload,
} from '@m17/api-types'
import { fetchApi } from './fetch'

const PREFIX = '/rebate'

export async function createRebate(body: CreateRebatePayload) {
  const result = await fetchApi(PREFIX + '/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  return result
}

export async function updateRebate(body: UpdateRebatePayload) {
  const result = await fetchApi(PREFIX + '/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  return result
}

export async function deleteRebate(body: DeleteRebatePayload) {
  const result = await fetchApi(PREFIX + '/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return result
}
