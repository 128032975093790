import { BASE_URL, fetchApi, fetchApiFile } from './fetch'

const PREFIX = '/pt'

export const uploadPtUrl = BASE_URL + '/pt/upload'

type BodyPTCreate = {
  pt_no: string
  project_id: number
  project_phase_id?: number
  spa_price: number
  spa_price_bumi: number
  building_type: string
  bumi_lot: string
}

export async function createPT(body: BodyPTCreate) {
  const result = await fetchApi(PREFIX + '/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
  return result
}

type BodyPTUpdate = {
  id: number
  pt_no: string
  project_id: number
  project_phase_id?: number
  spa_price: number
  spa_price_bumi: number
  building_type: string
  bumi_lot: string
}

export async function updatePT(body: BodyPTUpdate) {
  const result = await fetchApi(PREFIX + '/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return result
}

type BodyPTDelete = {
  id: number
}

export async function deletePT(body: BodyPTDelete) {
  const result = await fetchApi(PREFIX + '/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

  return result
}

export async function downloadImportPTFormat() {
  const result = await fetchApiFile(PREFIX + `/download/format`, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/csv',
    },
  })
  return result
}
