import React, { useState } from 'react'
import {
  Button,
  FormInstance,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from 'antd'
import ClaimSearchForm from './ClaimSearchForm'
import { CLAIM_STATUS, SelectOption } from '@m17/api-types'

type ClaimRecordType = {
  id: number
  no: number
  pt_no: string
  project_name: string
  sale_claim_status?: string
  claim?: {
    batch_name?: string
    status: number
  }
}

type ClaimPageProps = {
  claimData: Array<ClaimRecordType>
  selectedRowKeys: React.Key[]
  claimSearchForm: FormInstance
  searchFormClaimBatchOptions: SelectOption<number>[]
  searchFormProjectOptions: SelectOption<number>[]
  onSelectChange: (newSelectedRowKeys: React.Key[]) => void
  onSearchClick: () => void
  onClearClick: () => void
  onViewDetails: (record: ClaimRecordType) => void
}

const ClaimPage = ({
  claimData,
  selectedRowKeys,
  claimSearchForm,
  searchFormClaimBatchOptions,
  searchFormProjectOptions,
  onSelectChange,
  onSearchClick,
  onClearClick,
  onViewDetails,
}: ClaimPageProps) => {
  const [columns] = useState<TableColumnsType<ClaimRecordType>>([
    { key: 'no', title: 'No', dataIndex: 'no', width: '5%' },
    { key: 'pt_no', title: 'PT No.', dataIndex: 'pt_no' },
    { key: 'project_name', title: 'Project', dataIndex: 'project_name' },
    {
      key: 'claims',
      title: 'Claims',
      dataIndex: 'claims',
      render: (_, record) => (
        <Space direction="vertical">
          <Typography.Text strong>{record.sale_claim_status}</Typography.Text>
          {!record.claim?.batch_name && <Typography.Text>-</Typography.Text>}
          {record.claim && record.claim.batch_name && (
            <Typography.Text
              style={{
                color:
                  record.claim.status === CLAIM_STATUS.COMPLETED
                    ? 'green'
                    : undefined,
              }}
            >
              {record.claim.batch_name}
            </Typography.Text>
          )}
        </Space>
      ),
    },
    {
      key: 'details',
      title: 'Details',
      render: (_, record) => (
        <Button type="link" onClick={() => onViewDetails(record)}>
          View Details
        </Button>
      ),
    },
  ])
  return (
    <div>
      <div
        style={{
          background: 'white',
          padding: '16px',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <Typography.Title level={4}>Search</Typography.Title>
        <ClaimSearchForm
          form={claimSearchForm}
          claimBatchFilterOptions={searchFormClaimBatchOptions}
          projectFilterOptions={searchFormProjectOptions}
          onReset={onClearClick}
          onSubmit={onSearchClick}
        />
      </div>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        rowKey="claim_id"
        style={{ borderRadius: '8px' }}
        columns={columns}
        dataSource={claimData || []}
      />
    </div>
  )
}

export default ClaimPage
