import React, { FC, useState } from 'react'
import { Button, Space, Table, TableColumnsType } from 'antd'
import { PlusOutlined, EditFilled } from '@ant-design/icons'

export type ClaimBatchRecordType = {
  id: number
  name: string
}

type ManageClaimBatchPageProps = {
  allClaimBatches: ClaimBatchRecordType[]
  onClickAdd?: () => void
  onClickEdit?: (data: ClaimBatchRecordType) => void
}

const ManageClaimBatchPage: FC<ManageClaimBatchPageProps> = ({
  allClaimBatches,
  onClickAdd,
  onClickEdit,
}) => {
  const [columns] = useState<TableColumnsType<ClaimBatchRecordType>>([
    { key: 'id', title: 'ID', dataIndex: 'id' },
    { key: 'name', title: 'Name', dataIndex: 'name', width: '100%' },
    {
      key: 'action',
      title: 'Action',
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditFilled />}
            onClick={() => onClickEdit && onClickEdit(record)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button icon={<PlusOutlined />} onClick={onClickAdd}>
        Add
      </Button>
      <Table dataSource={allClaimBatches} columns={columns} />
    </Space>
  )
}

export default ManageClaimBatchPage
