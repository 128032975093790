import React, { useCallback, useEffect } from 'react'
import SalePage from '../views/SalePage'
import { Form, message, Modal, UploadFile } from 'antd'
import SaleForm from '../views/SaleForm'
import moment, { Moment } from 'moment'
import {
  fetchProject,
  fetchProjectPhases,
  fetchProjectPTs,
} from '../api/project'
import {
  createSale,
  deleteSale,
  downloadSales,
  fetchSales,
  updateSale,
  createFastCommClaim,
  getSearchFormAgentOptions,
  getSalePackageOptions,
  getSaleNetPrice,
} from '../api/sale'
import { useQuery, useMutation } from '@tanstack/react-query'
import Loading from './Loading'
import { getAllUsers } from '../api/user'
import { parseIdFromSelectValue, removeNull } from '../utils'
import FastCommClaimForm, {
  FastCommClaimFormValues,
} from '../views/FastCommClaimForm'
import useDisclosure from '../hooks/useDisclose'
import {
  AllSaleParams,
  AllSaleResponse,
  CLAIM_STATUS,
  GetNetPricePayload,
} from '@m17/api-types'

const initialRecord = {
  project_id: undefined,
  project_phase_id: undefined,
  phase: undefined,
  pt: undefined,
  purchaser_name: undefined,
  purchaser_contact: undefined,
  booking_date: moment(),
  purchaser_type: 0,
  financial_type: 0,
  sale_status: 0,
  claim_status: 0,
  sale_remark: '',
  claim_remark: '',
  commissions: [],
  banks: [],
}

const convertDate = (date: Moment[] | undefined) => {
  if (!date) {
    return date
  }
  return date.map((item) => item.unix())
}

function updateThumbUrl(uploadObject: {
  fileList?: UploadFile<any>[]
  file: UploadFile<any>
}) {
  if (uploadObject.fileList) {
    uploadObject.fileList = uploadObject.fileList.map((file) => {
      if (file.response?.success) {
        if (file.response.data.file.length === 0) {
          return {
            ...file,
            thumbUrl: undefined,
          }
        }
        const result = {
          ...file,
          thumbUrl: 'assets/' + file.response.data.file[0],
        }
        return result
      }
      return file
    })
  }
  return uploadObject
}

export default function SalePageContainer() {
  const [modal, setModal] = React.useState(false)
  const [form] = Form.useForm()
  const projectValue = Form.useWatch('project', form)
  const projectId = parseIdFromSelectValue(projectValue)
  const projectPhaseValue = Form.useWatch('project_phase_id', form)
  const projectPhaseId = parseIdFromSelectValue(projectPhaseValue)
  const ptValue = Form.useWatch('pt', form)
  const ptId = parseIdFromSelectValue(ptValue)
  const packageAddOn = Form.useWatch('package_add_on', form)
  const bookingDate = Form.useWatch('booking_date', form)
  const loDate = Form.useWatch('lo_date', form)
  const sopDate = Form.useWatch('sop_date', form)
  const spaDate = Form.useWatch('spa_date', form)
  const purchaserType = Form.useWatch('purchaser_type', form)
  const [saleSearchForm] = Form.useForm()
  const searchProjectValue = Form.useWatch('project_id', saleSearchForm)
  const searchProjectId = parseIdFromSelectValue(searchProjectValue)
  const searchProjectPhaseValue = Form.useWatch('phase', saleSearchForm)
  const searchProjectPhaseId = parseIdFromSelectValue(searchProjectPhaseValue)
  const [editMode, setEditMode] = React.useState(true)
  const [query, setQuery] = React.useState<AllSaleParams>({})
  const [id, setId] = React.useState<number | null>(null)

  const [fastCommClaimForm] = Form.useForm<FastCommClaimFormValues>()
  const fastCommClaimStatus = Form.useWatch('claim_status', fastCommClaimForm)
  const {
    isOpen: isOpenFastCommClaimForm,
    onOpen: onOpenFastCommClaimForm,
    onClose: onCloseFastCommClaimForm,
  } = useDisclosure()

  const { data: projects } = useQuery(['project'], fetchProject)
  const { data: users } = useQuery(['user'], getAllUsers)
  const {
    isLoading,
    data: sale,
    refetch,
  } = useQuery(['sale', query], () => fetchSales(query))
  const { data: projectPhases } = useQuery(
    ['project-phases', projectId],
    () => fetchProjectPhases(projectId || -1),
    {
      enabled: !!projectId,
    }
  )
  const { data: searchProjectPhases } = useQuery(
    ['search-project-phases', searchProjectId],
    () => fetchProjectPhases(searchProjectId || -1),
    {
      enabled: !!searchProjectId,
    }
  )
  const { data: ptData } = useQuery(
    ['project-pts', projectId, projectPhaseId],
    () => fetchProjectPTs(projectId || -1, projectPhaseId, 1),
    {
      enabled: !!projectId,
    }
  )
  const { data: allPtData } = useQuery(
    ['all-project-pts', projectId, projectPhaseId],
    () => fetchProjectPTs(projectId || -1, projectPhaseId, 0),
    {
      enabled: !!projectId,
    }
  )
  const { data: searchPTData } = useQuery(
    ['search-project-pts', searchProjectId, searchProjectPhaseId],
    () => fetchProjectPTs(searchProjectId || -1, searchProjectPhaseId),
    {
      enabled: !!searchProjectId,
    }
  )
  const { data: packageOptions } = useQuery(
    ['package-options', projectPhaseId],
    ({ queryKey }) => getSalePackageOptions(queryKey[1] as number),
    {
      enabled: !!projectPhaseId,
    }
  )
  const { data: agentOptions } = useQuery(
    ['search-agent-options'],
    getSearchFormAgentOptions
  )
  const { data: calculatedNetPrice } = useQuery(
    [
      'net-price',
      {
        package_add_on: packageAddOn,
        pt_id: ptId,
        purchaser_type: purchaserType,
        booking_date: bookingDate ? bookingDate.unix() : undefined,
        lo_date: loDate ? loDate.unix() : undefined,
        sop_date: sopDate ? sopDate.unix() : undefined,
        spa_date: spaDate ? spaDate.unix() : undefined,
      } as GetNetPricePayload,
    ],
    ({ queryKey }) =>
      getSaleNetPrice(queryKey[1] as unknown as GetNetPricePayload),
    {
      enabled: !!ptId,
    }
  )

  useEffect(() => {
    if (calculatedNetPrice) {
      form.setFieldValue('nett_price', calculatedNetPrice)
    }
  }, [calculatedNetPrice, form])

  const { mutateAsync: createFastCommClaimFn } = useMutation(
    ['create-fast-comm-claim'],
    createFastCommClaim
  )

  // Auto-fill SPA price based on PT
  // NOTE: PT spa_price is non bumi price. This might not be accurate for some case.
  useEffect(() => {
    if (ptId && ptData && !editMode) {
      const ptFound = ptData.find((p: any) => p.id === ptId)
      if (ptFound) {
        form.setFieldValue('spa_price', ptFound.spa_price)
      }
    }
  }, [ptId, ptData, form, editMode])

  const handleOk = async () => {
    // Validation
    const data = form.getFieldsValue()
    try {
      await form.validateFields()
    } catch (error) {
      return
    }

    if (
      form.getFieldsError().filter((item) => item.errors.length > 0).length > 0
    ) {
      message.error('Please make sure the field is filled up correctly')
      return
    }

    for (const fileList of data.receipt_paid_differential_sum?.fileList || []) {
      if (fileList.percent !== 100) {
        message.error('Make sure the file is uploaded correctly')
        return
      }
    }

    // preprocess the data
    data.project_id = Number(data.project.split('-')[0])
    data.project_phase_id = parseIdFromSelectValue(data.project_phase_id)
    data.booking_date = data.booking_date.unix()
    data.la_date = data.la_date && data.la_date.unix()
    data.lo_date = data.lo_date && data.lo_date.unix()
    data.spa_date = data.spa_date && data.spa_date.unix()
    data.project && delete data.project
    data.nett_price && delete data.nett_price
    data.pt_id = parseIdFromSelectValue(data.pt)
    data.sop_date = data.sop_date && data.sop_date.unix()
    data.real_nett_price = Number(data.real_nett_price)

    // process the images
    if (data.receipt_paid_differential_sum) {
      delete data.receipt_paid_differential_sum.file
      updateThumbUrl(data.receipt_paid_differential_sum)
    }

    // additional info
    data.other_info = JSON.stringify({
      checklist_date: data.checklist_date,
      differential_sum_amount: data.differential_sum_amount,
      receipt_paid_differential_sum: data.receipt_paid_differential_sum,
      banks: data.banks,
      real_nett_price: data.real_nett_price,
    })
    data.commissions = (data.commissions || [])
      .filter(Boolean)
      .map((el: { rate: any }) => ({
        ...el,
        rate: el.rate && Number(el.rate),
      }))

    delete data.pt
    const processData = removeNull(data)

    try {
      // edit
      if (editMode && id) {
        await updateSale(id, processData)
        message.success('Sale updated successfully')
      } else {
        await createSale(processData)
      }
      refetch()
      setModal(false)
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message)
      } else {
        message.error('Something went wrong. Please try again.')
      }
    }
  }

  const handleCancel = () => {
    setModal(false)
  }

  const onCreateClick = () => {
    form.resetFields()
    form.setFieldsValue(initialRecord)
    setEditMode(false)
    setModal(true)
  }

  React.useEffect(() => {
    // Modal close,
    // reset form
    if (!modal) {
      setEditMode(false)
    }
  }, [modal])

  const editButtonOnClick = (record: AllSaleResponse) => {
    const pt = record.pt
    const formValues = {
      ...record,
      booking_date: moment(record.booking_date, 'X'),
      spa_date: record.spa_date && moment(record.spa_date, 'X'),
      la_date: record.la_date && moment(record.la_date, 'X'),
      lo_date: record.lo_date && moment(record.lo_date, 'X'),
      commissions: record.commission.map((comm) => ({
        rate: comm.rate,
        username: comm.user.username,
      })),
      banks: [],
      pt,
      ...(record.other_info
        ? JSON.parse(record.other_info, (_, value) => {
            // date change to moment
            // regex for this date
            if (
              typeof value === 'string' &&
              value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
            ) {
              return moment(value)
            }
            return value
          })
        : {}),
      sop_date: record.sop_date && moment(record.sop_date, 'X'),
    }
    if (formValues.receipt_paid_differential_sum) {
      updateThumbUrl(formValues.receipt_paid_differential_sum)
    }
    form.resetFields()
    form.setFieldsValue(formValues)
    setId(record.id)
    setEditMode(true)
    setModal(true)
  }

  const fastCommButtonClick = useCallback(
    (record: any) => {
      fastCommClaimForm.resetFields()
      fastCommClaimForm.setFieldsValue({
        sale_id: record.id,
        claim_status: record.claim?.status,
        pt_no: record.pt_no,
      })
      onOpenFastCommClaimForm()
    },
    [fastCommClaimForm, onOpenFastCommClaimForm]
  )

  const onSubmitFastCommClaimForm = useCallback(async () => {
    try {
      const formValues = fastCommClaimForm.getFieldsValue()
      await createFastCommClaimFn({
        sale_id: formValues.sale_id,
      })
      message.success('Submit claim successfully.')
      await refetch()
      onCloseFastCommClaimForm()
    } catch (error) {
      message.error('Something went wrong. Please try again.ƒ')
    }
  }, [
    fastCommClaimForm,
    createFastCommClaimFn,
    refetch,
    onCloseFastCommClaimForm,
  ])

  if (isLoading) {
    return <Loading></Loading>
  }

  return (
    <div>
      <SalePage
        projectOptions={projects || []}
        projectPhases={searchProjectPhases || []}
        projectPts={searchPTData}
        agentOptions={agentOptions || []}
        onSearchClick={() => {
          const values = saleSearchForm.getFieldsValue()
          setQuery({
            ...values,
            project_id: values.project_id?.split('-')[0] || undefined,
            phase: parseIdFromSelectValue(values.phase),
            pt_id: parseIdFromSelectValue(values.pt),
            booking_date: convertDate(values.booking_date),
            spa_date: values.spa_date && convertDate(values.spa_date),
            la_date: values.la_date && convertDate(values.la_date),
            lo_date: values.lo_date && convertDate(values.lo_date),
          })
        }}
        loading={isLoading}
        onClearClick={() => {
          saleSearchForm.resetFields()
        }}
        onExportClick={async () => {
          // download the csv
          message.info('Exporting...')
          await downloadSales(query)
        }}
        saleSearchForm={saleSearchForm}
        onCreateClick={onCreateClick}
        dataSource={(sale || []).map((item: any) => ({
          ...item,
          'project.name': item.project.name,
          key: item.id,
        }))}
        onConfirm={async (record) => {
          await deleteSale(record.id)
          message.success('Sale deleted successfully')
          refetch()
        }}
        editButtonOnClick={editButtonOnClick}
        onFastCommButtonClick={fastCommButtonClick}
      ></SalePage>
      <Modal
        title="Sale"
        open={modal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={720}
      >
        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
          <SaleForm
            projectOptions={projects || []}
            mode={editMode ? 'edit' : 'create'}
            form={form}
            users={users || []}
            projectPhases={projectPhases || []}
            phasePackagesOptions={packageOptions || []}
            projectPTs={editMode ? allPtData : ptData}
          ></SaleForm>
        </div>
      </Modal>
      <Modal
        okText="Submit"
        title="Fast Comm"
        open={isOpenFastCommClaimForm}
        onCancel={onCloseFastCommClaimForm}
        onOk={onSubmitFastCommClaimForm}
        okButtonProps={{
          disabled:
            fastCommClaimStatus !== undefined &&
            ![CLAIM_STATUS.LEADER_REJECTED, CLAIM_STATUS.REJECTED].includes(
              fastCommClaimStatus!
            ),
        }}
        destroyOnClose
      >
        <FastCommClaimForm form={fastCommClaimForm} />
      </Modal>
    </div>
  )
}
