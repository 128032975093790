import './App.css'
import UserPage from './components/UserPage'
import { Outlet, Route, Routes } from 'react-router-dom'
import SalePage from './components/SalePage'
import Login from './components/Login'
import Logout from './components/Logout'
import ProjectPage from './components/ProjectPage'
import AuthRoute from './components/AuthRoute'
import LayoutUI from './components/LayoutUI'
import ReportPageContainer from './components/ReportPage'
import ManageClaimBatchPageContainer from './components/ManageClaimBatchPage'
import ClaimPageContainer from './components/ClaimPage'
import ClaimTabLayout from './components/ClaimTabLayout'
import LeaderClaimApproval from './components/LeaderClaimApproval'
import NotFoundPage from './components/NotFoundPage'
import DeveloperClaimTabLayout from './components/DeveloperClaimTabLayout'
import ConvertedSalesPageContainer from './components/ConvertedSalesPage'
import DeveloperClaimBatchPageContainer from './components/DeveloperClaimBatchPage'
import DeveloperClaimsPageContainer from './components/DeveloperClaimsPage'
import UserChartPageContainer from './components/UserChartPage'
import ErrorPage from './views/ErrorPage'

function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route element={<AuthRoute />}>
          <Route
            element={
              <LayoutUI>
                <Outlet />
              </LayoutUI>
            }
          >
            <Route path="/user" element={<UserPage />}></Route>
            <Route path="/" element={<SalePage />}></Route>
            <Route path="/project" element={<ProjectPage />}></Route>
            <Route path="/report" element={<ReportPageContainer />}></Route>
            <Route
              path="/org-chart"
              element={<UserChartPageContainer />}
            ></Route>
            <Route element={<ClaimTabLayout />}>
              <Route
                path="/claim-batches"
                element={<ManageClaimBatchPageContainer />}
              />
              <Route path="/claim" element={<ClaimPageContainer />} />
              <Route
                path="/claim-leader-approval"
                element={<LeaderClaimApproval />}
              />
            </Route>
            <Route element={<DeveloperClaimTabLayout />}>
              <Route
                path="/converted-sales"
                element={<ConvertedSalesPageContainer />}
              />
              <Route
                path="/developer-claims"
                element={<DeveloperClaimsPageContainer />}
              />
              <Route
                path="/developer-claim-batches"
                element={<DeveloperClaimBatchPageContainer />}
              />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default App
