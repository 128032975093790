import React, { FC } from 'react'
import { Form, FormInstance, Input } from 'antd'

export type ClaimBatchFormValues = {
  id?: number
  name: string
}

type ClaimBatchFormProps = {
  form?: FormInstance<ClaimBatchFormValues>
}

const ClaimBatchForm: FC<ClaimBatchFormProps> = ({ form }) => {
  return (
    <Form layout="horizontal" form={form} id="claim-batch-form">
      <Form.Item hidden name="id" />
      <Form.Item
        name="name"
        label="Batch Name"
        required
        rules={[{ required: true, message: 'Please enter a name' }]}
      >
        <Input placeholder="Enter batch name" />
      </Form.Item>
    </Form>
  )
}

export default ClaimBatchForm
