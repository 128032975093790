import {
  Form,
  Input,
  Typography,
  Divider,
  DatePicker,
  Row,
  Space,
  Checkbox,
  FormInstance,
} from 'antd'
import { BRebateMethod } from '../types/rebate'
import { useEffect, useState } from 'react'
import { Moment } from 'moment'

const PercentInputAddon = () => <Typography>%</Typography>
const RMInputAddon = () => <Typography>RM</Typography>

export type RebateFormValues = {
  effective_date: Moment
  a_rebate_pct_bumi?: number
  a_rebate_pct_non_bumi?: number
  b_rebate_amt_bumi?: number
  b_rebate_amt_non_bumi?: number
  b_rebate_method?: number
  c_rebate_pct_all?: number
  c_rebate_pct_all_2?: number
  c_sop_date_pct_all?: number
  c_sop_date_amt_all?: number
  c_rebate_amt_all?: number
  d_rebate_pct_existing_buyer?: number
  d_introducer_fee_amt?: number
}

type RebateFormProps = {
  form: FormInstance<RebateFormValues>
  initialValues?: any
}

const RebateForm = ({ form, initialValues }: RebateFormProps) => {
  const [bRebateMethod, setBRebateMethod] = useState<number>(
    initialValues?.b_rebate_method || BRebateMethod.None
  )

  // To preselect Part B rebate method checkbox
  useEffect(() => {
    const rebateMethod = form.getFieldValue('b_rebate_method')
    setBRebateMethod(rebateMethod)
  }, [form])

  return (
    <Form
      form={form}
      initialValues={initialValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
    >
      <Form.Item
        required
        name="effective_date"
        label="Effective Date"
        rules={[
          {
            required: true,
            message: 'Effective date is required',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Divider orientation="left">Part A</Divider>
      <Row>
        <Space size="large">
          <Form.Item name="a_rebate_pct_bumi" label="Bumi">
            <Input
              autoComplete="off"
              addonAfter={<PercentInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item name="a_rebate_pct_non_bumi" label="Non Bumi">
            <Input
              autoComplete="off"
              addonAfter={<PercentInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
        </Space>
      </Row>

      <Divider orientation="left">Part B</Divider>
      <Row>
        <Space size="large">
          <Form.Item name="b_rebate_amt_bumi" label="Rebate Amount (Bumi)">
            <Input
              autoComplete="off"
              addonBefore={<RMInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item
            name="b_rebate_amt_non_bumi"
            label="Rebate Amount (Non Bumi)"
          >
            <Input
              autoComplete="off"
              addonBefore={<RMInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
        </Space>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Form.Item name="b_rebate_method" valuePropName="checked">
          <Checkbox
            value={BRebateMethod.RebateBeforeA}
            name="b_rebate_method"
            onChange={(e) => {
              const value = e.target.value
              if (value === bRebateMethod) {
                setBRebateMethod(BRebateMethod.None)
                form.setFieldValue('b_rebate_method', BRebateMethod.None)
              } else {
                setBRebateMethod(e.target.value)
                form.setFieldValue('b_rebate_method', e.target.value)
              }
            }}
            checked={bRebateMethod === BRebateMethod.RebateBeforeA}
          >
            Rebate before Part A
          </Checkbox>
          <Checkbox
            value={BRebateMethod.RebateAfterC}
            name="b_rebate_method"
            onChange={(e) => {
              const value = e.target.value
              if (value === bRebateMethod) {
                setBRebateMethod(BRebateMethod.None)
                form.setFieldValue('b_rebate_method', BRebateMethod.None)
              } else {
                setBRebateMethod(e.target.value)
                form.setFieldValue('b_rebate_method', e.target.value)
              }
            }}
            checked={bRebateMethod === BRebateMethod.RebateAfterC}
          >
            Rebate after Part C
          </Checkbox>
        </Form.Item>
      </Row>

      <Divider orientation="left">Part C (All or Non Bumi)</Divider>
      <Row>
        <Space size="large">
          <Form.Item name="c_rebate_pct_all" label="Rebate 1 (%)">
            <Input
              autoComplete="off"
              addonAfter={<PercentInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item name="c_rebate_pct_all_2" label="Rebate 2 (%)">
            <Input
              autoComplete="off"
              addonAfter={<PercentInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item name="c_sop_date_pct_all" label="SOP Date (%)">
            <Input
              autoComplete="off"
              addonAfter={<PercentInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item name="c_sop_date_amt_all" label="SOP Date (RM)">
            <Input
              autoComplete="off"
              addonBefore={<RMInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item name="c_rebate_amt_all" label="Rebate Amount">
            <Input
              autoComplete="off"
              addonBefore={<RMInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
        </Space>
      </Row>

      <Divider orientation="left">Part D</Divider>
      <Row>
        <Space size="large">
          <Form.Item
            name="d_rebate_pct_existing_buyer"
            label="Existing Buyer Rebate"
          >
            <Input
              autoComplete="off"
              addonAfter={<PercentInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
          <Form.Item name="d_introducer_fee_amt" label="Introducer Fee">
            <Input
              autoComplete="off"
              addonBefore={<RMInputAddon />}
              type="number"
            ></Input>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  )
}

export default RebateForm
