export type Rebate = {
  project_phase_name: string
  effective_date: number
  a_rebate_pct_bumi: number
  a_rebate_pct_non_bumi: number
  b_rebate_amt_bumi: number
  b_rebate_amt_non_bumi: number
  b_rebate_method: number
  c_rebate_pct_all: number
  c_rebate_amt_all: number
  c_30_day_sign_lo_pct_all: number
  d_rebate_amt_existing_buyer: number
  d_introducer_fee: number
}

export enum BRebateMethod {
  None = 0,
  RebateBeforeA = 1,
  RebateAfterC = 2,
}
