import { numberColumn } from '@m17/table'
import type { ColumnProps } from 'antd/lib/table'

export function currencyColumn<T, K extends Extract<keyof T, string> = any>(
  key: K
) {
  return {
    ...numberColumn(key),
    render: (text: number) => {
      return toRinggit(text)
    },
    dataIndex: key,
  } as ColumnProps<T>
}

export function createSelectValue(id: string | number, name: string) {
  return `${id}-${name}`
}

export function parseIdFromSelectValue(value: string) {
  if (value) {
    return Number(value.split('-')[0])
  }
  return null
}

export function removeNull(obj: any) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      acc[key] = obj[key]
    }
    return acc
  }, {} as any)
}

export function toRinggit(amount: number) {
  if (isNaN(amount)) {
    return 'NaN'
  }
  return amount.toLocaleString('en-MY', {
    style: 'currency',
    currency: 'MYR',
  })
}

type DecodeBinaryParam = {
  length?: number
  value?: number
}

export function decodeBinary(obj: DecodeBinaryParam) {
  const { length = 0, value = 0 } = obj
  let arr = []
  for (let i = 0; i < length; i++) {
    if (value && value & (1 << i)) {
      arr.push(i)
    }
  }
  return arr
}
