export const BASE_URL = '/api/v1'

export async function fetchApi<T>(url: string, options?: RequestInit) {
  const response = await fetch(BASE_URL + url, options)
  if (response.status !== 200) {
    throw response
  }
  const data = await response.json()
  return data.data as T
}

export const fetchApiFile = async (url: string, options?: RequestInit) => {
  const response = await fetch(BASE_URL + url, options)
  const data = await response.blob()

  return data
}
