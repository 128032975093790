import {
  GetUserFormLeadersParams,
  AddUserPayload,
  UpdateUserPayload,
  SelectOption,
} from '@m17/api-types'
import { User } from '../types/user'
import { BASE_URL, fetchApi } from './fetch'
import { removeNull } from '../utils'
import {
  UserDetailData,
  LoginPayload,
  UserHierarchyChartData,
} from '@m17/backend'

type LoginParams = {
  username: string
  password: string
}

export function login({
  username,
  password,
}: LoginParams): Promise<LoginPayload> {
  return fetchApi('/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      username,
      password,
    }),
  })
}

export function fetchUserDetail(): Promise<UserDetailData> {
  return fetchApi('/user/detail')
}

type ResetPasswordParam = {
  username: string
  password: string
}

export function resetPassword({ username, password }: ResetPasswordParam) {
  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    username: username,
    password: password,
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  }

  return fetchApi('/user/reset-password', requestOptions)
}

export function getAllUsers(): Promise<User[]> {
  return fetchApi('/user/all')
}

export function addUser(payload: AddUserPayload) {
  return fetchApi('/user/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({ ...payload }),
  })
}

export const uploadUserUrl = BASE_URL + '/user/upload'

export function updateUser(payload: UpdateUserPayload): Promise<any> {
  return fetchApi('/user/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({ ...payload }),
  })
}

export function getUserFormLeaders(
  params: GetUserFormLeadersParams
): Promise<User[]> {
  return fetchApi(
    '/user/form/leaders?' + new URLSearchParams(removeNull(params))
  )
}

export function getUserTypeOptions(): Promise<Array<SelectOption>> {
  return fetchApi('/user/form/user-types')
}

export function getLeaderOptions(): Promise<SelectOption[]> {
  return fetchApi('/user/form/filter-options/leader')
}

export function getSubordinates({
  queryKey,
}: {
  queryKey: any
}): Promise<User[]> {
  const [, data] = queryKey
  return fetchApi('/user/subordinates?' + new URLSearchParams(removeNull(data)))
}

export function fetchUserChart(): Promise<UserHierarchyChartData> {
  return fetchApi('/user/user-hierarchy-chart')
}
