export function checkPremissions(
  userPermissions: string[],
  allowedPermissions: string[]
) {
  return (
    allowedPermissions.length > 0 &&
    allowedPermissions.some((permission) =>
      userPermissions.includes(permission)
    )
  )
}
