import { login } from '../api/user'
import Login from '../views/Login'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

export default function LoginContainer() {
  const navigate = useNavigate()
  const client = useQueryClient()

  useEffect(() => {
    client.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Login
      onFinish={(value) => {
        login({
          username: value.username,
          password: value.password,
        })
          .then((res) => {
            window.localStorage.setItem('token', res.sessionId)
            // Get URL redirect param
            const redirect = new URLSearchParams(window.location.search).get(
              'redirect'
            )
            navigate(redirect || '/')
          })
          .catch((err) => {
            message.error(err.message)
          })
      }}
    ></Login>
  )
}
