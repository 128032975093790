import { ColumnProps } from 'antd/lib/table'

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function baseColumn<T, K extends Extract<keyof T, string> = any>(
  key: K
): ColumnProps<T> {
  return {
    key: String(key),
    title: capitalize(
      String(key)
        // handling for camel case
        .replace(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g, ' ')
        .trim()
    ),
    dataIndex: key,
  }
}

export function stringColumn<T, K extends Extract<keyof T, string> = any>(
  key: K
): ColumnProps<T> {
  return {
    ...baseColumn(key),
    sorter: (a: T, b: T) => {
      const aStr = String(a[key])
      const bStr = String(b[key])
      return aStr.localeCompare(bStr)
    },
  }
}

export function numberColumn<T, K extends Extract<keyof T, string> = any>(
  key: K
): ColumnProps<T> {
  return {
    ...baseColumn(key),
    sorter: (a: T, b: T) => {
      const aNum = Number(a[key])
      const bNum = Number(b[key])
      return aNum - bNum
    },
  }
}

export function dateColumn<T, K extends Extract<keyof T, string> = any>(
  key: K
): ColumnProps<T> {
  return {
    ...baseColumn(key),
    render: (value: Date | undefined) => {
      if (value instanceof Date) {
        return value.toDateString()
      }
      return value
    },
  }
}
