import {
  AllSaleParams,
  AllSaleResponse,
  CreateFastCommClaimPayload,
  CreateSalePayload,
  GetNetPricePayload,
  GetSaleFormPackageOptionRespData,
  GetSaleSearchAgentOptionRespData,
  UpdateSalePayload,
} from '@m17/api-types'
import { fetchApi } from './fetch'

const PREFIX = '/sale'

function createFetchSaleUrl(data: AllSaleParams): string {
  const filtered = Object.keys(data).reduce((acc, key) => {
    if (data[key as keyof AllSaleParams] !== undefined) {
      const result = `${key}=${data[key as keyof AllSaleParams]}`
      acc.push(result)
    }
    return acc
  }, [] as string[])

  const paramsString = filtered.join('&')
  const url = `${PREFIX}/all?${paramsString}`
  return url
}

export async function fetchSales(
  data: AllSaleParams
): Promise<AllSaleResponse[]> {
  // filter out undefined values
  const url = createFetchSaleUrl(data)
  const result: AllSaleResponse[] = await fetchApi(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return result
}

export async function createSale(body: CreateSalePayload) {
  await fetchApi(PREFIX + '/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export async function updateSale(id: number, body: UpdateSalePayload) {
  await fetchApi(PREFIX + '/' + id, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export async function deleteSale(id: number) {
  await fetchApi(PREFIX + '/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export async function downloadSales(data: AllSaleParams) {
  // create params from object
  const url = createFetchSaleUrl(data)
  let filename = 'sales.csv'
  const csv = await fetch('/api/v1' + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      download: 'true',
    },
  }).then((response) => {
    // get filename from response header
    filename =
      response.headers.get('Content-Disposition')?.split('filename=')[1] ||
      filename
    return response.blob()
  })
  const urlObject = window.URL || window.webkitURL || window
  const exportUrl = urlObject.createObjectURL(csv)
  const a = document.createElement('a')
  a.href = exportUrl
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export async function createFastCommClaim(
  payload: CreateFastCommClaimPayload
): Promise<boolean> {
  const resp: boolean = await fetchApi(PREFIX + '/fast-comm-claim/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  return resp
}

export async function getSearchFormAgentOptions(): Promise<GetSaleSearchAgentOptionRespData> {
  const resp: GetSaleSearchAgentOptionRespData = await fetchApi(
    PREFIX + '/form/search/agent-options',
    {
      method: 'GET',
    }
  )
  return resp
}

export async function getSalePackageOptions(
  phaseId: number
): Promise<GetSaleFormPackageOptionRespData[]> {
  const resp: GetSaleFormPackageOptionRespData[] = await fetchApi(
    PREFIX + `/${phaseId}/form/package-options`,
    {
      method: 'GET',
    }
  )
  return resp
}

export async function getSaleNetPrice(
  payload: GetNetPricePayload
): Promise<number> {
  return await fetchApi<number>(PREFIX + '/net-price', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function getNetPrice(
  payload: GetNetPricePayload
): Promise<number> {
  const resp = await fetchApi<number>(PREFIX + '/net-price', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  return resp
}
