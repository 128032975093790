import { SelectOption } from '.'

export enum CLAIM_TYPE {
  FIRST_CLAIM,
  FINAL_CLAIM,
}

export const ClaimType = {
  [CLAIM_TYPE.FIRST_CLAIM]: 'First Claim',
  [CLAIM_TYPE.FINAL_CLAIM]: 'Final Claim',
}

export enum CLAIM_STATUS {
  PENDING,
  LEADER_REJECTED,
  LEADER_APPROVED,
  REJECTED,
  COMPLETED,
}

export const ClaimStatus = {
  [CLAIM_STATUS.PENDING]: 'Pending',
  [CLAIM_STATUS.LEADER_REJECTED]: 'Leader Rejected',
  [CLAIM_STATUS.LEADER_APPROVED]: 'Leader Approved',
  [CLAIM_STATUS.REJECTED]: 'Rejected',
  [CLAIM_STATUS.COMPLETED]: 'COMPLETED',
}

export type GetAllClaimsRespData = Array<{
  id: number
  no: number
  pt_no: string
  project_name: string
  sale_claim_status?: string
  claim_id?: number
  claim?: {
    id: number
    status: number
    batch_name?: string
  }
}>

export type GetSetClaimBatchOptionsRespData = Array<SelectOption<number>>

export type SetClaimBatchPayload = {
  claim_batch_id: number
  claim_ids: number[]
}

export type ApproveClaimsPayload = {
  claim_ids: number[]
}

export type RejectClaimPayload = {
  claim_id: number
  claim_remark?: string
}

export type RejectClaimsPayload = {
  claim_ids: number[]
  claim_remark?: string
}

export type GenerateClaimReportPayload = {
  claim_batch_id: number
}

export type GetAllClaimsPayload = {
  booking_date?: string
  claim_batch_id?: string
  project_id?: string
  claim_status?: string
}

export type GetAllClaimsForLeaderApprovalRespData = Array<{
  id: number
  no: number
  pt_no: string
  project_name: string
  claim?: {
    id: number
    batch_name?: string
    status: number
  }
}>

export type LeaderRejectClaimPayload = {
  claim_id: number
  claim_remark: string
}

export type LeaderApproveClaimPayload = {
  claim_id: number
}
