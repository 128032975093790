import { useCallback, useState } from 'react'

type UseDisclosureParam = {
  defaultValue?: boolean
}

export default function useDisclosure({
  defaultValue,
}: UseDisclosureParam = {}) {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue || false)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
