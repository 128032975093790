import React from 'react'
import G6, { GraphData } from '@antv/g6'
import { Select } from 'antd'

export interface UserChartPageProps {
  data?: GraphData
}

function unionFind(data: GraphData) {
  const nodeMap = new Map()
  if (!data.nodes) {
    data.nodes = []
  }

  if (!data.edges) {
    data.edges = []
  }

  const result: GraphData = {}

  result.nodes = data.nodes.map((node) => {
    const newNode = {
      ...node,
      parent: node.id,
    }
    nodeMap.set(node.id, newNode)
    return newNode
  })

  result.edges = data.edges.map((edge) => {
    return {
      ...edge,
    }
  })

  // given a id, find its root id
  const find = (nodeID: string): string => {
    const node = nodeMap.get(nodeID)
    if (!node) {
      return nodeID
    }
    if (node.parent === node.id) {
      return node.id
    }
    node.parent = find(node.parent)
    return node.parent
  }

  // given two nodes, union them
  const union = (node1: string, node2: string) => {
    const root1 = find(node1)
    const root2 = find(node2)
    if (root1 === root2) return
    const root1Node = nodeMap.get(root1)
    const root2Node = nodeMap.get(root2)
    root2Node.parent = root1Node.parent
  }

  // union nodes
  result.edges.forEach((edge) => {
    if (edge.source && edge.target) {
      union(edge.source, edge.target)
    }
  })

  return find
}

// Filter `option.label` match the user type `input`
const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export function UserChartPage({ data }: UserChartPageProps) {
  const [value, setValue] = React.useState('')
  const graph = React.useRef<any>(null)
  React.useEffect(() => {
    if (!graph.current) {
      graph.current = new G6.Graph({
        container: document.getElementById('mountNode') as any,
        height: Math.floor(window.innerHeight * 0.8),
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'drag-node'], // 允许拖拽画布、放缩画布、拖拽节点
        },
        defaultNode: {
          type: 'rect',
        },
        layout: {
          type: 'dagre',
        },
      })
    }
    if (!data) return
    const find = unionFind(data)
    const newData = {
      ...data,
    }
    newData.nodes = (newData.nodes || []).filter((d) => {
      if (!value) return true
      return find(d.id) === find(value)
    })
    newData.edges = (newData.edges || []).filter((d) => {
      if (!value) return true
      const root = find(value)
      if (d.source && d.target) {
        return find(d.source) === root && find(d.target) === root
      }
      return false
    })
    graph.current.data(newData)
    graph.current.render()
  }, [data, value])

  const onChange = (value: string) => {
    setValue(value)
  }

  return (
    <div>
      <Select
        showSearch
        filterOption={filterOption}
        onChange={onChange}
        options={(data?.nodes || []).map((el) => ({
          value: el.id,
          label: typeof el.label === 'string' ? el.label : '',
        }))}
        style={{ width: 360 }}
      ></Select>
      <div id="mountNode"></div>
    </div>
  )
}
