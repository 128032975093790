import { useMemo } from 'react'
import { Form, Select, DatePicker, Button, AutoComplete } from 'antd'
import {
  SALE_STATUS,
  SaleStatus,
  ClaimStatus,
  CLAIM_STATUS,
  AllSaleResponse,
  SelectOption,
} from '@m17/api-types'
import {
  FinancialType,
  FINANCIAL_TYPE,
  PurchaserType,
  PURCHASER_TYPE,
} from '../constants/sale'
import { createSelectValue } from '../utils'
import moment from 'moment'
import { ProjectPhase } from '../types/projectPhase'
import { Column, SimpleTable, numberColumn, stringColumn } from '@m17/table'
import { ColumnProps } from 'antd/lib/table'
import { currencyColumn } from '../utils'
import { Container } from './StyledComponent'

const { RangePicker } = DatePicker

const switchSaleStatusColor = (status: SALE_STATUS) => {
  switch (status) {
    case SALE_STATUS.LOAN_APPROVED:
      return '#73d13d'
    case SALE_STATUS.CONVERTED:
      return '#0036ff'
    case SALE_STATUS.CANCELLED:
      return '#ff4d4f'
    default:
      return '#000'
  }
}

const switchSaleStatusFontWeight = (status: SALE_STATUS) => {
  switch (status) {
    case SALE_STATUS.CONVERTED:
      return 'bold'
    default:
      return undefined
  }
}

type SalePageProps = {
  dataSource: AllSaleResponse[]
  editButtonOnClick: (record: AllSaleResponse) => void
  saleSearchForm: any
  projectOptions: any
  projectPhases?: ProjectPhase[]
  projectPts?: any
  agentOptions: Array<SelectOption<string>>
  loading: boolean
  onConfirm: (record: AllSaleResponse) => void
  onCreateClick: () => void
  onSearchClick: () => void
  onExportClick: () => void
  onClearClick: () => void
  onFastCommButtonClick: (record: AllSaleResponse) => void
}

export default function SalePage({
  dataSource,
  saleSearchForm,
  projectOptions = [],
  projectPhases = [],
  projectPts = [],
  agentOptions,
  loading,
  editButtonOnClick,
  onCreateClick,
  onSearchClick,
  onExportClick,
  onClearClick,
  onFastCommButtonClick,
}: SalePageProps) {
  const columns: Column<AllSaleResponse>[] = [
    {
      ...stringColumn('project_name'),
      dataIndex: 'project_name',
      title: 'Project Name',
    },
    {
      ...stringColumn('pt_no'),
      title: 'PT',
    },
    {
      ...dateColumn('booking_date'),
      dataIndex: 'booking_date',
      title: 'Booking Date',
    },
    {
      ...stringColumn('purchaser_name'),
      title: 'Purchaser Name',
    },
    {
      ...currencyColumn('nett_price'),
      title: 'Nett Price',
    },
    {
      title: 'Sale Status',
      dataIndex: 'sale_status',
      key: 'saleStatus',
      render: (text: SALE_STATUS) => (
        <div
          style={{
            color: switchSaleStatusColor(text),
            fontWeight: switchSaleStatusFontWeight(text),
          }}
        >
          {SaleStatus[text]}
        </div>
      ),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (commision: any[]) => {
        return (
          <div>
            {commision.map((el) => (
              <div style={{ marginBottom: 10 }}>
                <div>Rate: {el.rate}</div>
                <div
                  style={{
                    textOverflow: 'ellipsis ellipsis',
                  }}
                >
                  Name: {el.user.name}
                </div>
              </div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Claim Status',
      dataIndex: ['claim', 'status'],
      key: 'claimStatus',
      render: (text: CLAIM_STATUS) => (
        <span style={{ whiteSpace: 'nowrap' }}>{ClaimStatus[text] || '-'}</span>
      ),
    },
    {
      title: 'Claim Remark',
      dataIndex: ['claim', 'remark'],
      key: 'claimRemark',
      render: (remark: string) => (
        <span style={{ whiteSpace: 'pre-line' }}>{remark || '-'}</span>
      ),
    },
  ]

  const options = useMemo(() => {
    const keywords = ['PT', '"Purchaser Name"']
    return keywords.map((keyword) => ({
      value: keyword,
    }))
  }, [])

  return (
    <Container>
      <Form
        style={{
          backgroundColor: 'white',
          padding: 12,
          display: 'grid',
          grid: 'auto / auto auto',
          gap: 6,
        }}
        form={saleSearchForm}
      >
        <Form.Item name="project_id" label="Project">
          <Select showSearch={true}>
            {projectOptions.map((project: any) => (
              <Select.Option
                key={`project-option:${project.id}`}
                value={createSelectValue(project.id, project.name)}
              >
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="phase" label="Phase">
          <Select showSearch={true} allowClear>
            {projectPhases.map((phase: any) => (
              <Select.Option
                key={`phase-option:${phase.id}`}
                value={createSelectValue(phase.id, phase.name)}
              >
                {phase.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="pt" label="PT No">
          <Select showSearch={true} allowClear>
            {projectPts.map((pt: any) => (
              <Select.Option
                key={`pt-option:${pt.id}`}
                value={createSelectValue(pt.id, pt.pt_no)}
              >
                {pt.pt_no}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="booking_date" label="Booking Date">
          <RangePicker></RangePicker>
        </Form.Item>
        <Form.Item name="spa_date" label="Date of Signing SPA">
          <RangePicker></RangePicker>
        </Form.Item>
        <Form.Item name="lo_date" label="Date Acceptance LO">
          <RangePicker></RangePicker>
        </Form.Item>
        <Form.Item name="la_date" label="Date Signing LA">
          <RangePicker></RangePicker>
        </Form.Item>
        <Form.Item name={'sale_status'} label="Sale Status">
          <Select>
            {Array.from(Object.entries(SaleStatus)).map(([key, value]) => (
              <Select.Option
                value={Number(key)}
                key={`sale-status-option:${value}`}
              >
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'claim_status'} label="Claim Status">
          <Select>
            {Array.from(Object.entries(ClaimStatus)).map(([key, value]) => (
              <Select.Option value={Number(key)} key={`claim-status:${value}`}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'created_by'} label="Agent">
          <Select allowClear>
            {agentOptions.map(({ label, value }) => (
              <Select.Option value={value} key={`agent-option:${value}`}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'keyword'} label="Keyword">
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            placeholder="PT ~ 123 AND 'Purchaser Name' ~ John"
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 12,
            gridColumn: 'span 2 / span 2',
          }}
        >
          <Button onClick={onClearClick}>Clear</Button>
          <Button onClick={onSearchClick}>Search</Button>
          <Button onClick={onExportClick}>Export</Button>
          <Button type="primary" onClick={onCreateClick}>
            Add Sale
          </Button>
        </div>
      </Form>
      <div style={{ padding: 10 }} />
      <SimpleTable
        expandable={{
          expandedRowRender: (record: any) => {
            let display = views
              .map((view) => {
                const { dataIndex, title } = view
                const render = view.render as any
                const value = record[dataIndex]
                if (
                  value === undefined ||
                  value === null ||
                  value === '' ||
                  (dataIndex === 'spa_date' && value === 0) ||
                  (dataIndex === 'lo_date' && value === 0) ||
                  (dataIndex === 'la_date' && value === 0)
                ) {
                  return null
                }
                return (
                  <div style={{ paddingBottom: 12 }}>
                    <span style={{ fontWeight: 'bold' }}>{title}: </span>
                    <span>{render ? render(value) : record[dataIndex]}</span>
                  </div>
                )
              })
              .filter(Boolean)

            if (record.commission.length) {
              display = display.concat([
                <div style={{ fontWeight: 'bold' }}>Commission</div>,
              ])
              display = display.concat(
                record.commission.map((el: any, index: number) => (
                  <div style={{ paddingBottom: 5 }}>
                    <div>Username: {el.username}</div>
                    <div>Rate: {el.rate}</div>
                  </div>
                ))
              )
            }
            return <div>{display}</div>
          },
        }}
        loading={loading}
        pagination={{ position: ['bottomRight'], defaultPageSize: 10 }}
        columns={columns}
        actions={(record: AllSaleResponse) => {
          const isBankLoanBuyer =
            record.financial_type === FINANCIAL_TYPE.BANK_LOAN
          const arr = [
            {
              key: 'Edit',
              onAction: editButtonOnClick,
            },
          ]
          const otherInfo = JSON.parse(record.other_info || '{}')
          const hasBankInfo = otherInfo.banks && otherInfo.banks.length > 0
          if (
            record.sale_status === SALE_STATUS.CONVERTED &&
            record.spa_date &&
            (isBankLoanBuyer
              ? record.la_date && record.lo_date && hasBankInfo
              : true)
          ) {
            arr.push({
              key: 'Fast Comm',
              onAction: onFastCommButtonClick,
            })
          }
          return arr
        }}
        data={dataSource}
      ></SimpleTable>
    </Container>
  )
}

function dateColumn<T, K extends Extract<keyof T, string> = any>(
  key: K
): ColumnProps<T> {
  return {
    ...numberColumn(key),
    render: (value: number | null) => {
      if (typeof value === 'number') {
        return moment(value, 'X').format('DD/MM/YYYY')
      }
      return value
    },
  }
}

const views = [
  { dataIndex: 'project_id', title: 'Project ID' },
  { dataIndex: 'project.name', title: 'Project' },
  {
    dataIndex: 'booking_date',
    title: 'Booking Date',
    render: (text: string) => moment(text, 'X').format('DD/MM/YYYY'),
  },
  { dataIndex: 'purchaser_name', title: 'Purchaser Name' },
  { dataIndex: 'purchaser_contact', title: 'Purchaser Contact' },
  {
    dataIndex: 'purchaser_type',
    title: 'Purchaser Type',
    render: (text: PURCHASER_TYPE) => PurchaserType[text],
  },
  {
    dataIndex: 'financial_type',
    title: 'Financial Type',
    render: (text: FINANCIAL_TYPE) => FinancialType[text],
  },
  { dataIndex: 'spa_price', title: 'SPA Price' },
  { dataIndex: 'nett_price', title: 'Nett Price' },
  { dataIndex: 'created_by', title: 'Created By' },
  {
    dataIndex: 'claim_status',
    title: 'Claim Status',
    render: (text: CLAIM_STATUS) => ClaimStatus[text],
  },
  { dataIndex: 'claim_remark', title: 'Claim Remark' },
  { dataIndex: 'phase', title: 'Phase' },
  { dataIndex: 'pt_no', title: 'PT' },
  {
    dataIndex: 'sale_status',
    title: 'Sale Status',
    render: (text: SALE_STATUS) => SaleStatus[text],
  },
  { dataIndex: 'sale_remark', title: 'Sale Remark' },
  {
    dataIndex: 'spa_date',
    title: 'Date of Signing SPA',
    render: (text: string) => moment(text, 'X').format('DD/MM/YYYY'),
  },
  {
    dataIndex: 'lo_date',
    title: 'Date Acceptance LO',
    render: (text: string) => moment(text, 'X').format('DD/MM/YYYY'),
  },
  {
    dataIndex: 'la_date',
    title: 'Date Signing LA',
    render: (text: string) => moment(text, 'X').format('DD/MM/YYYY'),
  },
]
