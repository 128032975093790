import {
  CreateClaimBatchPayload,
  ListClaimBatchesRespData,
  UpdateClaimBatchPayload,
  UpdateClaimBatchRespData,
} from '@m17/api-types'
import { fetchApi } from './fetch'

const PREFIX = '/claim-batch'

export const createClaimBatch = async (payload: CreateClaimBatchPayload) => {
  return await fetchApi(PREFIX + '/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export const getAllClaimBatches =
  async (): Promise<ListClaimBatchesRespData> => {
    return await fetchApi(PREFIX + '/all', { method: 'GET' })
  }

export const updateClaimBatch = async (
  payload: UpdateClaimBatchPayload
): Promise<UpdateClaimBatchRespData> => {
  return await fetchApi(PREFIX + '/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}
