import { Form, FormInstance } from 'antd'
import { ClaimStatus } from '@m17/api-types'

export type FastCommClaimFormValues = {
  sale_id: number
  claim_status?: number
  pt_no?: string
}

type FastCommClaimFormProps = {
  form: FormInstance<FastCommClaimFormValues>
}

const FastCommClaimForm = ({ form }: FastCommClaimFormProps) => {
  return (
    <Form form={form}>
      <Form.Item shouldUpdate label="PT No.">
        {() => form.getFieldValue('pt_no')}
      </Form.Item>
      <Form.Item name="pt_no" hidden />
      <Form.Item name="sale_id" hidden />
      <Form.Item name="claim_status" hidden />
      <Form.Item shouldUpdate label="Claim status">
        {() => {
          const status = form.getFieldValue('claim_status')
          if (typeof status === 'number') {
            return ClaimStatus[status as keyof typeof ClaimStatus]
          }
          return '-'
        }}
      </Form.Item>
    </Form>
  )
}

export default FastCommClaimForm
