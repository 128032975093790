const PREFIX = '/report'

export async function generateReport(data: any) {
  const url = `${PREFIX}/generate?${new URLSearchParams(data)}`
  let filename = 'report.csv'
  const csv = await fetch('/api/v1' + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((response) => {
    // get filename from response header
    filename =
      response.headers.get('Content-Disposition')?.split('filename=')[1] ||
      filename
    return response.blob()
  })
  const urlObject = window.URL || window.webkitURL || window
  const exportUrl = urlObject.createObjectURL(csv)
  const a = document.createElement('a')
  a.href = exportUrl
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
