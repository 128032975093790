import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import ClaimPage from '../views/ClaimPage'
import {
  getAllClaims,
  getSetClaimBatchOptions,
  setClaimBatch,
  generateClaimReport,
  getSearchClaimBatchOptions,
  getSearchProjectOptions,
  approveClaims,
  rejectClaims,
} from '../api/claim'
import {
  Form,
  Select,
  Row,
  Col,
  Typography,
  Card,
  Button,
  message,
  Radio,
  Input,
  Modal,
} from 'antd'
import { GetAllClaimsPayload } from '@m17/api-types'
import { ClaimSearchFormValues } from '../views/ClaimSearchForm'
import moment, { Moment } from 'moment'
import useDisclosure from '../hooks/useDisclose'
import SaleForm from '../views/SaleForm'
import { getAllUsers } from '../api/user'
import SetBatchForm from './SetBatchForm'

const convertDate = (date: Moment[] | undefined) => {
  if (!date) {
    return date
  }
  return date.map((item) => item.unix())
}

const ClaimPageContainer = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [getAllClaimsFilter, setGetAllClaimsFilter] =
    useState<GetAllClaimsPayload>({})
  const { data: allClaimsData, refetch: refetchAllClaims } = useQuery(
    ['all-claims', getAllClaimsFilter],
    getAllClaims
  )
  const { data: setClaimBatchOptions } = useQuery(
    ['set-claim-batch-options'],
    getSetClaimBatchOptions
  )
  const { data: searchClaimBatchOptions } = useQuery(
    ['search-claim-batch-options'],
    getSearchClaimBatchOptions
  )
  const { data: searchProjectOptions } = useQuery(
    ['search-project-options'],
    getSearchProjectOptions
  )
  const { data: users } = useQuery(['user'], getAllUsers)
  const [claimSearchForm] = Form.useForm<ClaimSearchFormValues>()
  const [claimBatchForm] = Form.useForm()
  const [updateClaimsForm] = Form.useForm()
  const watchUpdateClaimAction = Form.useWatch('action', updateClaimsForm)
  const { mutateAsync: setClaimBatchFn } = useMutation(setClaimBatch)
  const [generateClaimReportForm] = Form.useForm()
  const { mutateAsync: generateClaimReportFn } =
    useMutation(generateClaimReport)
  const { mutateAsync: approveClaimsFn, isLoading: isLoadingApproveClaims } =
    useMutation(approveClaims)
  const { mutateAsync: rejectClaimsFn, isLoading: isLoadingRejectClaims } =
    useMutation(rejectClaims)

  const [saleForm] = Form.useForm()
  const {
    isOpen: isOpenSaleForm,
    onClose: onCloseSaleForm,
    onOpen: onOpenSaleForm,
  } = useDisclosure()

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const onSubmitSetClaimBatch = async () => {
    try {
      await claimBatchForm.validateFields()
    } catch (error) {
      return
    }
    try {
      const claimBatchFormValues = claimBatchForm.getFieldsValue()
      await setClaimBatchFn({
        claim_batch_id: claimBatchFormValues.claim_batch_id,
        claim_ids: selectedRowKeys as number[],
      })
      await refetchAllClaims()
      setSelectedRowKeys([])
      claimBatchForm.resetFields()
      message.success('Update successfully')
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  const onSubmitUpdateClaimsForm = async () => {
    try {
      updateClaimsForm.validateFields()
    } catch (error) {
      return
    }

    try {
      const updateClaimsFormValues = updateClaimsForm.getFieldsValue()
      if (updateClaimsFormValues.action === 1) {
        await approveClaimsFn({
          claim_ids: selectedRowKeys as number[],
        })
      } else {
        await rejectClaimsFn({
          claim_ids: selectedRowKeys as number[],
          claim_remark: updateClaimsFormValues.claim_remark,
        })
      }
      await refetchAllClaims()
      setSelectedRowKeys([])
      updateClaimsForm.resetFields()
      message.success('Update successfully')
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  // const onUpdateClaimStatus = async () => {
  //   // TODO:
  // };

  const onGenerateClaimReport = async () => {
    try {
      await generateClaimReportForm.validateFields()
    } catch (error) {
      return
    }
    try {
      const generateClaimReportFormValues =
        generateClaimReportForm.getFieldsValue()
      await generateClaimReportFn({
        claim_batch_id: generateClaimReportFormValues.claim_batch_id,
      })
      await refetchAllClaims()
      generateClaimReportForm.resetFields()
    } catch (error) {
      message.error('Something went wrong.')
    }
  }

  const onSearchClick = () => {
    const values = claimSearchForm.getFieldsValue()
    const payload = {
      booking_date: convertDate(values.booking_date)?.join(','),
      claim_batch_id: values.claim_batch_id
        ? String(values.claim_batch_id)
        : undefined,
      project_id: values.project_id ? String(values.project_id) : undefined,
      claim_status: values.claim_status
        ? String(values.claim_status)
        : undefined,
    }

    const filteredPayload = Object.keys(payload).reduce<any>((acc, cur) => {
      const key = cur as keyof typeof payload
      if (payload[key]) {
        acc[key] = payload[key]
      }
      return acc
    }, {})
    setGetAllClaimsFilter(filteredPayload)
  }

  const onClearClick = () => {
    claimSearchForm.resetFields()
  }

  const onViewDetails = (record: any) => {
    const pt =
      record.pt_id && record?.project_pt
        ? `${record.project_pt.pt_no}`
        : record.pt
    const formValues = {
      ...record,
      project: `${record['project']['name']}`,
      ...(record.project_phase_id
        ? {
            project_phase_id: `${record.project_phase.name}`,
          }
        : {}),
      booking_date: moment(record.booking_date, 'X'),
      spa_date: record.spa_date && moment(record.spa_date, 'X'),
      la_date: record.la_date && moment(record.la_date, 'X'),
      lo_date: record.lo_date && moment(record.lo_date, 'X'),
      commissions: record.commission.map((comm: any) => ({
        rate: comm.rate,
        username: comm.user.username,
      })),
      pt,
      ...(record.other_info
        ? JSON.parse(record.other_info, (_, value) => {
            // date change to moment
            // regex for this date
            if (
              typeof value === 'string' &&
              value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
            ) {
              return moment(value)
            }
            return value
          })
        : {}),
      sop_date: record.sop_date && moment(record.sop_date, 'X'),
    }
    saleForm.setFieldsValue(formValues)
    onOpenSaleForm()
  }

  return (
    <div>
      <ClaimPage
        claimData={allClaimsData || []}
        selectedRowKeys={selectedRowKeys}
        claimSearchForm={claimSearchForm}
        searchFormClaimBatchOptions={searchClaimBatchOptions || []}
        searchFormProjectOptions={searchProjectOptions || []}
        onSelectChange={onSelectChange}
        onSearchClick={onSearchClick}
        onClearClick={onClearClick}
        onViewDetails={onViewDetails}
      />

      <Row gutter={[16, 16]} style={{ margin: '16px 0' }}>
        <Col span={8}>
          <Card>
            <SetBatchForm
              form={claimBatchForm}
              claimBatchOptions={setClaimBatchOptions || []}
              onSubmit={onSubmitSetClaimBatch}
              disabled={selectedRowKeys.length === 0}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Form form={updateClaimsForm} layout="horizontal">
              <Typography.Title level={5}>Update Claims</Typography.Title>
              <Form.Item
                label="Action"
                name="action"
                rules={[
                  { required: true, message: 'Please select an action.' },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Approve</Radio>
                  <Radio value={2}>Reject</Radio>
                </Radio.Group>
              </Form.Item>

              {watchUpdateClaimAction === 2 && (
                <Form.Item
                  name="claim_remark"
                  label="Remark"
                  rules={[
                    { required: true, message: 'Please enter the reason' },
                  ]}
                >
                  <Input.TextArea placeholder="Reject reason" />
                </Form.Item>
              )}

              <Button
                disabled={selectedRowKeys.length === 0}
                onClick={onSubmitUpdateClaimsForm}
                loading={isLoadingApproveClaims || isLoadingRejectClaims}
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Form form={generateClaimReportForm} layout="vertical">
              <Typography.Title level={5}>
                Generate Claim Report
              </Typography.Title>
              <Form.Item
                label="Claim batch"
                name="claim_batch_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select a claim batch',
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.children ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }}
                >
                  {(setClaimBatchOptions || []).map(({ label, value }) => (
                    <Select.Option
                      map={`set-claim-batch-option:${value}`}
                      value={value}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Button onClick={onGenerateClaimReport}>Generate</Button>
            </Form>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Sale Details"
        onOk={onCloseSaleForm}
        onCancel={onCloseSaleForm}
        cancelButtonProps={{ hidden: true }}
        open={isOpenSaleForm}
        width={720}
      >
        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
          <SaleForm
            projectOptions={[]}
            form={saleForm}
            users={users || []}
            projectPhases={[]}
            projectPTs={[]}
            mode="view"
          ></SaleForm>
        </div>
      </Modal>
    </div>
  )
}

export default ClaimPageContainer
