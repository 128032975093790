import { SelectOption } from '@m17/api-types'
import { Button, Form, FormInstance, Select, Typography } from 'antd'
import React, { FC } from 'react'

type SetBatchFormProps = {
  form: FormInstance<any>
  claimBatchOptions: SelectOption<number>[]
  disabled?: boolean
  onSubmit: () => void
}

const SetBatchForm: FC<SetBatchFormProps> = ({
  form,
  claimBatchOptions,
  disabled,
  onSubmit,
}) => {
  return (
    <Form form={form} layout="vertical">
      <Typography.Title level={5}>Set Claim Batch</Typography.Title>
      <Form.Item
        label="Claim batch"
        name="claim_batch_id"
        rules={[
          {
            required: true,
            message: 'Please select a claim batch',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            return (option?.children ?? '')
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }}
        >
          {(claimBatchOptions || []).map(({ label, value }) => (
            <Select.Option
              map={`set-claim-batch-option:${value}`}
              value={value}
            >
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Button disabled={disabled} onClick={onSubmit}>
        Update
      </Button>
    </Form>
  )
}

export default SetBatchForm
