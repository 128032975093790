import React from 'react'
import { Tabs } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { checkPremissions } from '../utils/permission'
import { fetchPermissions } from '../api/permission'
import { useQuery } from '@tanstack/react-query'
import { WRITE_PERMISSION } from '../constants/permission'
import { Container } from '../views/StyledComponent'

const ClaimTabLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { data: userPermissionData } = useQuery(['user-permissions'], () =>
    fetchPermissions()
  )

  return (
    <Container>
      <Tabs defaultActiveKey={location.pathname} onChange={navigate}>
        {checkPremissions(userPermissionData?.permissions || [], [
          WRITE_PERMISSION.MANAGE_CLAIMS,
        ]) && (
          <>
            <Tabs.TabPane tab="Claims" key="/claim" />
            <Tabs.TabPane tab="Claim Batch" key="/claim-batches" />
          </>
        )}
        {checkPremissions(userPermissionData?.permissions || [], [
          WRITE_PERMISSION.LEADER_CLAIM_APPROVAL,
        ]) && (
          <Tabs.TabPane tab="Leader Approval" key="/claim-leader-approval" />
        )}
      </Tabs>
      <Outlet />
    </Container>
  )
}

export default ClaimTabLayout
