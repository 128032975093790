import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useFetchUserDetail } from '../hooks'
import Loading from './Loading'

export default function AuthRoute() {
  const { pathname } = useLocation()
  const { status, error, data } = useFetchUserDetail()

  if (status === 'loading') {
    return <Loading></Loading>
  }

  // Not able to login
  if (error) {
    if (error.status === 401) {
      return <Navigate to="/login" />
    } else return <Navigate to="/error" />
  }

  const { type } = data

  if (
    type === 'accountant' &&
    ![
      '/claim',
      '/claim-batches',
      '/converted-sales',
      '/developer-claims',
      '/developer-claim-batches',
    ].includes(pathname)
  ) {
    return <Navigate to="/claim" />
  }

  return <Outlet />
}
