import { useQuery } from '@tanstack/react-query'
import { Modal, message, Form } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  addUser,
  fetchUserDetail,
  resetPassword,
  updateUser,
  uploadUserUrl,
  getUserFormLeaders,
  getUserTypeOptions,
  getLeaderOptions,
  getSubordinates,
} from '../api/user'
import UserPage, { UserTableRecord } from '../views/UserPage'
import UploadButton from './UploadButton'
import Loading from './Loading'
import UserForm, { AddUserFormValues } from '../views/UserForm'
import useDisclosure from '../hooks/useDisclose'
import { ResetPasswordPayload, ToggleUserStatusPayload } from '../types/user'
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '../views/ResetPasswordForm'
import { UserSearchFormValues } from '../views/UserSearchForm'

export default function UserPageContainer() {
  const [userSearchQuery, setUserSearchQuery] = useState({})
  const {
    isLoading,
    data: meData,
    isError,
  } = useQuery(['get-user-detail'], fetchUserDetail)
  const { data: subordinates, refetch } = useQuery(
    ['subordinates', userSearchQuery],
    getSubordinates
  )
  const [selectedUsername, setSelectedUsername] = useState<string>()
  const { data: allUplines, refetch: refetchLeaders } = useQuery(
    ['user-form-leaders', selectedUsername],
    () => getUserFormLeaders({ username: selectedUsername })
  )
  const leaderOptions = useMemo(
    () =>
      allUplines?.map((u) => ({
        label: `${u.type} - ${u.name}`,
        value: u.username,
      })) || [],
    [allUplines]
  )
  const { data: userTypeOptions } = useQuery(
    ['user-type-options'],
    getUserTypeOptions
  )
  const { data: leaderFilterOptions } = useQuery(
    ['leader-filter-options'],
    getLeaderOptions
  )
  const {
    mutateAsync: resetPasswordMutate,
    isLoading: isLoadingResetPassword,
  } = useMutation(resetPassword)
  const { mutateAsync: updateUserMutate, isLoading: isLoadingUpdateUser } =
    useMutation(updateUser)

  const [isUserModalVisible, setIsUserModalVisible] = useState(false)
  const {
    isOpen: isOpenEditUserForm,
    onClose: onCloseEditUserForm,
    onOpen: onOpenEditUserForm,
  } = useDisclosure()
  const {
    isOpen: isOpenResetPasswordForm,
    onClose: onCloseResetPasswordForm,
    onOpen: onOpenResetPasswordForm,
  } = useDisclosure()

  const [userForm] = Form.useForm<AddUserFormValues>()
  const [resetPasswordForm] = Form.useForm<ResetPasswordFormValues>()
  const [userSearchForm] = Form.useForm()

  const handleCancel = () => {
    setIsUserModalVisible(false)
  }

  const handleUserFormSubmit = async () => {
    try {
      await userForm.validateFields()
      if (
        userForm.getFieldsError().filter((item) => item.errors.length).length >
        0
      ) {
        message.error('Please make sure the field is filled up correctly')
        return
      }
      message.info('Adding...')
      const formValues = userForm.getFieldsValue()
      await addUser({
        code: formValues.code,
        leader_id: formValues.leader_id,
        name: formValues.name,
        password: formValues.password,
        type: formValues.type,
        username: formValues.username,
      })
      setIsUserModalVisible(false)
      message.success('Adding user success')
      await refetch()
      await refetchLeaders()
    } catch (err) {
      if (err instanceof Error) {
        message.error('Make sure username is unique and form is correct')
      }
    }
  }

  const onResetPasswordClick = useCallback(
    ({ username }: ResetPasswordPayload) => {
      resetPasswordForm.resetFields()
      resetPasswordForm.setFieldsValue({
        username,
      })
      onOpenResetPasswordForm()
    },
    [resetPasswordForm, onOpenResetPasswordForm]
  )

  const onCancelResetPasswordClick = useCallback(() => {
    onCloseResetPasswordForm()
  }, [onCloseResetPasswordForm])

  const onOkResetPasswordClick = useCallback(async () => {
    try {
      await resetPasswordForm.validateFields()
    } catch (error) {
      message.error('Please make sure the field is filled up correctly')
      return
    }

    try {
      const values = resetPasswordForm.getFieldsValue()
      await resetPasswordMutate(values)
      message.success('Reset password successfully.')
      await refetch()
      onCloseResetPasswordForm()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message || 'Something went wrong.')
      }
    }
  }, [
    resetPasswordForm,
    resetPasswordMutate,
    refetch,
    onCloseResetPasswordForm,
  ])

  const onEditUserClick = useCallback(
    ({ code, name, username, leader_id, type }: UserTableRecord) => {
      setSelectedUsername(username)
      userForm.resetFields()
      onOpenEditUserForm()
      userForm.setFieldsValue({
        code: code || undefined,
        name,
        username,
        leader_id: leader_id || undefined,
        type,
      })
    },
    [userForm, onOpenEditUserForm]
  )

  const onCancelEditUserClick = useCallback(() => {
    onCloseEditUserForm()
  }, [onCloseEditUserForm])

  const onOkEditUserClick = useCallback(async () => {
    try {
      await userForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }
    const values = userForm.getFieldsValue()
    try {
      await updateUserMutate({
        leader_id: values.leader_id,
        code: values.code,
        name: values.name,
        username: values.username,
        type: values.type,
      })
      message.success('Edit successful.')
      onCloseEditUserForm()
      await refetch()
      await refetchLeaders()
    } catch (error) {
      if (error instanceof Error) {
        message.error(error.message || 'Something went wrong.')
      }
    }
  }, [userForm, updateUserMutate, refetch, onCloseEditUserForm, refetchLeaders])

  const onConfirmToggleUserStatus = useCallback(
    async ({ status, username }: ToggleUserStatusPayload) => {
      const newStatus = status === 0 ? '1' : '0'
      try {
        await updateUserMutate({ status: newStatus, username })
        message.success(
          newStatus === '0'
            ? 'Deactive user successfully'
            : 'Enable user successfully'
        )
        await refetch()
      } catch (error) {
        if (error instanceof Error) {
          message.error(error.message || 'Something went wrong.')
        }
      }
    },
    [refetch, updateUserMutate]
  )

  const onSubmitUserSearch = useCallback(
    (v: UserSearchFormValues) => {
      setUserSearchQuery(v)
    },
    [setUserSearchQuery]
  )

  const onResetUserSearch = useCallback(() => {
    setUserSearchQuery({})
  }, [setUserSearchQuery])

  if (isLoading || isError) {
    return <Loading></Loading>
  }

  return (
    <div>
      <UserPage
        loading={isLoading}
        onCreateClick={() => {
          userForm.resetFields()
          setIsUserModalVisible(true)
        }}
        data={meData}
        subordinates={subordinates || []}
        renderUploadButton={<UploadButton action={uploadUserUrl} />}
        userSearchForm={userSearchForm}
        userTypeFilterOptions={userTypeOptions || []}
        leaderFilterOptions={leaderFilterOptions || []}
        onResetPassword={onResetPasswordClick}
        onEditUserClick={onEditUserClick}
        onConfirmToggleUserStatus={onConfirmToggleUserStatus}
        onSubmitUserSearch={onSubmitUserSearch}
        onResetUserSearch={onResetUserSearch}
      ></UserPage>
      <Modal
        title="Add User"
        open={isUserModalVisible}
        onOk={handleUserFormSubmit}
        onCancel={handleCancel}
      >
        <UserForm
          form={userForm}
          leaderOptions={leaderOptions}
          userTypeOptions={userTypeOptions || []}
        />
      </Modal>
      <Modal
        title="Reset Password"
        open={isOpenResetPasswordForm}
        onOk={onOkResetPasswordClick}
        okText="Reset"
        okButtonProps={{
          loading: isLoadingResetPassword,
        }}
        onCancel={onCancelResetPasswordClick}
      >
        <ResetPasswordForm form={resetPasswordForm} />
      </Modal>
      <Modal
        title="Update Profile"
        open={isOpenEditUserForm}
        okText="Submit"
        onCancel={onCancelEditUserClick}
        onOk={onOkEditUserClick}
        okButtonProps={{
          loading: isLoadingUpdateUser,
        }}
      >
        <UserForm
          form={userForm}
          leaderOptions={leaderOptions}
          userTypeOptions={userTypeOptions || []}
          mode="edit"
          isSelfEdit={selectedUsername === meData.username}
        />
      </Modal>
    </div>
  )
}
