import React, { useCallback, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Modal, message } from 'antd'

import {
  createClaimBatch,
  getAllClaimBatches,
  updateClaimBatch,
} from '../api/claimBatch'

import ManageClaimBatchPage from '../views/ManageClaimBatchPage'
import {
  CreateClaimBatchPayload,
  UpdateClaimBatchPayload,
} from '@m17/api-types'
import ClaimBatchForm, { ClaimBatchFormValues } from '../views/ClaimBatchForm'
import useDisclosure from '../hooks/useDisclose'

const ManageClaimBatchPageContainer = () => {
  const [claimBatchForm] = Form.useForm<ClaimBatchFormValues>()
  const [isEdit, setIsEdit] = useState(false)
  const { data: allClaimBatches, refetch: refetchAllClaimBatches } = useQuery(
    ['all-claim-batches'],
    getAllClaimBatches
  )
  const {
    mutateAsync: createClaimBatchFn,
    isLoading: isLoadingCreateClaimBatch,
  } = useMutation(['create-claim-batch'], createClaimBatch)

  const { mutateAsync: updateClaimBatchFn } = useMutation(
    ['update-claim-batch'],
    updateClaimBatch
  )

  const {
    isOpen: isOpenClaimBatchForm,
    onClose: onCloseClaimBatchForm,
    onOpen: onOpenClaimBatchForm,
  } = useDisclosure()

  const onClickAdd = useCallback(() => {
    claimBatchForm.resetFields()
    setIsEdit(false)
    onOpenClaimBatchForm()
  }, [claimBatchForm, onOpenClaimBatchForm])

  const onClickEdit = useCallback(
    ({ id, name }: { id: number; name: string }) => {
      claimBatchForm.resetFields()
      claimBatchForm.setFieldsValue({ id, name })
      setIsEdit(true)
      onOpenClaimBatchForm()
    },
    [claimBatchForm, onOpenClaimBatchForm]
  )

  const onOkClaimBatchForm = useCallback(async () => {
    try {
      await claimBatchForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    try {
      const fieldsValue = claimBatchForm.getFieldsValue()
      if (isEdit) {
        if (!fieldsValue.id) {
          throw new Error('Missing record id')
        }
        const payload: UpdateClaimBatchPayload = {
          id: fieldsValue.id,
          name: fieldsValue.name,
        }
        await updateClaimBatchFn(payload)
        message.success('Claim batch updated successfully.')
      } else {
        const payload: CreateClaimBatchPayload = {
          name: fieldsValue.name,
        }
        await createClaimBatchFn(payload)
        message.success('New claim batch added successfully.')
      }
      await refetchAllClaimBatches()
      onCloseClaimBatchForm()
    } catch (error) {
      message.error('Something went wrong.')
    }
  }, [
    claimBatchForm,
    isEdit,
    refetchAllClaimBatches,
    createClaimBatchFn,
    onCloseClaimBatchForm,
    updateClaimBatchFn,
  ])

  if (!allClaimBatches) {
    return <div></div>
  }

  return (
    <>
      <ManageClaimBatchPage
        onClickAdd={onClickAdd}
        onClickEdit={onClickEdit}
        allClaimBatches={allClaimBatches}
      />
      <Modal
        title="Add new claim batch"
        open={isOpenClaimBatchForm}
        okButtonProps={{
          form: 'claim-batch-form',
          htmlType: 'submit',
          loading: isLoadingCreateClaimBatch,
        }}
        onOk={onOkClaimBatchForm}
        onCancel={onCloseClaimBatchForm}
        destroyOnClose
      >
        <ClaimBatchForm form={claimBatchForm} />
      </Modal>
    </>
  )
}

export default ManageClaimBatchPageContainer
