import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, message } from 'antd'
import type { UploadProps } from 'antd'

interface UploadButtonProps extends UploadProps {
  action?: string
  onDone?: () => void
}

const UploadButton = ({ onDone, ...props }: UploadButtonProps) => {
  const defaultProps: UploadProps = {
    name: 'file',
    action: '/api/v1/upload',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload: (file) => {
      // check is csv file
      if (file.type !== 'text/csv') {
        message.error(`${file.name} is not a csv file`)
      }
      return file.type === 'text/csv'
    },
    onChange(info) {
      if (info.file.status === 'uploading') {
        message.info('uploading...')
      }
      if (info.file.status === 'done') {
        if (onDone) onDone()
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        info.file.response && message.error(info.file.response.message)
      }
    },
    maxCount: 1,
    showUploadList: false,
  }
  return (
    <Upload {...defaultProps} {...props}>
      <Button icon={<UploadOutlined />}>Import</Button>
    </Upload>
  )
}

export const ImageUploadButton = ({ onDone, ...props }: UploadButtonProps) => {
  const defaultProps: UploadProps = {
    listType: 'picture',
    name: 'file',
    action: '/api/v1/upload',
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
        return false
      }
    },
  }

  return (
    <Upload {...defaultProps} {...props}>
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  )
}

export default UploadButton
