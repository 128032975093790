import { useMutation } from '@tanstack/react-query'
import { Form, FormInstance, Input, message } from 'antd'
import { createProject } from '../api/project'

export interface ProjectFormValues {
  name: string
  area: string
  developer: string
}

interface ProjectFormProps {
  form: FormInstance<ProjectFormValues>
  onFinish: () => void
}

export function ProjectForm({ form, onFinish }: ProjectFormProps) {
  const { mutateAsync: createProjectMutate } = useMutation(createProject)

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={(value) => {
        createProjectMutate(value)
          .then(onFinish)
          .catch((err) => message.error(err))
      }}
    >
      <Form.Item
        name={'name'}
        label="Project name"
        required
        rules={[
          {
            required: true,
            message: 'The project name is required!',
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={'area'}
        label="Area"
        rules={[{ required: true, message: 'The Area name is required!' }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={'developer'}
        label="Developer"
        rules={[
          {
            required: true,
            message: 'The Developer name is required!',
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
    </Form>
  )
}
