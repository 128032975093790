import { SelectOption } from '.'

export type CreateDeveloperClaimBatchPayload = {
  name: string
  type: DEVELOPER_CLAIM_BATCH_TYPE
  commission_pct?: number
  full_commission_pct?: number
}

export type CreateDeveloperClaimBatchResp = {
  id: number
  name: string
  type: DEVELOPER_CLAIM_BATCH_TYPE
  commission_pct: number
  full_commission_pct?: number
}

export enum DEVELOPER_CLAIM_BATCH_STATUS {
  PENDING,
  CLAIM_COMPLETED,
}

export const DeveloperClaimBatchStatus = {
  [DEVELOPER_CLAIM_BATCH_STATUS.PENDING]: 'Pending',
  [DEVELOPER_CLAIM_BATCH_STATUS.CLAIM_COMPLETED]: 'Claim Completed',
}

export enum DEVELOPER_CLAIM_BATCH_TYPE {
  FULL_CLAIM,
  FIRST_CLAIM,
  SECOND_CLAIM,
  THIRD_CLAIM,
  FORTH_CLAIM,
  FINAL_CLAIM,
}

export const DeveloperClaimBatchType = {
  [DEVELOPER_CLAIM_BATCH_TYPE.FULL_CLAIM]: 'Full Claim',
  [DEVELOPER_CLAIM_BATCH_TYPE.FIRST_CLAIM]: 'First Claim',
  [DEVELOPER_CLAIM_BATCH_TYPE.SECOND_CLAIM]: 'Second Claim',
  [DEVELOPER_CLAIM_BATCH_TYPE.THIRD_CLAIM]: 'Third Claim',
  [DEVELOPER_CLAIM_BATCH_TYPE.FORTH_CLAIM]: 'Forth Claim',
  [DEVELOPER_CLAIM_BATCH_TYPE.FINAL_CLAIM]: 'Final Claim',
}

export type UpdateDeveloperClaimBatchPayload = {
  id: number
  name?: string
  type?: DEVELOPER_CLAIM_BATCH_TYPE
  commission_pct?: number
  full_commission_pct?: number
}

export type UpdateDeveloperClaimBatchResp = {
  id: number
  name: string
  type: DEVELOPER_CLAIM_BATCH_TYPE
  commission_pct: number
  full_commission_pct?: number
}

export type ListDeveloperClaimBatchesResp = {
  id: number
  name: string
  type: number
  commission_pct: number
  full_commission_pct: number
}

export type SetDeveloperClaimBatchOptionsResp = SelectOption<number>
