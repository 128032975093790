import React from 'react'
import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import { PTFormValues } from '../types/pt'
import { parseIdFromSelectValue } from '../utils'

type PTFormProps = {
  form: FormInstance<PTFormValues>
  projectOptions: {
    name: string
    id: number
  }[]
  projectPhaseOptions: {
    name: string
    id: number
  }[]
}

const PTForm = ({ form, projectOptions, projectPhaseOptions }: PTFormProps) => {
  return (
    <Form form={form} name="pt_form">
      <Form.Item name="id" hidden />
      {/* PT NO. */}
      <Form.Item
        name="pt_no"
        label="PT No"
        rules={[{ required: true, message: 'PT No is required.' }]}
      >
        <Input placeholder="PT No" />
      </Form.Item>
      {/* Project Options */}
      <Form.Item
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (!value) {
                return Promise.reject('Project is required')
              }
              const projectId = parseIdFromSelectValue(value)
              return projectOptions.find((opt) => projectId === opt.id)
                ? Promise.resolve()
                : Promise.reject('The project is not found')
            },
          },
        ]}
        name="project"
        label="Project"
      >
        <Select showSearch placeholder="Select a project" disabled>
          {projectOptions.map((opt) => (
            <Select.Option value={`${opt.id}-${opt.name}`}>
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* Project Phase Options */}
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Project phase is required',
          },
        ]}
        name="project_phase"
        label="Project Phase"
      >
        <Select showSearch allowClear placeholder="Select a phase">
          {projectPhaseOptions.map((opt) => (
            <Select.Option value={`${opt.id}-${opt.name}`}>
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* SPA Price */}
      <Form.Item
        name={'spa_price'}
        label="SPA Price"
        required
        rules={[{ required: true, message: 'SPA price is required' }]}
      >
        <Input type="number" prefix={'RM '} placeholder="SPA price"></Input>
      </Form.Item>
      {/* SPA Price (BUMI) */}
      <Form.Item
        name={'spa_price_bumi'}
        label="SPA Price Bumi"
        required
        rules={[{ required: true, message: 'SPA price bumi is required' }]}
      >
        <Input
          type="number"
          prefix={'RM '}
          placeholder="SPA price bumi"
        ></Input>
      </Form.Item>
      {/* Building Type */}
      <Form.Item
        label="Building Type"
        name="building_type"
        rules={[{ required: true, message: 'Building type is required.' }]}
      >
        <Input placeholder="Builing type" />
      </Form.Item>
      <Form.Item
        name="leader_comm_rate"
        label="Leader Comm (%)"
        required
        rules={[
          {
            required: true,
            message: 'Please enter a rate',
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
    </Form>
  )
}

export default PTForm
