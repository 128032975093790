import {
  GetAllDeveloperClaimsResp,
  GetDeveloperClaimConvertedSaleResp,
  SetDeveloperClaimBatchPayload,
} from '@m17/api-types'
import { GenerateDeveloperClaimInvoicePayload } from '@m17/backend'
import { fetchApi } from './fetch'

const PREFIX = '/developer-claim'

export async function getDeveloperClaimConvertedSales(): Promise<
  GetDeveloperClaimConvertedSaleResp[]
> {
  return fetchApi(PREFIX + '/converted-sales', {
    method: 'GET',
  })
}

export async function setDeveloperClaimBatch(
  payload: SetDeveloperClaimBatchPayload
): Promise<boolean> {
  return fetchApi(PREFIX + '/set-claim-batch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export async function getAllDeveloperClaims(): Promise<
  GetAllDeveloperClaimsResp[]
> {
  return fetchApi(PREFIX + '/all', {
    method: 'GET',
  })
}

export async function generateDeveloperClaimInvoice(
  payload: GenerateDeveloperClaimInvoicePayload
) {
  const { developer_claim_batch_id } = payload
  const params = new URLSearchParams({
    developer_claim_batch_id: String(developer_claim_batch_id),
  })
  const url = `${PREFIX}/generate-developer-claim-invoice?${params}`
  let filename = 'proforma_invoice.xlsx'

  const response = await fetch('/api/v1' + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  filename =
    response.headers.get('Content-Disposition')?.split('filename=')[1] ||
    filename

  const blob = await response.blob()

  const urlObject = window.URL || window.webkitURL || window
  const exportUrl = urlObject.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = exportUrl
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
