import React from 'react'
import {
  Button,
  List,
  Input,
  Typography,
  Tooltip,
  Tabs,
  Select,
  TableColumnType,
  Space,
} from 'antd'
import { EditFilled, PlusOutlined } from '@ant-design/icons'
import UploadButton from '../components/UploadButton'
import { uploadUserUrl } from '../api/user'
import { createSelectValue, currencyColumn, toRinggit } from '../utils'
import { ProjectPhase } from '../types/projectPhase'
import { uploadPtUrl } from '../api/pt'
import {
  SimpleTable,
  Column,
  stringColumn,
  numberColumn,
  dateColumn,
} from '@m17/table'
import { Container } from './StyledComponent'

type RebateColumnsType = {
  id: number
  no: number
  project_phase_name: string
  effective_date: number
  a_rebate_pct_bumi: number
  a_rebate_pct_non_bumi: number
  b_rebate_amt_bumi: number
  b_rebate_amt_non_bumi: number
  b_rebate_method: number
  c_rebate_pct_all: number
  c_rebate_pct_all_2: number
  c_sop_date_pct_all: number
  c_sop_date_amt_all: number
  c_rebate_amt_all: number
  d_rebate_pct_existing_buyer: number
  d_introducer_fee_amt: number
}

export type PackageRecordType = {
  id: number
  name: string
  price: number
}

type ProjectPageProps = {
  itemList?: any[]
  data?: any
  userDetail?: any
  assigned: any[]
  projectPhases?: ProjectPhase[]
  phaseSelected: any
  projectRebates: any[]
  projectPTs: any[]
  projectPhasePackages: PackageRecordType[]
  onClick: (record: any) => void
  onAddUserClick: () => void
  onRemoveClick: (record: any) => void
  onChangeTab?: (newTab: string) => void
  onSelectPhase?: (phase: any) => void
  onAddRebateClick: () => void
  onEditRebateClick: (rebate: any) => void
  onComfirmDeleteRebateClick: (rebateId: number) => void
  onAddProjectClick: () => void
  onEditProjectClick: () => void
  onAddPhaseClick: () => void
  onEditPhaseClick: (phase: ProjectPhase) => void
  onAddPTClick: () => void
  onEditPTClick: (pt: any) => void
  onConfirmDeletePTClick: (ptId: number) => void
  onDownloadPTImportFormatClick: () => void
  onDoneUploadPTCSV: () => void
  onAddPackageClick: () => void
  onEditPackageClick: (record: PackageRecordType) => void
  onDeletePackage: (id: string) => void
}

const empty = {}

const ptColumns: Column<any>[] = [
  {
    ...stringColumn('pt_no'),
    title: 'PT No.',
  },
  {
    ...stringColumn('project_name'),
    title: 'Project Name',
  },
  {
    ...stringColumn('project_phase_name'),
    title: 'Phase Name',
  },
  {
    ...numberColumn('spa_price'),
    title: 'SPA Price',
    render: (value) => toRinggit(value),
  },
  {
    ...stringColumn('building_type'),
    title: 'Building Type',
  },
  {
    ...numberColumn('leader_comm_rate'),
    title: 'Leader Comm (%)',
  },
]

const phaseColumn: Column<any>[] = [
  { title: 'username', type: 'string' },
  {
    ...stringColumn('name'),
    title: 'Full Name',
  },
]

export default function ProjectPage({
  itemList = [],
  data = empty,
  userDetail,
  assigned = [],
  projectPhases = [],
  phaseSelected,
  projectRebates = [],
  projectPTs = [],
  projectPhasePackages = [],
  onClick,
  onAddUserClick,
  onRemoveClick,
  onChangeTab = () => null,
  onSelectPhase = () => null,
  onAddRebateClick,
  onEditRebateClick,
  onComfirmDeleteRebateClick,
  onAddProjectClick,
  onEditProjectClick,
  onAddPhaseClick,
  onEditPhaseClick,
  onAddPTClick,
  onEditPTClick,
  onConfirmDeletePTClick,
  onDownloadPTImportFormatClick,
  onDoneUploadPTCSV,
  onAddPackageClick,
  onEditPackageClick,
  onDeletePackage,
}: ProjectPageProps) {
  const [text, setText] = React.useState('')
  const [packageColumn] = React.useState<Column<PackageRecordType>[]>([
    {
      ...stringColumn('name'),
      title: 'Name',
    },
    {
      ...currencyColumn('price'),
      title: 'Price',
    },
  ])

  if (text !== '') {
    itemList = itemList.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    )
  }

  const rebateColumns: TableColumnType<RebateColumnsType>[] = [
    {
      ...stringColumn('project_phase_name'),
      title: 'Phase',
    },
    {
      ...dateColumn('effective_date'),
      title: 'Effective Date',
      render: (value) => {
        const effectiveDate = new Date(value * 1000)
        return new Intl.DateTimeFormat('en-MY').format(effectiveDate)
      },
    },
    {
      key: 'rebate_rates',
      title: 'Rebate Rates',
      dataIndex: [
        'a_rebate_pct_bumi',
        'a_rebate_pct_non_bumi',
        'b_rebate_amt_bumi',
        'b_rebate_amt_non_bumi',
        'b_rebate_method',
        'c_rebate_pct_all',
        'c_rebate_pct_all_2',
        'c_sop_date_pct_all',
        'c_sop_date_amt_all',
        'c_rebate_amt_all',
        'd_rebate_pct_existing_buyer',
        'd_introducer_fee_amt',
      ],
      render: (value, record) => {
        const {
          a_rebate_pct_bumi,
          a_rebate_pct_non_bumi,
          b_rebate_amt_bumi,
          b_rebate_amt_non_bumi,
          c_rebate_pct_all,
          c_rebate_pct_all_2,
          c_sop_date_pct_all,
          c_sop_date_amt_all,
          c_rebate_amt_all,
          d_rebate_pct_existing_buyer,
          d_introducer_fee_amt,
        } = record
        return (
          <div style={{ flexDirection: 'column' }}>
            <div>
              {`A: ${a_rebate_pct_bumi.toFixed(
                2
              )} - ${a_rebate_pct_non_bumi.toFixed(2)} % `}
            </div>
            <div>
              B:{' '}
              <span>
                <strong>{`RM ${b_rebate_amt_bumi.toFixed(2)}`}</strong>
              </span>
              {' - '}
              <span>
                <strong>{`RM ${b_rebate_amt_non_bumi.toFixed(2)}`}</strong>
              </span>
            </div>
            <div>
              {`C: ${c_rebate_pct_all.toFixed(2)} % - `}
              {`${c_rebate_pct_all_2.toFixed(2)} % - `}
              {`${c_sop_date_pct_all.toFixed(2)} % - `}
              <span>
                <strong>{`RM ${c_sop_date_amt_all.toFixed(2)}`}</strong>
              </span>
              {'-'}
              <span>
                <strong>{`RM ${c_rebate_amt_all.toFixed(2)}`}</strong>
              </span>
            </div>
            <div>
              {`D: ${d_rebate_pct_existing_buyer.toFixed(2)} %`}
              {' - '}
              <span>
                <strong>{`RM ${d_introducer_fee_amt.toFixed(2)}`}</strong>
              </span>
            </div>
          </div>
        )
      },
    },
  ]

  const itemListWithAdd = itemList.map((item) => ({ ...item, type: 'item' }))
  itemListWithAdd.unshift({ type: 'add' })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <List
        style={{
          position: 'sticky',
          backgroundColor: 'white',
          overflow: 'auto',
          minHeight: '100vh',
          maxHeight: '100vh',
          top: 0,
        }}
        size="small"
        header={
          <div
            style={{
              minWidth: 320,
              display: 'flex',
              paddingLeft: 6,
              paddingRight: 12,
            }}
          >
            <Input
              onChange={(e) => setText(e.target.value)}
              bordered={false}
              placeholder="Project"
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 12,
              }}
            >
              <Tooltip title={<span>csv format: [id]</span>}>
                <UploadButton action={uploadUserUrl} />
              </Tooltip>
            </div>
          </div>
        }
        dataSource={itemListWithAdd}
        renderItem={(item) => {
          if (item.type === 'add') {
            return (
              <List.Item>
                <Button
                  style={{ width: '100%' }}
                  onClick={onAddProjectClick}
                  type="dashed"
                  icon={<PlusOutlined />}
                >
                  Add Project
                </Button>
              </List.Item>
            )
          }

          return (
            <List.Item
              style={{ cursor: 'pointer' }}
              onClick={() => onClick(item)}
            >
              {item.name}
            </List.Item>
          )
        }}
      />
      {data === empty ? (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            flexGrow: 1,
          }}
        >
          <Typography.Text>Please select a project</Typography.Text>
        </div>
      ) : (
        <Container
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              padding: 12,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              gap: 6,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography.Text strong>Project Info</Typography.Text>
              <Button
                onClick={onEditProjectClick}
                style={{ marginLeft: 'auto' }}
              >
                <EditFilled />
                Edit
              </Button>
            </div>
            <div>
              <Typography.Text strong>Project ID: </Typography.Text>{' '}
              <Typography.Text copyable>{data.id}</Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Project name: </Typography.Text>{' '}
              <Typography.Text copyable>{data.name}</Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Area: </Typography.Text>{' '}
              <Typography.Text>{data.area && data.area}</Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Developer: </Typography.Text>
              <Typography.Text>
                {data.developer && data.developer}
              </Typography.Text>
            </div>
          </div>

          <Tabs onChange={onChangeTab}>
            <Tabs.TabPane tab="PIC" key="project-pic-tab" style={{ gap: 4 }}>
              <div style={{ display: 'flex', marginBottom: 12 }}>
                <Button onClick={onAddUserClick}>Add User</Button>
              </div>
              <SimpleTable
                columns={phaseColumn}
                data={assigned}
                actions={[
                  {
                    key: 'Remove',
                    onAction: onRemoveClick,
                    color: 'red',
                  },
                ]}
              ></SimpleTable>
            </Tabs.TabPane>
            <Tabs.TabPane tab="PT" key="project-pt-tab" style={{ gap: 4 }}>
              <Space style={{ marginBottom: 12 }}>
                <Button onClick={onAddPTClick}>Add PT</Button>
                <UploadButton action={uploadPtUrl} onDone={onDoneUploadPTCSV} />
                <Button type="link" onClick={onDownloadPTImportFormatClick}>
                  Download format
                </Button>
              </Space>

              <SimpleTable
                columns={ptColumns}
                data={projectPTs}
                actions={[
                  {
                    key: 'Edit',
                    onAction: onEditPTClick,
                  },
                  {
                    key: 'Remove',
                    onAction: (record) => onConfirmDeletePTClick(record.id),
                    color: 'red',
                  },
                ]}
              ></SimpleTable>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Phase" key="project-phase-tab">
              <div
                style={{
                  marginBottom: 12,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'flex-end',
                  }}
                >
                  <Button onClick={onAddPhaseClick}>Add Phase</Button>
                </div>
              </div>

              <SimpleTable
                data={projectPhases}
                columns={[
                  {
                    title: 'id',
                    type: 'string',
                  },
                  {
                    title: 'name',
                    type: 'string',
                  },
                ]}
                actions={[
                  {
                    key: 'Edit',
                    onAction: (record) => onEditPhaseClick(record),
                  },
                ]}
              />
            </Tabs.TabPane>
            {userDetail && userDetail.type === 'admin' && (
              <Tabs.TabPane tab="Rebate" key="project-rebate-tab">
                <div
                  style={{
                    marginBottom: 12,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Select
                    showSearch
                    value={phaseSelected}
                    onChange={onSelectPhase}
                    placeholder="Please select a phase"
                  >
                    {projectPhases?.map((phases) => (
                      <Select.Option
                        value={createSelectValue(phases.id, phases.name)}
                      >
                        {phases.name}
                      </Select.Option>
                    ))}
                  </Select>

                  <div
                    style={{
                      display: 'flex',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={onAddRebateClick}
                      disabled={!phaseSelected}
                    >
                      Add Rebate
                    </Button>
                  </div>
                </div>

                <SimpleTable
                  data={projectRebates}
                  columns={rebateColumns}
                  actions={[
                    {
                      key: 'Edit',
                      onAction: onEditRebateClick,
                    },
                    {
                      key: 'Remove',
                      onAction: (record) =>
                        onComfirmDeleteRebateClick(record.id),
                      color: 'red',
                    },
                  ]}
                />
              </Tabs.TabPane>
            )}
            <Tabs.TabPane tab="Package" key="package">
              <div
                style={{
                  marginBottom: 12,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Select
                  showSearch
                  value={phaseSelected}
                  onChange={onSelectPhase}
                  placeholder="Please select a phase"
                >
                  {projectPhases?.map((phases) => (
                    <Select.Option
                      value={createSelectValue(phases.id, phases.name)}
                    >
                      {phases.name}
                    </Select.Option>
                  ))}
                </Select>

                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'flex-end',
                  }}
                >
                  <Button onClick={onAddPackageClick} disabled={!phaseSelected}>
                    Add Package
                  </Button>
                </div>
              </div>
              <SimpleTable
                data={projectPhasePackages}
                columns={packageColumn}
                actions={[
                  {
                    key: 'Edit',
                    onAction: (record) => {
                      onEditPackageClick(record)
                    },
                  },
                  {
                    key: 'Remove',
                    onAction: (record) => {
                      onDeletePackage(record.id.toString())
                    },
                    color: 'red',
                  },
                ]}
              />
            </Tabs.TabPane>
          </Tabs>
        </Container>
      )}
    </div>
  )
}
