import React from 'react'
import {
  Table,
  Typography,
  Button,
  Tooltip,
  Space,
  Popconfirm,
  FormInstance,
  Card,
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { EditFilled } from '@ant-design/icons'
import { ResetPasswordPayload, ToggleUserStatusPayload } from '../types/user'
import AccessControl from '../components/AccessControl'
import { WRITE_PERMISSION } from '../constants/permission'
import UserSearchForm, { UserSearchFormValues } from './UserSearchForm'
import { SelectOption } from '@m17/api-types'
import { Container } from './StyledComponent'

export interface UserTableRecord {
  username: string
  name: string
  type: string
  leader_id: string | null
  code: string | null
  status: number
}

type UserPageProps = {
  data: {
    username: string
    name: string
    type: string
    leader_id: string | null
    agents: UserTableRecord[]
    code: string | null
    status: number
  }
  subordinates: UserTableRecord[]
  loading: boolean
  renderUploadButton: React.ReactNode
  userSearchForm: FormInstance
  userTypeFilterOptions: SelectOption[]
  leaderFilterOptions: SelectOption[]
  onCreateClick: () => void
  onResetPassword: (record: ResetPasswordPayload) => void
  onEditUserClick: (record: UserTableRecord) => void
  onConfirmToggleUserStatus: (payload: ToggleUserStatusPayload) => void
  onSubmitUserSearch: (v: UserSearchFormValues) => void
  onResetUserSearch?: React.FormEventHandler<HTMLFormElement>
}

export default function UserPage({
  data,
  subordinates,
  renderUploadButton,
  loading,
  userSearchForm,
  userTypeFilterOptions,
  leaderFilterOptions,
  onCreateClick,
  onResetPassword,
  onEditUserClick,
  onConfirmToggleUserStatus,
  onSubmitUserSearch,
  onResetUserSearch,
}: UserPageProps) {
  const columns: ColumnsType<UserTableRecord> = [
    { key: 'username', title: 'Username', dataIndex: 'username' },
    { key: 'code', title: 'Code', dataIndex: 'code' },
    { key: 'name', title: 'Name', dataIndex: 'name' },
    { key: 'type', title: 'Type', dataIndex: 'type' },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (status !== 1 ? 'Inactive' : 'Active'),
    },
    {
      key: 'leader',
      title: 'Leader/Pre-Leader',
      dataIndex: 'leader_id',
      render: (id) => id || '-',
    },
    {
      key: 'action',
      title: 'Action',
      render: (_, record) => (
        <Space>
          <Button onClick={() => onEditUserClick(record)} icon={<EditFilled />}>
            Edit
          </Button>
          <Button onClick={() => onResetPassword(record)}>
            Reset Password
          </Button>
          <AccessControl
            allowedPermissions={[
              WRITE_PERMISSION.DISABLE_USER,
              WRITE_PERMISSION.ENABLE_USER,
            ]}
          >
            <Popconfirm
              title={`Are you sure to ${
                record.status === 0 ? 'activate' : 'deactive'
              } this user?`}
              onConfirm={() => onConfirmToggleUserStatus(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger={record.status === 1}>
                {record.status === 0 ? 'Activate' : 'Deactive'}
              </Button>
            </Popconfirm>
          </AccessControl>
        </Space>
      ),
    },
  ]

  return (
    <Container>
      <div
        style={{
          padding: 12,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Typography.Text strong>Personal Info</Typography.Text>
          <div>
            <Typography.Text strong>Username: </Typography.Text>{' '}
            <Typography.Text copyable>{data.username}</Typography.Text>
          </div>
          <div>
            <Typography.Text strong>Code: </Typography.Text>{' '}
            <Typography.Text copyable>{data.code || '-'}</Typography.Text>
          </div>
          <div>
            <Typography.Text strong>Name: </Typography.Text>{' '}
            <Typography.Text>{data.name}</Typography.Text>
          </div>
          <div>
            <Typography.Text strong>Type: </Typography.Text>
            <Typography.Text>{data.type}</Typography.Text>
          </div>
          <div>
            {data.leader_id && (
              <>
                <Typography.Text strong>Leader ID: </Typography.Text>
                <Typography.Text copyable>{data.leader_id}</Typography.Text>
              </>
            )}
          </div>
        </div>
        <Space align="end">
          <Button icon={<EditFilled />} onClick={() => onEditUserClick(data)}>
            Edit
          </Button>
          <Button onClick={() => onResetPassword(data)}>Reset Password</Button>
        </Space>
      </div>
      <div style={{ padding: 10 }} />
      <Card>
        <UserSearchForm
          leaderFilterOptions={leaderFilterOptions}
          userTypeFilterOptions={userTypeFilterOptions}
          form={userSearchForm}
          onSubmitUserSearch={onSubmitUserSearch}
          onResetUserSearch={onResetUserSearch}
        />
      </Card>
      <div style={{ padding: 10 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <AccessControl allowedPermissions={[WRITE_PERMISSION.CREATE_USER]}>
          <Button onClick={onCreateClick}>Create</Button>
        </AccessControl>
        <Tooltip
          placement="topLeft"
          title={<span>csv format: [username*, name, type, password]</span>}
        >
          <span>{renderUploadButton}</span>
        </Tooltip>
      </div>
      <div style={{ padding: 10 }} />
      <Table
        dataSource={subordinates}
        columns={columns}
        loading={loading}
        size="middle"
      />
    </Container>
  )
}
