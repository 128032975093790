import { Button, DatePicker, Form, FormInstance, Select } from 'antd'
import { SaleStatus } from '@m17/api-types'
import { createSelectValue } from '../utils'
import { Container } from './StyledComponent'

const { RangePicker } = DatePicker

type ReportPageProps = {
  form: FormInstance<any>
  projects: any[]
  onGenerateReportClick: () => void
}

const ReportPage = ({
  form,
  projects,
  onGenerateReportClick,
}: ReportPageProps) => {
  return (
    <Container>
      <Form
        form={form}
        style={{
          backgroundColor: 'white',
          padding: 12,
          gap: 6,
        }}
      >
        <Form.Item
          name="booking_date"
          label="Booking date"
          required
          rules={[
            {
              required: true,
              message: 'Please select booking date range',
            },
          ]}
        >
          <RangePicker></RangePicker>
        </Form.Item>
        <Form.Item
          name="project"
          label="Project"
          required
          rules={[
            {
              required: true,
              message: 'Please select a project',
            },
          ]}
        >
          <Select placeholder="Select a project">
            {projects.map((p) => (
              <Select.Option value={createSelectValue(p.id, p.name)}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sale_status" label="Sale Status">
          <Select placeholder="Select a sale status" allowClear>
            {Object.entries(SaleStatus).map((el) => (
              <Select.Option value={el[0]}>{el[1]}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={onGenerateReportClick}>
            Generate Report
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}

export default ReportPage
