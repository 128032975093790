import React, { useCallback, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  createDeveloperClaimBatch,
  getDeveloperClaimBatches,
  updateDeveloperClaimBatch,
} from '../api/developerClaimBatch'
import DeveloperClaimBatchPage, {
  DeveloperClaimBatchRecord,
} from '../views/DeveloperClaimBatchPage'
import useDisclosure from '../hooks/useDisclose'
import { Form, Modal, message } from 'antd'
import {
  CreateDeveloperClaimBatchPayload,
  DeveloperClaimBatchType,
  UpdateDeveloperClaimBatchPayload,
} from '@m17/api-types'
import DeveloperClaimBatchForm, {
  DeveloperClaimBatchFormValues,
} from '../views/DeveloperClaimBatchForm'

const DeveloperClaimBatchPageContainer = () => {
  const { data: developerClaimBatches, refetch: refetchDeveloperClaimBatches } =
    useQuery(['developer-claim-batches'], getDeveloperClaimBatches)
  const {
    mutateAsync: createDeveloperClaimBatchFn,
    isLoading: isLoadingCreateClaimBatch,
  } = useMutation(['create-developer-claim-batch'], createDeveloperClaimBatch)
  const {
    mutateAsync: updateDeveloperClaimBatchFn,
    isLoading: isLoadingUpdateClaimBatch,
  } = useMutation(['update-developer-claim-batch'], updateDeveloperClaimBatch)

  const [claimBatchForm] = Form.useForm<DeveloperClaimBatchFormValues>()
  const [isEdit, setIsEdit] = useState(false)

  const {
    isOpen: isOpenClaimBatchForm,
    onClose: onCloseClaimBatchForm,
    onOpen: onOpenClaimBatchForm,
  } = useDisclosure()

  const okClickAdd = useCallback(() => {
    claimBatchForm.resetFields()
    setIsEdit(false)
    onOpenClaimBatchForm()
  }, [claimBatchForm, onOpenClaimBatchForm, setIsEdit])

  const onClickEdit = useCallback(
    ({
      id,
      name,
      type,
      commission_pct,
      full_commission_pct,
    }: DeveloperClaimBatchRecord) => {
      claimBatchForm.resetFields()
      claimBatchForm.setFieldsValue({
        id,
        name,
        type,
        commission_pct,
        full_commission_pct,
      })
      setIsEdit(true)
      onOpenClaimBatchForm()
    },
    [claimBatchForm, onOpenClaimBatchForm]
  )

  const onOkClaimBatchForm = useCallback(async () => {
    try {
      await claimBatchForm.validateFields()
    } catch (error) {
      message.error('Please make sure the form is correct!')
      return
    }

    try {
      const fieldsValue = claimBatchForm.getFieldsValue()
      if (isEdit) {
        if (!fieldsValue.id) {
          throw new Error('Missing record id')
        }
        const payload: UpdateDeveloperClaimBatchPayload = {
          id: fieldsValue.id,
          name: fieldsValue.name,
          type: fieldsValue.type,
          commission_pct: fieldsValue.commission_pct,
          full_commission_pct: fieldsValue.full_commission_pct,
        }
        await updateDeveloperClaimBatchFn(payload)
        message.success('Claim batch updated successfully.')
      } else {
        const payload: CreateDeveloperClaimBatchPayload = {
          name: fieldsValue.name,
          type: fieldsValue.type,
          commission_pct: fieldsValue.commission_pct,
          full_commission_pct: fieldsValue.full_commission_pct,
        }
        await createDeveloperClaimBatchFn(payload)
        message.success('New claim batch added successfully.')
      }
      await refetchDeveloperClaimBatches()
      onCloseClaimBatchForm()
    } catch (error) {
      message.error('Something went wrong.')
    }
  }, [
    claimBatchForm,
    isEdit,
    refetchDeveloperClaimBatches,
    createDeveloperClaimBatchFn,
    onCloseClaimBatchForm,
    updateDeveloperClaimBatchFn,
  ])

  const developerClaimBatchTypeOptions = Object.entries(
    DeveloperClaimBatchType
  ).map(([k, l]) => ({
    label: l,
    value: Number(k),
  }))

  return (
    <>
      <DeveloperClaimBatchPage
        developerClaimBatches={developerClaimBatches || []}
        onClickAdd={okClickAdd}
        onClickEdit={onClickEdit}
      />
      <Modal
        title="Add new claim batch"
        open={isOpenClaimBatchForm}
        okButtonProps={{
          form: 'claim-batch-form',
          htmlType: 'submit',
          loading: isLoadingCreateClaimBatch || isLoadingUpdateClaimBatch,
        }}
        onOk={onOkClaimBatchForm}
        onCancel={onCloseClaimBatchForm}
        destroyOnClose
      >
        <DeveloperClaimBatchForm
          form={claimBatchForm}
          typeOptions={developerClaimBatchTypeOptions}
        />
      </Modal>
    </>
  )
}

export default DeveloperClaimBatchPageContainer
